import "@app/plugins/components/results/CollectionsFilter.scss";

import React, { useEffect } from "react";
import { Button, Tree } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Collections = ({
  config: {
    collections,
    collection_id: collectionId,
    detail_view_url,
    selected_collection_path: selectedCollectionPath
  }
}) => {
  const { t, i18n } = useTranslation();

  const onSelect = (_, info) => {
    window.location = `${detail_view_url}${info.node.slug}`;
  };

  const createLinks = (data, isParent) => {
    const links = JSON.parse(JSON.stringify(data));
    let convertedLinks = [];
    if (isParent) {
      convertedLinks = links.filter(item => item.children.length > 0);
    } else {
      convertedLinks = links;
    }
    convertedLinks.forEach((item, index) => {
      item.title = (
        <a
          href={`${detail_view_url}${item.slug}`}
          title={item.title}
          className={`${isParent ? "library-parent" : ""} ${
            isParent && index !== 0 ? "library-parent--next" : ""
          }`}
          style={{
            ...(item.disabled
              ? { pointerEvents: "none", cursor: "default" }
              : {})
          }}
          aria-label={`${item.title} ${
            Array.isArray(item.children) && item.children.length
              ? t("cms.plugins.collectionsFilter.drop_down_list")
              : ""
          }`}
        >
          {item.title}
        </a>
      );

      if (item.children.length > 0) {
        item.children = createLinks(item.children, false);
      }
    });
    return convertedLinks;
  };

  const convertedData = createLinks(collections, true);

  const refreshAriaLabels = () => {
    const switchers = document.querySelectorAll("span.ant-tree-switcher");

    switchers.forEach(title => {
      title?.children[0]
        ? (title.children[0].ariaLabel = `${
            title.className.includes("ant-tree-switcher_open")
              ? t("cms.plugins.collections.collapse")
              : t("cms.plugins.collections.expand")
          }`)
        : "";
    });
  };

  useEffect(() => {
    refreshAriaLabels();
  }, []);

  return (
    <>
      <div className="side-menu__header">
        {t("cms.plugins.collectionsFilter.title")}
      </div>
      <div className="side-menu__list side-menu__list--small">
        <Tree
          defaultExpandedKeys={selectedCollectionPath}
          selectedKeys={[collectionId]}
          onSelect={onSelect}
          treeData={convertedData}
          onExpand={refreshAriaLabels}
          switcherIcon={
            <Button
              type="primary"
              className="ant-tree-icon to-focus"
              icon={<CaretDownOutlined aria-hidden="true" />}
              aria-label={t("cms.plugins.collectionsFilter.expand")}
            />
          }
        />
      </div>
    </>
  );
};

export default Collections;
