import {
  ArrowRightOutlined,
  DeleteOutlined,
  CloseOutlined,
  EditOutlined,
  LockOutlined
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import Input from "@app/components/Input/CMSInput";
import Modal from "../assets/Modal/Modal";
import axiosSession from "@app/config/axiosSession";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import handleErrors from "@app/helpers/handleErrors";

const ProfileInfo = ({
  setActiveSubpage,
  profileData,
  t,
  subpages,
  setDeleteModalOpen
}) => {
  return (
    <>
      <div className="profile__info">
        <p>
          <span>{t("cms.plugins.myProfile.name")}: </span>
          <strong>{profileData.first_name}</strong>
        </p>
        <p>
          <span>{t("cms.plugins.myProfile.lastName")}: </span>
          <strong>{profileData.last_name}</strong>
        </p>
        <p>
          <span>E-mail: </span>
          <strong>{profileData.email}</strong>
        </p>
        <p>
          <span>{t("cms.plugins.myProfile.username")}: </span>
          <strong>{profileData.username}</strong>
        </p>
      </div>
      <div className="profile__info__actions">
        <button
          className="btn btn--text"
          onClick={() => setActiveSubpage(subpages.edit)}
          alt={t("cms.plugins.myProfile.edit")}
        >
          <span>{t("cms.plugins.myProfile.edit")}</span>
          <EditOutlined aria-label={null} />
        </button>
        <button
          className="btn btn--text"
          onClick={() => setActiveSubpage(subpages.password)}
          alt={t("cms.plugins.myProfile.changePassword")}
        >
          <span>{t("cms.plugins.myProfile.changePassword")}</span>
          <LockOutlined aria-label={null} />
        </button>
        <button
          className="btn btn--text"
          onClick={() => setDeleteModalOpen(true)}
          alt={t("cms.plugins.myProfile.delete")}
        >
          <span>{t("cms.plugins.myProfile.delete")}</span>
          <DeleteOutlined aria-label={null} />
        </button>
      </div>
    </>
  );
};

const ChangeData = ({
  handleSubmit,
  register,
  errors,
  setError,
  t,
  changeDataUrl,
  setActiveSubpage,
  fetchData,
  profileData,
  subpages,
  notifySuccess
}) => {
  const { notifyError } = useGenericToastify();
  const onSubmit = data => {
    axiosSession
      .put(changeDataUrl, {
        username: data.username,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name
      })
      .then(() => {
        fetchData();
        setActiveSubpage(subpages.info);
        notifySuccess(t("cms.plugins.myProfile.successDataChange"));
      })
      .catch(function(error) {
        notifyError(t("cms.plugins.login.errors.formError"));
        handleErrors(error.response.data, setError);
      });
  };

  return (
    <div>
      <form className="form-group" onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="first_name"
          label={t("cms.plugins.myProfile.name")}
          ariaLabel={t("cms.plugins.myProfile.name")}
          register={register({
            required: t("cms.plugins.register.errors.required")
          })}
          errors={errors}
          defaultValue={profileData.first_name}
          required
        />

        <Input
          name="last_name"
          label={t("cms.plugins.myProfile.lastName")}
          ariaLabel={t("cms.plugins.myProfile.lastName")}
          darkTheme={true}
          register={register({
            required: t("cms.plugins.register.errors.required")
          })}
          errors={errors}
          defaultValue={profileData.last_name}
          required
        />

        <Input
          name="email"
          label="E-mail"
          ariaLabel="E-mail"
          placeholder="E-mail"
          type="email"
          register={register({
            required: t("cms.plugins.register.errors.required")
          })}
          errors={errors}
          defaultValue={profileData.email}
          required
        />

        <Input
          name="username"
          label={t("cms.plugins.myProfile.username")}
          ariaLabel={t("cms.plugins.myProfile.username")}
          darkTheme={true}
          register={register({
            required: t("cms.plugins.register.errors.required")
          })}
          errors={errors}
          defaultValue={profileData.username}
          disabled
          isInputGray
        />

        <button type="submit" className="btn">
          {t("cms.plugins.myProfile.saveChanges")}
        </button>
        <button
          className="btn btn--text"
          onClick={() => {
            setActiveSubpage(subpages.info);
          }}
        >
          {t("cms.plugins.myProfile.cancel")}
        </button>
      </form>
      <div>
        <p>
          <span className="color-red">*</span> - {t("app.requiredDescription")}
        </p>
      </div>
    </div>
  );
};

const ChangePassword = ({
  handleSubmit,
  register,
  errors,
  getValues,
  setError,
  t,
  changePasswordUrl,
  setActiveSubpage,
  subpages,
  notifySuccess
}) => {
  const [incorrectPassword, setIncorrectPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isRepeatNewPasswordVisible, setIsRepeatNewPasswordVisible] = useState(
    false
  );

  const onSubmit = data => {
    axiosSession
      .post(changePasswordUrl, {
        new_password1: data.new_password,
        new_password2: data.repeat_new_password,
        old_password: data.old_password
      })
      .then(() => {
        setActiveSubpage(subpages.info);
        notifySuccess(t("cms.plugins.myProfile.successPassChange"));
      })
      .catch(err => {
        Object.keys(err.response.data).forEach(item => {
          if (err.response.data[item][0] === "Invalid password") {
            setIncorrectPassword(t("cms.plugins.myProfile.incorrectPassword"));
          }
        });
        if (err.response.data.new_password2) {
          setError("new_password", "", err.response.data.new_password2[0]);
        }
        console.error(err);
      });
  };

  return (
    <div>
      <form className="form-group" onSubmit={handleSubmit(onSubmit)}>
        <div className="input-container">
          <Input
            className="with-icon"
            type={isPasswordVisible ? "text" : "password"}
            name="old_password"
            label={`${t("cms.plugins.myProfile.password")} *`}
            register={register({
              required: t("cms.plugins.register.errors.required")
            })}
            placeholder={t(
              "cms.plugins.myProfile.placeholders.currentPassword"
            )}
            ariaLabel={t("cms.plugins.myProfile.placeholders.currentPassword")}
            errors={errors}
            onChange={data => {
              if (incorrectPassword !== "") {
                setIncorrectPassword("");
              }
              return data;
            }}
          />
          <button
            className="icon-end"
            type="button"
            aria-label={t("cms.plugins.myProfile.altPreview")}
            onClick={() => setIsPasswordVisible(prev => !prev)}
          >
            <FontAwesomeIcon
              className="show-password-btn__icon"
              icon={isPasswordVisible ? faEyeSlash : faEye}
            />
          </button>
        </div>

        {incorrectPassword && (
          <p className="is-error-text">{incorrectPassword}</p>
        )}

        <div className="input-container">
          <Input
            className="with-icon"
            type={isNewPasswordVisible ? "text" : "password"}
            name="new_password"
            label={`${t("cms.plugins.myProfile.newPassword")} *`}
            register={register({
              required: t("cms.plugins.register.errors.required")
            })}
            placeholder={t("cms.plugins.myProfile.placeholders.newPassword")}
            ariaLabel={t("cms.plugins.myProfile.placeholders.newPassword")}
            errors={errors}
          />
          <button
            className="icon-end"
            type="button"
            aria-label={t("cms.plugins.myProfile.altPreview")}
            onClick={() => setIsNewPasswordVisible(prev => !prev)}
          >
            <FontAwesomeIcon
              className="show-password-btn__icon"
              icon={isNewPasswordVisible ? faEyeSlash : faEye}
            />
          </button>
        </div>
        <p>{t("cms.plugins.myProfile.passwordHint")}</p>

        <div className="input-container">
          <Input
            className="with-icon"
            type={isRepeatNewPasswordVisible ? "text" : "password"}
            name="repeat_new_password"
            label={`${t("cms.plugins.myProfile.repeatNewPassword")} *`}
            alt={t("cms.plugins.myProfile.repeatNewPassword")}
            register={register({
              required: t("cms.plugins.register.errors.required"),
              validate: value =>
                value === getValues()["new_password"] ||
                t("cms.plugins.register.errors.passwordMismatch")
            })}
            placeholder={t(
              "cms.plugins.myProfile.placeholders.repeatNewPassword"
            )}
            errors={errors}
          />
          <button
            className="icon-end"
            type="button"
            aria-label={t("cms.plugins.myProfile.altPreview")}
            onClick={() => setIsRepeatNewPasswordVisible(prev => !prev)}
          >
            <FontAwesomeIcon
              className="show-password-btn__icon"
              icon={isRepeatNewPasswordVisible ? faEyeSlash : faEye}
            />
          </button>
        </div>

        <button type="submit" className="btn">
          {t("cms.plugins.myProfile.saveChanges")}
        </button>
        <button
          className="btn btn--text"
          onClick={() => {
            setActiveSubpage(subpages.info);
          }}
        >
          {t("cms.plugins.myProfile.cancel")}
        </button>
      </form>
      <div>
        <p>
          <span className="color-red">*</span> - {t("app.requiredDescription")}
        </p>
      </div>
    </div>
  );
};

const MyProfile = ({ config: { id } }) => {
  const [activeSubpage, setActiveSubpage] = useState("profile_info");
  const [profileData, setProfileData] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { notifyError, notifySuccess } = useGenericToastify();

  const SUBPAGES = {
    info: "profile_info",
    edit: "edit_profile",
    password: "change_password"
  };

  const urls = {
    userInfo: "/api/rest-auth/user/",
    changePassword: "/api/rest-auth/password/change/",
    deleteAccount: "/api/accounts/",
    logout: "/api/rest-auth/logout/"
  };

  const fetchData = () => {
    axiosSession
      .get(urls.userInfo, {
        pk: id
      })
      .then(({ data }) => setProfileData(data))
      .catch(err => console.error(err));
  };

  const logout = () => {
    axiosSession
      .post(urls.logout)
      .then(() => {
        window.location.href = "/";
      })
      .catch(error => {
        console.error(error);
      });
  };

  const deleteAccount = () => {
    axiosSession
      .delete(urls.deleteAccount)
      .then(() => {
        notifySuccess(t("cms.plugins.myProfile.deleteSuccess"));
        logout();
      })
      .catch(() => {
        notifyError(t("cms.plugins.myProfile.deleteError"));
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    errors,
    setError,
    getValues,
    setValue
  } = useForm();

  return (
    <>
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title={t("cms.plugins.myProfile.deleteTitle")}
        content={<div>{t("cms.plugins.myProfile.deleteBody")}</div>}
        buttons={
          <>
            <button
              className="btn btn--text"
              onClick={() => setDeleteModalOpen(false)}
              onKeyPress={() => setDeleteModalOpen(false)}
            >
              {t("cms.plugins.profileComments.editModal.cancle")}&nbsp;
              <CloseOutlined className="anticon-dark" aria-label={null} />
            </button>
            <button className="btn" onClick={deleteAccount}>
              {t("cms.plugins.myProfile.delete")}&nbsp;
              <ArrowRightOutlined aria-label={null} />
            </button>
          </>
        }
      />
      {activeSubpage === "profile_info" && (
        <ProfileInfo
          profileData={profileData}
          setActiveSubpage={setActiveSubpage}
          setDeleteModalOpen={setDeleteModalOpen}
          t={t}
          subpages={SUBPAGES}
        />
      )}
      {activeSubpage === "edit_profile" && (
        <ChangeData
          t={t}
          handleSubmit={handleSubmit}
          register={register}
          errors={errors}
          setError={setError}
          getValues={getValues}
          setValue={setValue}
          changeDataUrl={urls.userInfo}
          setActiveSubpage={setActiveSubpage}
          fetchData={fetchData}
          profileData={profileData}
          subpages={SUBPAGES}
          notifySuccess={notifySuccess}
        />
      )}
      {activeSubpage === "change_password" && (
        <ChangePassword
          t={t}
          handleSubmit={handleSubmit}
          register={register}
          errors={errors}
          setError={setError}
          getValues={getValues}
          setValue={setValue}
          changePasswordUrl={urls.changePassword}
          setActiveSubpage={setActiveSubpage}
          subpages={SUBPAGES}
          notifySuccess={notifySuccess}
        />
      )}
    </>
  );
};

export default MyProfile;
