import React from "react";
import Slider from "@components/Slider/Slider";

const Popular = ({ config }) => {
  const popularUrl = "/api/search/frontoffice/popularity/results/";

  return <Slider config={config} url={popularUrl} />;
};

export default Popular;
