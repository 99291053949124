import axiosSession from "../config/axiosSession";

const getLibraries = () => {
  return axiosSession.get("/api/library/select-data/");
};

const getSchemas = () => {
  return axiosSession.get("/api/attributes/schema/select-data/");
};

const getCollectionDetail = id => {
  return axiosSession.get(`/api/collections/${id}/`);
};

const getCollectionPermissions = id => {
  return axiosSession.get(`/api/collections/${id}/permissions/`);
};

const getCollectionSchema = id => {
  return axiosSession.get(`/api/collections/${id}/schema/`);
};

const getCollectionThumbnail = id => {
  return axiosSession.get(`/api/collections/${id}/thumbnails/`);
};

const getCollectionAttributesValue = id => {
  return axiosSession.get(`/api/collections/${id}/values/`);
};

const getCollectionObject = id => {
  return axiosSession.get(`/api/collections/${id}/objects/`);
};

const createCollection = data => {
  return axiosSession.post("/api/collections/", data);
};

const editCollection = (id, data) => {
  return axiosSession.put(`/api/collections/${id}/`, data);
};
const getCollectionList = () => {
  return axiosSession.get(`/api/collections/`);
};

export {
  getCollectionAttributesValue,
  getCollectionDetail,
  getCollectionPermissions,
  getCollectionSchema,
  getCollectionThumbnail,
  getLibraries,
  getSchemas,
  createCollection,
  editCollection,
  getCollectionObject,
  getCollectionList
};
