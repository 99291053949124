import React, { useState } from "react";
import { ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";

import axiosSession from "@app/config/axiosSession";
import useGenericToastify from "@app/hooks/useGenericToastify";
import CMSTable from "@components/Table/CMSTable";
import Modal from "../assets/Modal/Modal";
import { useTranslation } from "react-i18next";
import { Space } from "antd";

const Bookmarks = () => {
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useGenericToastify();
  const [itemData, setItemData] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [shouldTableRefresh, setShouldTableRefresh] = useState(false);
  const [CMSTableView, setCMSTableView] = useState("table");

  const urls = {
    get: "/api/attributes/user/bookmarks/"
  };

  const columns = [
    {
      title: t("cms.plugins.bookmarks.structure.title"),
      dataIndex: "name",
      key: "digital_item__name",
      sorter: true,
      width: "30%"
    },
    {
      title: t("cms.plugins.bookmarks.structure.content"),
      dataIndex: "content",
      key: "content",
      sorter: true,
      width: "30%"
    },
    {
      title: t("cms.plugins.bookmarks.structure.page"),
      dataIndex: "page_number",
      key: "page_number",
      sorter: true,
      width: "6%"
    },
    {
      title: t("cms.plugins.bookmarks.structure.created"),
      dataIndex: "created",
      key: "created",
      sorter: true,
      width: "15%"
    },
    {
      title: t("cms.plugins.bookmarks.structure.actions"),
      dataIndex: "",
      sorter: false,
      width: "8%",
      fixed: "right",
      render: (text, record) => (
        <Space
          size="small"
          direction={CMSTableView === "list" ? "vertical" : "horizontal"}
        >
          <a
            className="btn btn--text-black"
            href={record.url}
            title={t("cms.plugins.bookmarks.actions.goto")}
            aria-label={t("cms.plugins.bookmarks.actions.goto")}
          >
            <ArrowRightOutlined className="anticon-dark" aria-hidden="true" />{" "}
            {CMSTableView === "list" && t("cms.plugins.bookmarks.actions.goto")}
          </a>
          <button
            className="btn btn--text-black"
            title={t("cms.plugins.bookmarks.actions.delete")}
            aria-label={t("cms.plugins.bookmarks.actions.delete")}
            onClick={() => onDelete(record)}
          >
            <DeleteOutlined className="anticon-dark" aria-hidden="true" />{" "}
            {CMSTableView === "list" &&
              t("cms.plugins.bookmarks.actions.delete")}
          </button>
        </Space>
      )
    }
  ];

  const onDelete = item => {
    setItemData({ id: item.id, name: item.name });
    setModalIsOpen(true);
  };

  const onConfirmDelete = id => {
    axiosSession
      .delete(`/api/attributes/user/bookmarks/${id}/`)
      .then(() => {
        notifySuccess(t("cms.plugins.bookmarks.message.deleteSuccess"));
        setShouldTableRefresh(true);
      })
      .catch(err => {
        notifyError(t("cms.plugins.bookmarks.message.deleteError"));
        console.error(err);
      })
      .finally(() => {
        setModalIsOpen(false);
        setShouldTableRefresh(false);
      });
  };

  return (
    <>
      <Modal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        title={t("cms.plugins.notes.message.headerTitle")}
        content={
          <div className="only-text" key={itemData.id}>
            <DeleteOutlined />
            <span>{t("cms.plugins.notes.message.bodyContent")}</span>
          </div>
        }
        buttons={
          <>
            <button
              className="btn btn--text"
              onClick={() => setModalIsOpen(false)}
              onKeyPress={() => setModalIsOpen(false)}
            >
              {t("cms.plugins.notes.message.btnDeleteNo")}
            </button>
            <button
              className="btn"
              onClick={() => onConfirmDelete(itemData.id)}
            >
              {t("cms.plugins.notes.message.btnDeleteYes")}
            </button>
          </>
        }
      />
      <CMSTable
        columns={columns}
        dataUrl={urls.get}
        listView
        shouldTableRefresh={shouldTableRefresh}
        setUpperView={setCMSTableView}
      />
    </>
  );
};

export default Bookmarks;
