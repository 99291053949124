import React from "react";
import { useTranslation } from "react-i18next";
import ArrowButton from "../../plugins/components/assets/Button/ArrowButton";

const ConfirmForgotPassword = () => {
  const { t } = useTranslation();

  return (
    <section className="section wrapper">
      <nav className="breadcrumbs">
        <ul>
          <li>
            <a
              className="breadcrumbs__frontoffice-link"
              href="/"
              title={t("cms.plugins.registerEmail.mainPage")}
            >
              {t("cms.plugins.registerEmail.mainPage")}
            </a>
          </li>

          <li>
            <span>&rarr;</span>
          </li>
          <li>
            <a className="breadcrumbs__frontoffice-link" href="/login">
              {t("cms.plugins.registerEmail.login")}
            </a>
          </li>

          <li>
            <span>&rarr;</span>
          </li>
          <li>
            <a
              className="breadcrumbs__frontoffice-link"
              href="/forgot-username/"
              title={t("cms.plugins.forgotPassword.label")}
            >
              {t("cms.plugins.forgotPassword.label")}
            </a>
          </li>
          <li>
            <span>&rarr;</span>
          </li>
          <li>
            <span className="breadcrumbs__current">
              {t("cms.plugins.forgotPassword.confirm")}
            </span>
          </li>
        </ul>
      </nav>

      <section className="section">
        <div className="login__title-row">
          <h2 className="header--decorated">
            {t("cms.plugins.forgotPassword.confirm")}
          </h2>
          <ArrowButton href="/login" className="login__title-row__register">
            {t("cms.plugins.login.login")}
          </ArrowButton>
        </div>
        <div className="login__container">
          <p>{t("cms.plugins.forgotPassword.confirmMessage")}.&nbsp;</p>
          <ArrowButton href="/login" className="login__links__register">
            {t("cms.plugins.registerEmail.login")}
          </ArrowButton>
        </div>
      </section>
    </section>
  );
};

export default ConfirmForgotPassword;
