import "./GenericFieldArray.scss";

import { ErrorMessage, useFieldArray } from "react-hook-form";
import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericInput from "../Input/GenericInput";
import GenericSelect from "../Select/GenericSelect";
import PropTypes from "prop-types";
import has from "lodash/has";
import i18n from "@app/i18n";
import isEmpty from "lodash/isEmpty";

const GenericFieldArray = ({
  name,
  control,
  errors,
  addButtonText,
  appendOption,
  deleteButtonTitle,
  selectName,
  selectOptions,
  selectPlaceholder,
  selectLabel,
  handleSelectChange,
  inputName,
  inputLabel,
  removeAllArray,
  setRemoveAllArray,
  disabled,
  secondSelectOptions,
  secondSelectPlaceholder,
  secondSelectName,
  secondSelectLabel,
  secondSelect,
  secondRequired,
  noOptionsMessage,
  isFirstOptionVisible,
  defaultValue,
  secondDefaultValue,
  isTypeSelected,
  menuPosition,
  isFrontoffice,
  className,
  classNamePrefix
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: name
  });

  const [disableButton, setDisableButton] = useState(isEmpty(selectOptions));

  useEffect(() => {
    if (removeAllArray) {
      remove();
      setRemoveAllArray(false);
    }
  }, [remove, removeAllArray, setRemoveAllArray]);

  useEffect(() => {
    setDisableButton(isEmpty(selectOptions));
  }, [selectOptions]);

  useEffect(() => {
    if (isFirstOptionVisible && !removeAllArray && isTypeSelected) {
      append(appendOption);
    }
  }, [removeAllArray, isFirstOptionVisible, isTypeSelected]);

  return (
    <div
      className={`field-array ${
        isFrontoffice ? " grid" : "columns is-full is-multiline"
      }`}
    >
      {fields.map((item, index) => {
        return (
          <React.Fragment key={item.id}>
            <div
              className={`${
                isFrontoffice
                  ? `col-xs-${secondSelect ? 6 : 12} col-md-${
                      secondSelect ? 3 : 5
                    }`
                  : `column is-${secondSelect ? 3 : 5}`
              }`}
            >
              <GenericSelect
                options={selectOptions}
                placeholder={selectPlaceholder}
                control={control}
                required={{
                  required: "To pole jest wymagane!",
                  validate: value => value.id !== "" || "To pole jest wymagane!"
                }}
                handleSelectChange={handleSelectChange}
                name={`${name}[${index}].${selectName}`}
                errors={errors}
                defaultValue={defaultValue ? defaultValue : item[selectName]}
                label={selectLabel}
                isDisabled={disabled}
                noOptionsMessage={noOptionsMessage}
                menuPosition={menuPosition}
                className={className}
                classNamePrefix={classNamePrefix}
              />
            </div>
            {secondSelect && (
              <div
                className={`${
                  isFrontoffice ? "col-xs-6 col-md-3" : "column is-3"
                }`}
              >
                <GenericSelect
                  options={secondSelectOptions}
                  placeholder={secondSelectPlaceholder}
                  control={control}
                  required={{
                    required: "To pole jest wymagane!",
                    validate: value =>
                      value.id !== "" || "To pole jest wymagane!"
                  }}
                  handleSelectChange={handleSelectChange}
                  name={`${name}[${index}].${secondSelectName}`}
                  errors={errors}
                  defaultValue={
                    secondDefaultValue
                      ? secondDefaultValue
                      : item[secondSelectName]
                  }
                  label={secondSelectLabel}
                  isDisabled={disabled}
                  noOptionsMessage={noOptionsMessage}
                  menuPosition={menuPosition}
                  menuPosition={menuPosition}
                  className={className}
                  classNamePrefix={classNamePrefix}
                />
              </div>
            )}
            <div
              className={`${
                isFrontoffice
                  ? `col-xs-11 col-md-${secondSelect ? 5 : 11}`
                  : "column is-5"
              }  `}
            >
              <GenericInput
                control={control}
                name={`${name}[${index}].${inputName}`}
                label={inputLabel}
                defaultValue={`${item[inputName]}`}
                darkTheme={false}
                required={secondRequired}
                errors={errors}
                disabled={disabled}
              />
            </div>
            {disabled ? (
              ""
            ) : (
              <div
                className={`${
                  isFrontoffice
                    ? "col-xs-1"
                    : `column is-${secondSelect ? 1 : 2}`
                } `}
              >
                <div
                  className="generic_field_array__field-row__btn"
                  onClick={() => remove(index)}
                  onKeyPress={() => remove(index)}
                  role="button"
                  tabIndex="0"
                  title={deleteButtonTitle}
                >
                  <FontAwesomeIcon icon="trash" />
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })}
      {disabled ? (
        ""
      ) : (
        <div className={`${isFrontoffice ? "col-xs-12" : "column is-full"} `}>
          <button
            className={isFrontoffice ? "btn" : "button button--is-black"}
            type="button"
            onClick={() => append(appendOption)}
            disabled={disableButton}
          >
            {addButtonText}
          </button>
        </div>
      )}

      {!isEmpty(errors) && has(errors, name) ? (
        <div className={`${isFrontoffice ? "col-xs-12" : "column is-full"} `}>
          <ErrorMessage
            as={<p className="help is-danger" />}
            errors={errors}
            name={name}
          />
        </div>
      ) : null}
    </div>
  );
};

GenericFieldArray.propTypes = {
  secondSelect: PropTypes.bool,
  secondRequired: PropTypes.object,
  noOptionsMessage: PropTypes.func,
  menuPosition: PropTypes.string,
  isFrontoffice: PropTypes.bool,
  menuPosition: PropTypes.string,
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  selectPlaceholder: PropTypes.string,
  secondSelectPlaceholder: PropTypes.string
};

GenericFieldArray.defaultProps = {
  secondSelect: false,
  secondRequired: {
    required: i18n.t("app.requiredField")
  },
  noOptionsMessage: () => i18n.t("app.select"),
  isFirstOptionVisible: false,
  menuPosition: "absolute",
  isFrontoffice: false,
  className: "",
  classNamePrefix: "",
  selectPlaceholder: i18n.t("app.selectPlaceholder"),
  secondSelectPlaceholder: i18n.t("app.selectPlaceholder")
};

export default GenericFieldArray;
