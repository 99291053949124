import React, { useState } from "react";

import isEmpty from "lodash/isEmpty";
import CMSTable from "@components/Table/CMSTable";
import Modal from "../assets/Modal/Modal";
import { useTranslation } from "react-i18next";
import { EyeOutlined } from "@ant-design/icons";

const ProfileMeta = () => {
  const { t } = useTranslation();
  const [itemData, setItemData] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [CMSTableView, setCMSTableView] = useState("table");

  const urls = {
    get: "/api/digital_items/correction/"
  };

  const columns = [
    {
      title: t("cms.plugins.meta.structure.title"),
      dataIndex: "digital_item",
      key: "digital_item",
      sorter: true,
      width: "20%"
    },
    {
      title: t("cms.plugins.meta.structure.type"),
      dataIndex: "type",
      key: "correction_type",
      sorter: true,
      width: "15%"
    },
    {
      title: t("cms.plugins.meta.structure.status"),
      dataIndex: "status",
      key: "status",
      sorter: true,
      width: "15%",
      render: (text, record) => (
        <>
          <span
            className={
              text === "Zaakceptowana"
                ? "color-green"
                : text === "Odrzucona"
                ? "color-red"
                : "color-gray-16"
            }
          >
            •&nbsp;
          </span>
          {text}
        </>
      )
    },
    {
      title: t("cms.plugins.meta.structure.created"),
      dataIndex: "created",
      key: "created",
      sorter: true,
      width: "15%"
    },
    {
      title: t("cms.plugins.bookmarks.structure.actions"),
      dataIndex: "",
      sorter: false,
      width: "15%",
      fixed: "right",
      render: (text, record) => (
        <button
          className="btn btn--text-black"
          title={t("cms.plugins.meta.actions.preview")}
          aria-label={t("cms.plugins.meta.actions.preview")}
          onClick={() => onPreview(record)}
        >
          <EyeOutlined className="anticon-dark" aria-hidden="true" />{" "}
          {CMSTableView === "list" && t("cms.plugins.meta.actions.preview")}
        </button>
      )
    }
  ];

  const onPreview = item => {
    setItemData(item);
    setModalIsOpen(true);
  };

  return (
    <>
      <Modal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        title={t("cms.plugins.meta.modal.title")}
        content={
          <div className="profile__info" key={itemData.id}>
            {!isEmpty(itemData) && (
              <>
                <div className="profile__info__description">
                  <p>
                    <span>{t("cms.plugins.meta.structure.title")}:</span>
                    <strong>{itemData.digital_item}</strong>
                  </p>
                  <p>
                    <span>{t("cms.plugins.meta.structure.created")}:</span>
                    <strong>{itemData.created}</strong>
                  </p>
                  <p>
                    <span>{t("cms.plugins.meta.structure.status")}:</span>
                    <strong>{itemData.status}</strong>
                  </p>
                  <p>
                    <span>{t("cms.plugins.meta.structure.type")}:</span>
                    <strong>{itemData.type}</strong>
                  </p>
                </div>
                {itemData.content.map(elem => (
                  <div className="profile__info__box">
                    <p>
                      <span>{t("cms.plugins.meta.modal.attributeFile")}:</span>
                      <strong>{elem.object}</strong>
                    </p>
                    <p>
                      <span>{t("cms.plugins.meta.modal.category")}:</span>
                      <strong>{elem.category}</strong>
                    </p>
                    <p>
                      <span>{t("cms.plugins.meta.modal.comment")}:</span>
                      <strong>{elem.content || "-"}</strong>
                    </p>
                  </div>
                ))}
              </>
            )}
          </div>
        }
        buttons={null}
      />

      <CMSTable
        columns={columns}
        dataUrl={urls.get}
        listView
        setUpperView={setCMSTableView}
      />
    </>
  );
};

export default ProfileMeta;
