import "./DictionariesCheckExternalValue.scss";

import React, { useEffect, useState } from "react";
import { getDict, getExternalDictValues } from "@app/services/services";

import ClipLoader from "react-spinners/ClipLoader";
import GenericInput from "@app/components/Input/GenericInput";
import { useForm } from "react-hook-form";

const DictionariesCheckExternalValue = ({ dictID }) => {
  const { errors, control, getValues, setError, clearError } = useForm();
  const [searchResults, setSearchResults] = useState([]);
  const [searched, setSearched] = useState(false);
  const [searchKeys, setSearchKeys] = useState([]);
  const [sourceName, setSourceName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const generateKey = pre => {
    return `${pre}_${Math.random()}`;
  };
  const displayElement = (item, value) => {
    const toDisplay = [];
    for (const row in item) {
      if (row === value) {
        for (const field of item[row]) {
          const toSubDisplay = [];
          for (const subfield in field) {
            toSubDisplay.push(
              <p
                key={generateKey(field[subfield])}
                className="search-results__body__items__item__body__element"
              >
                {subfield}: {field[subfield]}
              </p>
            );
          }
          toDisplay.push(
            <div
              key={generateKey(field)}
              className="search-results__body__items__item__body"
            >
              {toSubDisplay}
            </div>
          );
        }
      }
    }
    return toDisplay;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dict = await getDict(dictID);
        setSourceName(dict.data.source_name);
        setSearchKeys(dict.data.search_values);
      } catch (error) {
        console.error(error);
        error.response;
      }
    };
    fetchData();
  }, [dictID]);

  const onSubmit = async () => {
    const value = getValues("value");
    if (value !== "") {
      clearError("value");
      setIsLoading(true);
      const externalDictValues = await getExternalDictValues(dictID, {
        value
      });
      setSearchResults(externalDictValues?.data?.results ?? []);
      setSearched(true);
      setIsLoading(false);
    } else {
      setError("value", "", "Szukana wartość nie może być pusta");
    }
  };

  return (
    <section className="dictionaries-entries-form">
      {sourceName ? (
        <>
          <h2 className="dictionaries-entries-form__title">{sourceName}</h2>
          <div>
            <GenericInput
              control={control}
              name="value"
              label="Wyszukaj rekord wzorcowy"
              placeholder="Wyszukaj rekord wzorcowy"
              darkTheme={false}
              required={{ required: "Szukana wartość nie może być pusta" }}
              errors={errors}
            />
            <div className="buttons">
              <button
                className={`button button--is-wide library-form__btn`}
                onClick={onSubmit}
              >
                Szukaj
              </button>
            </div>
            {!isLoading ? (
              searchResults.length > 0 ? (
                <div className="search-results">
                  <h1 className="search-results__title">Wyniki wyszukiwania</h1>
                  <div className="search-results__body">
                    {searchResults.map((item, index) => (
                      <div
                        key={generateKey(index)}
                        className="search-results__body__items"
                      >
                        {searchKeys.map(value => (
                          <div
                            key={generateKey(value)}
                            className="search-results__body__items__item"
                          >
                            <p className="search-results__body__items__item__title">
                              {value}:
                            </p>
                            {displayElement(item.fields, value)}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              ) : searched ? (
                <div className="search-results">
                  <div className="search-results__body">
                    <p className="search-results__body__text">
                      Nie znaleziono wyników wyszukiwania dla podanego hasła
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )
            ) : (
              <div className="search-results-loading">
                <ClipLoader size={60} color={"black"} loading={isLoading} />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="step-form-loader">
          <ClipLoader size={60} color={"black"} loading={!sourceName} />
        </div>
      )}
    </section>
  );
};

export default DictionariesCheckExternalValue;
