const handleSkiplinkClick = targetElSelector => {
  const targetEl = document.querySelector(targetElSelector);
  if (targetEl) {
    const headerOffset =
      document.querySelector(".header")?.getBoundingClientRect().height || 0;
    const targetElPosition = targetEl.getBoundingClientRect().top;

    window.scrollTo({
      top: targetElPosition - headerOffset
    });

    targetEl.focus({ preventScroll: true });
    if (targetEl !== document.activeElement) {
      const focusable = targetEl.querySelector(
        "a, button, input, [tabindex='0']"
      );
      focusable?.focus({ preventScroll: true });
    }
  }
};

export default handleSkiplinkClick;
