import React, { useEffect, useState } from "react";

import ToggleSection from "@app/plugins/components/assets/ToggleSection/ToggleSection";
import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import ArrowButton from "../assets/Button/ArrowButton";

const Similar = ({ config }) => {
  const {
    digital_item_id: id,
    number_of_items: limit,
    detail_view_url: detailUrl,
    name,
    default_visible
  } = config;
  const { t } = useTranslation();
  const [showSection, setShowSection] = useState(default_visible);
  const [data, setData] = useState({});

  const similarUrl = "/api/search/frontoffice/similar/";
  const noImageUrl = "/static/images/no_image.png";

  useEffect(() => {
    if (id && showSection && isEmpty(data)) {
      axiosSession
        .get(similarUrl, {
          params: {
            id,
            limit
          }
        })
        .then(({ data }) => setData(data))
        .catch(err => console.error(err));
    }
  }, [id, showSection]);

  return (
    <>
      {id && (
        <>
          <ToggleSection
            title={name}
            showSection={showSection}
            setShowSection={setShowSection}
          />
          {showSection && (
            <div className="plugin-similar">
              {!isEmpty(data) ? (
                data.items.map(elem => {
                  const title = elem.title || "";
                  const author = elem.authors || "";
                  const href = `${detailUrl}/${elem.slug}/`;

                  return (
                    <div key={elem.id} className="plugin-similar__item">
                      <div className="plugin-similar__item__img-container">
                        <img
                          src={elem.thumbnail}
                          alt={title}
                          loading="lazy"
                          onError={e => {
                            e.target.onerror = null;
                            e.target.src = noImageUrl;
                          }}
                        />
                      </div>
                      <div className="plugin-similar__item__attributes">
                        <div className="plugin-similar__item__attributes__header">
                          <span
                            className="plugin-similar__item__attributes__header__title"
                            title={title}
                          >
                            {title}
                          </span>
                          <span
                            className="plugin-similar__item__attributes__header__author"
                            title={author}
                          >
                            {author}
                          </span>
                        </div>
                        <ArrowButton
                          className="plugin-similar__item__attributes__button"
                          href={href}
                        >
                          {t("cms.plugins.similar.read")}
                        </ArrowButton>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="plugin-similar__loader">
                  <div className="loader"></div>&nbsp;
                  {t("cms.plugins.similar.loading")}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Similar;
