import { useEffect } from "react";

const setH1TagFromTitleTag = () => {
  useEffect(() => {
    const titleTag = document.title;
    const headerH1 = document.getElementsByTagName("H1");
    if (headerH1) {
      headerH1[0].textContent = titleTag;
    }
  }, []);
};

export default setH1TagFromTitleTag;
