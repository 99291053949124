import React from "react";
import PropTypes from "prop-types";

const TextRow = ({ title, content, link, containLine }) => {
  return (
    <div className="text-row">
      <span className="text-row__title">{title}:</span>
      {link ? (
        <a
          className={`text-row__content text-row__content--${
            containLine ? "contain-line" : "break-line"
          }`}
          href={link}
          target="_blank"
        >
          {content}
        </a>
      ) : (
        <span className="text-row__content">{content}</span>
      )}
    </div>
  );
};

export default TextRow;

TextRow.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  link: PropTypes.string,
  containLine: PropTypes.bool
};

TextRow.defaultProps = {
  link: null,
  containLine: false
};
