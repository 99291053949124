import React, { useState } from "react";

import CMSTable from "@components/Table/CMSTable";
import Modal from "../assets/Modal/Modal";
import TruncateMarkup from "react-truncate-markup";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { EyeOutlined } from "@ant-design/icons";

const OCR = () => {
  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [CMSTableView, setCMSTableView] = useState("table");

  const urls = {
    get: "/api/digital_items/annotation-correction/all/"
  };

  const columns = [
    {
      title: t("cms.plugins.ocr.structure.title"),
      dataIndex: "digital_item_name",
      key: "digital_file__digital_item__name",
      sorter: true,
      width: "15%"
    },
    {
      title: t("cms.plugins.ocr.structure.textBefore"),
      dataIndex: "old_content",
      key: "old_content",
      sorter: true,
      width: "15%"
    },
    {
      title: t("cms.plugins.ocr.structure.textAfter"),
      dataIndex: "correction",
      key: "correction",
      sorter: true,
      width: "15%"
    },
    {
      title: t("cms.plugins.ocr.structure.status"),
      dataIndex: "status_name",
      key: "status",
      sorter: true,
      width: "15%",
      render: (text, record) => (
        <>
          <span
            className={
              text === "Zaakceptowana"
                ? "color-green"
                : text === "Odrzucona"
                ? "color-red"
                : "color-gray-16"
            }
          >
            •&nbsp;
          </span>
          {text}
        </>
      )
    },
    {
      title: t("cms.plugins.ocr.structure.created"),
      dataIndex: "created",
      key: "created",
      sorter: true,
      width: "15%"
    },
    {
      title: t("cms.plugins.notes.structure.actions"),
      dataIndex: "",
      sorter: false,
      width: "15%",
      fixed: "right",
      render: (text, record) => (
        <button
          className="btn btn--text-black"
          title={t("cms.plugins.ocr.action")}
          aria-label={t("cms.plugins.ocr.action")}
          onClick={() => {
            setSelectedItem(record);
            setDisplayModal(true);
          }}
        >
          <EyeOutlined className="anticon-dark" aria-hidden="true" />{" "}
          {CMSTableView === "list" && t("cms.plugins.ocr.action")}
        </button>
      )
    }
  ];

  const tableMessages = {
    pagination: {
      show: t("app.table.pagination.show"),
      results: t("app.table.pagination.results"),
      goto: t("app.table.pagination.goto")
    },
    search: {
      button: t("app.table.search.button"),
      placeholder: t("app.table.search.placeholder")
    },
    loading: t("app.table.loading"),
    noItems: t("cms.plugins.ocr.noItems"),
    fetchError: t("app.table.fetchError")
  };

  return (
    <>
      <Modal
        open={displayModal}
        onClose={() => setDisplayModal(false)}
        title={t("cms.plugins.ocr.header")}
        content={
          <div className="profile__info" key={selectedItem.id}>
            {!isEmpty(selectedItem) && (
              <>
                <p>
                  <span>{t("cms.plugins.ocr.preview.dateTime")}:</span>
                  <strong>{selectedItem.created}</strong>
                </p>
                <p>
                  <span>{t("cms.plugins.ocr.preview.itemTitle")}:</span>
                  <strong>{selectedItem.digital_item_name}</strong>
                </p>
                <p>
                  <span>{t("cms.plugins.ocr.preview.imgBefore")}:</span>
                  <img
                    alt={`${t("cms.plugins.ocr.preview.imgBefore")}-${
                      selectedItem.digital_item_name
                    }`}
                    src={selectedItem.image_before}
                  />
                </p>
                <p>
                  <span>{t("cms.plugins.ocr.preview.imgAfter")}:</span>
                  <img
                    alt={`${t("cms.plugins.ocr.preview.imgAfter")}-${
                      selectedItem.digital_item_name
                    }`}
                    src={selectedItem.image_after}
                  />
                </p>
                <p>
                  <span>{t("cms.plugins.ocr.preview.textBefore")}:</span>
                  <strong>{selectedItem.old_content}</strong>
                </p>
                <p>
                  <span>{t("cms.plugins.ocr.preview.textAfter")}:</span>
                  <strong>{selectedItem.correction}</strong>
                </p>
              </>
            )}
          </div>
        }
        buttons={null}
      />
      <CMSTable
        columns={columns}
        dataUrl={urls.get}
        listView
        setUpperView={setCMSTableView}
      />
    </>
  );
};

export default OCR;
