import React, { useEffect, useState } from "react";

import { Button, Tree } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import axiosSession from "@app/config/axiosSession";
import { useTranslation } from "react-i18next";

const CollectionsFilter = ({
  collectionsDict,
  filterOptions,
  setFilterOptions
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [parent, setParent] = useState([]);

  const treeUrl = "/api/collections/tree/";

  useEffect(() => {
    if (!isMounted) {
      axiosSession.get(treeUrl).then(({ data }) => {
        const hasCollectionsElements = data.results.filter(
          item => !item.isLeaf
        );
        const treeData = hasCollectionsElements.map((elem, index) => ({
          ...elem,
          key: elem.id,
          title: (
            <span
              className={`library-parent to-focus ${
                index !== 0 ? "library-parent--next" : ""
              }`}
              role="button"
              aria-label={genTitle(elem, !elem?.isLeaf)}
              key={index}
              tabIndex="0"
            >
              {genTitle(elem)}
            </span>
          )
        }));

        setData(treeData);
      });

      setIsMounted(true);
    }
  }, [isMounted]);

  useEffect(() => {
    if (filterOptions?.collections?.length == 0) {
      setParent([]);
    }
  }, [filterOptions]);

  const genTitle = ({ title, id }, condition) =>
    `${title} (${collectionsDict[id] || 0}) ${
      condition ? t("cms.plugins.collectionsFilter.drop_down_list") : ""
    }`;

  const updateTreeData = (list, key, children) => {
    return list.map(node => {
      if (node.key === key) {
        return { ...node, children: children };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children)
        };
      }
      return node;
    });
  };

  const onLoadData = async ({ id, key }) => {
    await axiosSession
      .get(treeUrl, { params: { parent: id } })
      .then(({ data }) => {
        const children = data.results.map(elem => ({
          ...elem,
          key: elem.id,
          title: (
            <span
              tabIndex="0"
              role="button"
              className="to-focus"
              aria-label={genTitle(elem, !elem?.isLeaf)}
            >
              {genTitle(elem)}
            </span>
          )
        }));
        setTimeout(() => {
          setData(origin => updateTreeData(origin, key, children));
        }, 500);
      });
  };

  const onSelect = (_, info) => {
    setFilterOptions(origin => {
      let temp = Object.assign({}, origin);
      origin["collections"].includes(info.node.id)
        ? (temp["collections"] = origin["collections"].filter(
            x => x !== info.node.id
          ))
        : temp["collections"].push(info.node.id);
      return temp;
    });
    setParent([`${info.node.parent}`]);
  };

  return (
    <>
      {isMounted && data.length > 0 && (
        <>
          <div className="side-menu__header" tabIndex="0">
            {t("cms.plugins.collectionsFilter.title")}
          </div>
          <div className="side-menu__list side-menu__list--small">
            <Tree
              key={`collection-${Object.keys(collectionsDict)?.length ?? 0}`}
              multiple
              treeData={data}
              loadData={onLoadData}
              defaultSelectedKeys={filterOptions["collections"]}
              defaultExpandedKeys={parent}
              defaultExpandAll={false}
              onSelect={onSelect}
              switcherIcon={
                <Button
                  type="primary"
                  className="ant-tree-icon to-focus"
                  icon={<CaretDownOutlined aria-hidden="true" />}
                  aria-label={t("cms.plugins.collectionsFilter.expand")}
                />
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default CollectionsFilter;
