import React, { useState } from "react";

import RedirectModal from "./RedirectModal";
import useGenericToastify from "@app/hooks/useGenericToastify";
import useWindowWidth from "@app/hooks/useWindowResize";
import { useTranslation } from "react-i18next";

const TilesView = ({
  download,
  downloadList,
  halfSize,
  isDownloading,
  items,
  redirectParams,
  urls,
  withRedirectModal,
  favorite,
  handleFavorite
}) => {
  const { t } = useTranslation();
  const [resourceData, setResourceData] = useState(false);
  const { notifyError } = useGenericToastify();
  const mobileBreakpoint = 768;
  const isMobile = useWindowWidth() < mobileBreakpoint;

  const onClicked = (item, e = false) => {
    if (!e || e.key === "Enter") {
      setResourceData({
        catalog: item.catalog,
        link: item.link
      });
    }
  };

  return (
    <ul className="grid">
      {items.map(item => {
        const previewUrl = `${urls.detailViewUrl}${item.slug}?${
          item?.annotation_id !== ""
            ? `annotation_id=${item.annotation_id}`
            : ""
        }${
          item?.page_number ? `&page=${item.page_number}` : ""
        }${redirectParams}`;
        return (
          <li
            key={item.id}
            className={`card ${favorite ? "favorite" : ""} col-xs-${
              halfSize ? "6" : "12"
            } col-sm-6 col-md-3 ${isDownloading ? "card--selectable" : ""}`}
          >
            <a
              href={isDownloading ? null : !item.mbc ? item.link : previewUrl}
              aria-disabled={isDownloading ? "true" : "false"}
              target="_blank"
              tabIndex={-1}
            >
              <picture className="card__cover">
                <img
                  src={item.thumbnail ? item.thumbnail : urls.noImageUrl}
                  alt={t("cms.plugins.search.objectThumbnail")}
                />
              </picture>
            </a>
            <div className="card__content">
              {!item.mbc && <p className="card__date">{item.catalog}</p>}

              {favorite && !isMobile ? (
                <a
                  href={
                    isDownloading ? null : !item.mbc ? item.link : previewUrl
                  }
                  aria-disabled={isDownloading ? "true" : "false"}
                  target="_blank"
                  className="card__title"
                >
                  {item.title}
                </a>
              ) : (
                <div className="flex">
                  <a
                    href={
                      isDownloading ? null : !item.mbc ? item.link : previewUrl
                    }
                    aria-disabled={isDownloading ? "true" : "false"}
                    target="_blank"
                    className="card__title"
                  >
                    {item.title}
                  </a>
                  <div
                    onClick={() =>
                      handleFavorite(item.identifier, item?.is_liked)
                    }
                    role="button"
                  >
                    <i
                      className={`${
                        item.is_liked ? "fas" : "far"
                      } fa-heart plugin-digital-item-rating-display__heart__icon`}
                    />
                  </div>
                </div>
              )}

              <p className="card__description">{item.authors}</p>
            </div>
            {favorite && (
              <div
                onClick={() => handleFavorite(item.identifier, item?.is_liked)}
                role="button"
                className="heart"
              >
                <i
                  className={`${
                    item?.is_liked ? "fas" : "far"
                  } fa-heart plugin-digital-item-rating-display__heart__icon`}
                />
              </div>
            )}
            <label className="card__select">
              <input
                type="checkbox"
                key={`${item.id}-${downloadList.includes(item.id)}`}
                name={`item-${item.id}`}
                value={item.id}
                onClick={e => download(e.target.value)}
                defaultChecked={downloadList.includes(item.id)}
              />
              <span className="checkbox"></span>
              <span className="sr-only">Zaznacz obiekt</span>
            </label>
          </li>
        );
      })}
    </ul>
  );
};

TilesView.defaultProps = {
  halfSize: false,
  isDownloading: false,
  redirectParams: "",
  withRedirectModal: false,
  favorite: false,
  handleFavorite: () => {}
};

export default TilesView;
