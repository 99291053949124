import React, { useEffect, useState } from "react";

import ToggleSection from "@app/plugins/components/assets/ToggleSection/ToggleSection";
import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";
import TextRow from "../../components/assets/Typography/TextRow";

const DublinCore = ({ config }) => {
  const [digitalItemInfo, setDigitalItemInfo] = useState({});
  const [showSection, setShowSection] = useState(config.default_visible);
  const url =
    config.lang === "pl"
      ? `/api/digital_items/${config.id}/dc?plugin_id=${config.plugin_id}`
      : `/api/digital_items/${config.id}/dc?language=${config.lang}&plugin_id=${config.plugin_id}`;

  useEffect(() => {
    if (config.id) {
      axiosSession
        .get(url)
        .then(res => {
          setDigitalItemInfo(res.data);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [config.id, config.lang]);

  return (
    <div className="plugin-digital-item-dublin_core">
      {config.id && (
        <ToggleSection
          title={config.component_name}
          showSection={showSection}
          setShowSection={setShowSection}
        />
      )}
      {showSection &&
        !isEmpty(digitalItemInfo) &&
        digitalItemInfo.map(item => (
          <TextRow
            key={`${item.value.toString()}${item.key}`}
            title={item.key}
            content={item.value}
          />
        ))}
    </div>
  );
};

export default DublinCore;
