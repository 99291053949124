import "./GenericSelect.scss";

import { Controller, ErrorMessage } from "react-hook-form";

import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useEffect } from "react";

const GenericSelect = ({
  alt,
  options,
  required,
  control,
  notifyErrors,
  handleSelectChange,
  name,
  label,
  displayNoneLabel,
  defaultValue,
  isDisabled,
  placeholder,
  errors,
  isMulti,
  isClearable,
  getOptionValue,
  getOptionLabel,
  hideSelectedOptions,
  onBlur,
  maxMenuHeight,
  darkTheme,
  styles,
  isSearchable,
  noOptionsMessage,
  closeMenuOnSelect,
  className,
  compactView,
  filterOption,
  menuPosition,
  classNamePrefix
}) => {
  const whiteTextLabel = darkTheme ? "" : "__black";
  const errorStyle =
    !isEmpty(errors) && has(errors, name) ? "is-danger is-error" : "";

  const { notifyError } = useGenericToastify();

  const getMessage = obj => {
    if (obj && Object.keys(obj).length === 0) {
      return "";
    } else {
      if ("message" in obj) {
        notifyError(obj.message);
      } else {
        if (Array.isArray(obj)) {
          getMessage(obj[0]);
        } else {
          if (Object.keys(obj).length) {
            getMessage(obj[Object.keys(obj)[0]]);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (notifyErrors) {
      getMessage(errors);
    }
  }, [errors]);

  return (
    <div
      className={`field ${
        compactView ? "mbc-select--compact" : "mbc-select"
      } ${className}`}
    >
      {label && (
        <label
          htmlFor={name}
          className={`label mbc-select__label${whiteTextLabel} ${displayNoneLabel &&
            "simple-search__hidden-label"}`}
        >
          {label} {required && <span className="label__required">*</span>}
        </label>
      )}
      <div className="control">
        <Controller
          as={
            <Select
              classNamePrefix={
                classNamePrefix ? classNamePrefix : "react-select"
              }
              className={`${errorStyle} ${
                menuPosition === "fixed" ? `fixed-select-container` : ""
              } mbc-select__label__black`}
              options={options}
              onBlur={onBlur}
              maxMenuHeight={maxMenuHeight}
              styles={styles}
              isSearchable={isSearchable}
              noOptionsMessage={noOptionsMessage}
              closeMenuOnSelect={closeMenuOnSelect}
              filterOption={filterOption}
              menuPosition={menuPosition}
              aria-label={alt ? alt : null}
            />
          }
          control={control}
          placeholder={placeholder}
          rules={required}
          onChange={handleSelectChange}
          name={name}
          defaultValue={defaultValue}
          isDisabled={isDisabled}
          isClearable={isClearable}
          getOptionLabel={
            getOptionLabel ? getOptionLabel : option => `${option.name}`
          }
          getOptionValue={
            getOptionValue ? getOptionValue : option => `${option.id}`
          }
          isMulti={isMulti}
          hideSelectedOptions={hideSelectedOptions}
        />
      </div>
      <ErrorMessage
        as={<p className="help is-danger is-error-text" />}
        errors={errors}
        name={name}
      />
    </div>
  );
};

GenericSelect.propTypes = {
  alt: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.object,
  control: PropTypes.object,
  notifyErrors: PropTypes.bool,
  handleSelectChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  displayNoneLabel: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  isMulti: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  getOptionValue: PropTypes.func,
  maxMenuHeight: PropTypes.number,
  darkTheme: PropTypes.bool,
  isSearchable: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  className: PropTypes.string,
  compactView: PropTypes.bool,
  menuPosition: PropTypes.string,
  classNamePrefix: PropTypes.string
};
GenericSelect.defaultProps = {
  alt: "",
  notifyErrors: false,
  isMulti: false,
  hideSelectedOptions: false,
  defaultValue: null,
  displayNoneLabel: false,
  isSearchable: true,
  noOptionsMessage: () => "Brak opcji",
  closeMenuOnSelect: true,
  className: "",
  compactView: false,
  menuPosition: "absolute",
  classNamePrefix: null
};

export default GenericSelect;
