import "./Checkbox.scss";

import { ErrorMessage } from "react-hook-form";
import PropTypes from "prop-types";
import React from "react";

const Checkbox = ({
  name,
  value,
  register,
  children,
  onChange,
  defaultChecked,
  checked,
  disabled,
  errors,
  darkTheme,
  hasMargin
}) => {
  const themeStyles = `${darkTheme ? "checkbox-dark" : "checkbox-light"}`;
  const disabledStyles = `${themeStyles}${disabled ? "__disabled" : ""}`;
  return (
  <div className={`mbc-checkbox ${themeStyles} ${disabledStyles} ${hasMargin ? "checkbox-has-margin" : ""}`}>
    <input
      type="checkbox"
      name={name}
      value={value}
      ref={register}
      onChange={onChange}
      defaultChecked={defaultChecked}
      disabled={disabled}
      checked={checked}
    />
    <label>{children}</label>
    <ErrorMessage
      as={<p className="help is-danger" />}
      errors={errors}
      name={name}
    />
  </div>
)};

Checkbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  register: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  darkTheme: PropTypes.bool,
  hasMargin: PropTypes.bool
};

Checkbox.defaultProps = {
  errors: {},
  disabled: false,
  darkTheme: true,
  hasMargin: false
};

export default Checkbox;
