import React, { useEffect, useState } from "react";
import ToggleSection from "@app/plugins/components/assets/ToggleSection/ToggleSection";
import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";

const Collections = ({ config }) => {
  const { id, default_visible, collection_url } = config;
  const [collectionsList, setCollectionsList] = useState([]);
  const [showSection, setShowSection] = useState(default_visible);
  const [isFetched, setIsFetched] = useState(false);
  const { t } = useTranslation();

  const object_type =
    config.object_type === "object" ? "digital_items" : "directories";
  const collectionsListUrl = `/api/${object_type}/${id}/collections/published/`;
  useEffect(() => {
    if (id && showSection && !isFetched) {
      axiosSession
        .get(collectionsListUrl)
        .then(({ data: { collections } }) => {
          if (collections.length) {
            setCollectionsList(
              collections.sort((a, b) => a.full_path.localeCompare(b.full_path))
            );
          }
          setIsFetched(true);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [collectionsListUrl, id, showSection]);

  return (
    <>
      {id && (
        <>
          <ToggleSection
            title={t("cms.plugins.collections.title")}
            showSection={showSection}
            setShowSection={setShowSection}
          />
          <div className="plugin-digital-item-collection">
            {showSection &&
              (!isEmpty(collectionsList) ? (
                <>
                  {collectionsList.map(collection => {
                    return (
                      <div key={collection.full_path}>
                        <a
                          className="plugin-digital-item-collection__link"
                          href={`${collection_url}${collection.slug}`}
                          title={collection.full_path}
                        >
                          {collection.full_path}
                        </a>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p className="plugin-digital-item-collection__no-data">
                  {t("cms.plugins.collections.nodata")}
                </p>
              ))}
          </div>
        </>
      )}
    </>
  );
};

export default Collections;
