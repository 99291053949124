import axiosSession from "@app/config/axiosSession";

const handle404 = () => {
  const param_types = [
    { query: "digital_item", url_param: "digital_item" },
    { query: "object_id", url_param: "group" },
    { query: "collection_id", url_param: "collection" }
  ];
  const mainContainer = document.getElementsByClassName("main-container")[0];
  let param = null;
  param_types.some(item => {
    const id = new URLSearchParams(window.location.search).get(item.query);
    if (id && mainContainer) {
      param = item;
      mainContainer.style.display = "none";
      axiosSession
        .get(`/api/digital_items/${id}/check_active/${item.url_param}/`)
        .then(() => {
          mainContainer.style.display = "block";
        })
        .catch(() => {
          document.getElementById("mbc-error-section").style.display = "block";
        });
      return true;
    }
  });
  if (!param && mainContainer) {
    mainContainer.style.display = "block";
  }
};

export default handle404;
