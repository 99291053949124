const CustomStyles = {
  container: provided => ({
    ...provided,
    minHeight: "0.063rem"
  }),
  control: provided => ({
    ...provided,
    minHeight: "0.063rem",
    height: "2.75rem"
  }),
  input: provided => ({
    ...provided,
    minHeight: "0.063rem",
    paddingBottom: "0.25em",
    margin: "0",
    paddingTop: "0"
  }),
  indicatorsContainer: provided => ({
    ...provided,
    minHeight: "0.063rem",
    height: "2.75rem"
  }),
  dropdownIndicator: provided => ({
    ...provided,
    minHeight: "0.063rem",
    paddingTop: "0",
    paddingBottom: "0"
  }),
  indicatorSeparator: provided => ({
    ...provided,
    minHeight: "0.063rem",
    height: "1.8em",
    marginTop: "0.35em"
  }),
  clearIndicator: provided => ({
    ...provided,
    minHeight: "0.063rem"
  }),
  valueContainer: provided => ({
    ...provided,
    minHeight: "0.063rem",
    height: "2em",
    paddingTop: "0",
    paddingBottom: "0"
  }),
  singleValue: provided => ({
    ...provided,
    minHeight: "0.063rem",
    paddingBottom: "0.25em",
    paddingTop: "0.25em"
  })
};

export default CustomStyles;
