import React from "react";
import PropTypes from "prop-types";
import useWindowWidth from "@app/hooks/useWindowResize";
import { useTranslation } from "react-i18next";

const Timeline = ({ item, detailUrl, favorite, handleFavorite }) => {
  const { t } = useTranslation();
  const mobileBreakpoint = 768;
  const isMobile = useWindowWidth() < mobileBreakpoint;

  return (
    <>
      <div className={`card ${favorite ? "favorite" : ""}`}>
        <a
          href={
            !item.mbc
              ? item.link
              : `${detailUrl}${item.slug}?${
                  item?.annotation_id !== ""
                    ? `annotation_id=${item.annotation_id}`
                    : ""
                }${
                  item?.page_number ? `&page=${item.page_number}` : ""
                }&file_type=2`
          }
          aria-label={item?.marc21_values?.title ?? item.title}
          target="_blank"
          tabIndex={-1}
        >
          <picture className="card__cover">
            <img
              src={
                item.thumbnail ? item.thumbnail : "/static/images/no_image.png"
              }
              alt={t("cms.plugins.search.objectThumbnail")}
            />
          </picture>
        </a>
        <div className="card__content">
          {favorite && !isMobile ? (
            <a
              href={
                !item.mbc
                  ? item.link
                  : `${detailUrl}${item.slug}?${
                      item?.page_number ? `page=${item.page_number}` : ""
                    }&file_type=2`
              }
              aria-label={item?.marc21_values?.title ?? item.title}
              target="_blank"
              className="card__title"
            >
              {item?.marc21_values?.title ?? item.title}
            </a>
          ) : (
            <div className="flex">
              <a
                href={
                  !item.mbc
                    ? item.link
                    : `${detailUrl}${item.slug}?${
                        item?.page_number ? `page=${item.page_number}` : ""
                      }&file_type=2`
                }
                aria-label={item?.marc21_values?.title ?? item.title}
                target="_blank"
                className="card__title"
              >
                {item?.marc21_values?.title ?? item.title}
              </a>
              <div
                onClick={() => handleFavorite(item.identifier, item?.is_liked)}
                role="button"
              >
                <i
                  className={`${
                    item?.is_liked ? "fas" : "far"
                  } fa-heart plugin-digital-item-rating-display__heart__icon`}
                />
              </div>
            </div>
          )}
          <p className="card__description">
            {item?.marc21_values?.authors ?? item.authors}
          </p>
        </div>
        {favorite && (
          <div
            onClick={() => handleFavorite(item.identifier, item?.is_liked)}
            role="button"
            className="heart"
          >
            <i
              className={`${
                item?.is_liked ? "fas" : "far"
              } fa-heart plugin-digital-item-rating-display__heart__icon`}
            />
          </div>
        )}
      </div>
      <div className="timeline__date">{item?.release_date ?? "-"}</div>
    </>
  );
};

Timeline.propTypes = {
  item: PropTypes.object.isRequired,
  detailUrl: PropTypes.string.isRequired,
  favorite: PropTypes.bool,
  handleFavorite: PropTypes.func
};

Timeline.defaultProps = {
  favorite: false,
  handleFavorite: () => {}
};

export default Timeline;
