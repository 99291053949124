import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const ArrowButton = ({ children, onClick, className, href }) => {
  return (
    <>
      {href ? (
        <a href={href} className={`arrow-button ${className}`}>
          {children}&nbsp;
          <FontAwesomeIcon
            className="arrow-button__arrow"
            icon={faArrowRight}
          />
        </a>
      ) : (
        <button onClick={onClick} className={`arrow-button ${className}`}>
          {children}&nbsp;
          <FontAwesomeIcon
            className="arrow-button__arrow"
            icon={faArrowRight}
          />
        </button>
      )}
    </>
  );
};

export default ArrowButton;

ArrowButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  href: PropTypes.string
};
ArrowButton.defaultProps = {
  onClick: () => {},
  className: "",
  href: null
};
