import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Rating from "./Rating";
import axiosSession from "@app/config/axiosSession";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";

const RatingDisplay = ({ config }) => {
  const { user_pk, id, withFavorites, withAddRating, withRating } = config;

  const { t } = useTranslation();
  const [rate, setRate] = useState(0);
  const [numberOfVotes, setNumberOfVotes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const { notifyError } = useGenericToastify();
  const [isText, setIsText] = useState(false);
  const [isLoading, setIsLoading] = useState(user_pk ? true : false);

  const ratesUrl = `/api/digital_items/${id}/rates/`;
  const favouriteUrl = "/api/digital_items/user/favorite/";
  const favoriteEditUrl = `/api/digital_items/${id}/user/favorite/`;

  const getUpdatedRate = () => {
    if (id && withRating) {
      axiosSession
        .get(ratesUrl)
        .then(({ data }) => {
          setRate(data.rate);
          setNumberOfVotes(data.number_of_votes);
        })
        .catch(err => console.error(err));
    }
  };

  useEffect(() => {
    getUpdatedRate();
  }, [id, withRating]);

  useEffect(() => {
    if (user_pk && withFavorites) {
      axiosSession
        .get(`${favouriteUrl}ids/`)
        .then(({ data }) => {
          setIsLiked(data.ids.includes(parseInt(id)));
          setIsLoading(false);
        })
        .catch(e => console.error(e));
    }
  }, [id, user_pk, withFavorites]);

  const onClick = e => {
    if (e.key === "Enter" || e.type === "click") {
      if (user_pk) {
        if (!isLoading) {
          setIsLoading(true);
          if (isLiked) {
            axiosSession
              .delete(favoriteEditUrl)
              .then(() => setIsLiked(false))
              .catch(e => console.error(e))
              .finally(() => setIsLoading(false));
          } else {
            axiosSession
              .post(favouriteUrl, { digital_item: id })
              .then(() => setIsLiked(true))
              .catch(e => console.error(e))
              .finally(() => setIsLoading(false));
          }
        }
      } else {
        notifyError(t("cms.plugins.results.notLogged"));
      }
    }
  };

  const CustomIcon = ({ liked }) => {
    return (
      <div
        tabIndex="0"
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        title={
          isLiked
            ? t("cms.plugins.results.removeFromFavorites")
            : t("cms.plugins.results.addToFavorites")
        }
      >
        {isLoading ? (
          <div className="loader" />
        ) : (
          <>
            {liked ? (
              <i
                className={`fas fa-heart plugin-digital-item-rating-display__heart__icon `}
              ></i>
            ) : (
              <i
                className={`far fa-heart plugin-digital-item-rating-display__heart__icon ${
                  user_pk ? "" : "--inactive"
                }`}
              ></i>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="plugin-digital-item-rating-display">
      {id && (
        <>
          {withFavorites && (
            <div className="plugin-digital-item-rating-display--has-padding">
              <CustomIcon liked={isLiked} />
            </div>
          )}
          {withAddRating && (
            <div
              className={`plugin-digital-item-rating-display--has-padding ${isText &&
                "rate-stars"}`}
            >
              <Rating
                id={id}
                asElement
                setIsText={setIsText}
                getUpdatedRate={getUpdatedRate}
              />
            </div>
          )}
          {withRating && (
            <>
              <div className="plugin-digital-item-rating-display--has-padding">
                <span className="plugin-digital-item-rating-display__star">
                  <FontAwesomeIcon icon={faStar} />
                </span>
                &nbsp;
                <span className="plugin-digital-item-rating-display__rating">
                  {rate}
                </span>
              </div>
              <div>
                <span>
                  {numberOfVotes}&nbsp;{t("cms.plugins.ratingDisplay.rates")}
                </span>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default RatingDisplay;
