import {
  ArrowLeftOutlined,
  SearchOutlined,
  SettingOutlined
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import AutosuggestInput from "./AutosuggestInput";
import PropTypes from "prop-types";
import ResizableSelect from "../assets/Select/ResizableSelect";
import SearchModal from "./AdvancedSearch/CMSSearchModal";
import SpeechRecognition from "./SpeechRecognition";
import axiosConfig from "@app/config/axiosSession";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";

const Search = ({ config }) => {
  const {
    results_url,
    display_label,
    query,
    type,
    display_placeholder,
    has_speech,
    advanced,
    recording_limit,
    plugin_attributes,
    wolne_lektury
  } = config;
  const { t } = useTranslation();
  const options = [
    { label: t("cms.plugins.search.options.fields"), value: "fields" },
    { label: t("cms.plugins.search.options.text"), value: "text" },
    { label: t("cms.plugins.search.options.all"), value: "all" }
  ];

  const { notifyError } = useGenericToastify();
  const [inputValue, setInputValue] = useState(query);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [searchType, setSearchType] = useState(
    type
      ? {
          label: t(`cms.plugins.search.options.${type ? type : "fields"}`),
          value: type
        }
      : options[0]
  );
  const {
    errors,
    register,
    getValues,
    setValue,
    setError,
    handleSubmit,
    triggerValidation
  } = useForm({
    defaultValues: {
      search: ""
    }
  });
  const autosuggestUrl = "/api/search/frontoffice/autosuggest/";
  const simpleSearchUrl = "/api/search/frontoffice/simple-search/";

  const onSearch = search => {
    axiosConfig
      .post(simpleSearchUrl, {
        query: search.query,
        search_type: search.type,
        full_url: config.results_url,
        filters: [],
        language: getLanguage().toUpperCase()
      })
      .then(({ data }) => {
        window.location = `${results_url}?id=${data}&query=${search.query}&type=${search.type}`;
      })
      .catch(() => {
        notifyError(t("cms.plugins.advancedSearch.errors.searchFailed"));
      });
  };

  const getLanguage = () => {
    return (
      t.language ||
      (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
      "PL"
    );
  };

  const onFilter = async e => {
    if (e.key === "Enter") {
      const data = getValues();
      let search = {};
      search.type = searchType.value;
      search.query = data.search;
      return triggerValidation(search);
    }
  };

  const onSubmit = () => {
    if (inputValue.length === 0) {
      setError("search", "required");
    } else {
      const search = {};
      search.type = searchType.value;
      search.query = inputValue;

      onSearch(search);
    }
  };

  useEffect(() => {
    register("search", {
      minLength: 3
    });
  }, []);

  useEffect(() => {
    const urlQuery = new URLSearchParams(window.location.search).get("query");
    const urlType = new URLSearchParams(window.location.search).get("type");
    if (!query && urlQuery) {
      setInputValue(urlQuery);
    }
    if (!type && urlType) {
      setSearchType(options.find(x => x.value === urlType));
    }
  }, []);

  const handleTypeChange = selectedOption => {
    setSearchType(selectedOption);
    return selectedOption;
  };

  const errorMsg = error => {
    return (
      <p className="input-error">{t(`cms.plugins.search.errors.${error}`)}</p>
    );
  };

  useEffect(() => {
    if (isExpanded) {
      const blur = document.createElement("div");
      blur.id = "blur";
      blur.classList.add("blurred");
      blur.addEventListener("click", () => setIsExpanded(false));
      document.body.append(blur);
    } else if (document.getElementById("blur")) {
      document.getElementById("blur").remove();
    }
  }, [isExpanded]);

  return (
    <div
      className={`simple-search ${isExpanded ? "simple-search--expanded" : ""}`}
    >
      <SearchOutlined
        className={`simple-search__mobile-icon ${isExpanded ? "hidden" : ""}`}
        onClick={() => setIsExpanded(true)}
      />
      {advanced && (
        <SearchModal
          modalIsOpen={showAdvanced}
          setModalIsOpen={setShowAdvanced}
          showCloseIcon={true}
          url={results_url}
          pluginAttributes={plugin_attributes}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <div className="simple-search__input-container">
            {isExpanded && (
              <div className="simple-search__go-back">
                <ArrowLeftOutlined onClick={() => setIsExpanded(false)} />
              </div>
            )}
            <label
              className="simple-search__hidden-label"
              htmlFor="main-search"
            >
              {t("cms.plugins.search.alt")}
            </label>
            <AutosuggestInput
              url={autosuggestUrl}
              renderSuggestion={suggestion => <span>{`${suggestion}`}</span>}
              getSuggestionValue={suggestion => suggestion}
              setInputValue={setInputValue}
              setValue={setValue}
              onChange={(_, { newValue }) => {
                if (newValue.length < 1500) {
                  setValue("search", newValue);
                  setInputValue(newValue);
                }
              }}
              value={inputValue}
              inputProps={{
                id: "main-search",
                className: "input",
                alt: t("cms.plugins.search.alt"),
                type: "text",
                name: "search",
                title: t("cms.plugins.search.title"),
                placeholder: display_placeholder
                  ? t("cms.plugins.search.placeholder")
                  : "",
                onKeyPress: onFilter
              }}
              suggestions={suggestions}
              setSuggestions={setSuggestions}
            />

            <div className="icon-container search">
              <button
                type="submit"
                className="btn btn--text-black"
                aria-label={t("cms.plugins.search.button")}
              >
                <SearchOutlined />
              </button>
            </div>

            {has_speech && config.logged && (
              <SpeechRecognition
                setInputValue={setInputValue}
                recording_limit={recording_limit}
              />
            )}

            {errors.search?.type === "required" && errorMsg("required")}
            {errors.search?.type === "minLength" && errorMsg("minLength")}
          </div>

          <ResizableSelect
            options={options}
            onChange={handleTypeChange}
            isSearchable={false}
            value={searchType}
            menuPosition="fixed"
            classNamePrefix="cms-select"
            className="simple-search__select"
            aria-label={`${t("cms.plugins.search.label")}, ${
              searchType.label
            }, pole złożone z ${options.length} elementów`}
          />
        </>

        <button
          type="submit"
          className="btn hidden-xs"
          aria-label={t("cms.plugins.search.button")}
        >
          {t("cms.plugins.search.button")}
        </button>
        {isExpanded && (
          <>
            <span className="simple-search__suggest-list__title">
              {suggestions.length} {t("cms.plugins.search.results")}
            </span>
            <div className="simple-search__suggest-list">
              <ul>
                {suggestions.map(item => (
                  <li>{item}</li>
                ))}
              </ul>
            </div>
          </>
        )}
        {advanced && (
          <button
            className="btn btn--text-white simple-search__advanced"
            type="button"
            onClick={e => {
              e.preventDefault();
              setShowAdvanced(true);
            }}
            title={t("cms.plugins.search.advancedSearch")}
          >
            {isExpanded ? `${t("cms.plugins.search.advancedSearch")} ` : ""}
            <SettingOutlined aria-hidden={true} />
          </button>
        )}
      </form>
    </div>
  );
};

Search.propTypes = {
  results_url: PropTypes.string,
  display_label: PropTypes.bool,
  display_placeholder: PropTypes.bool,
  has_speech: PropTypes.bool
};

Search.defaultProps = {
  url: "/",
  display_label: false,
  display_placeholder: true,
  has_speech: false,
  errors: {}
};

export default Search;
