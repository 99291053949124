import React, { useEffect, useRef, useState } from "react";
import CMSCheckbox from "@components/Checkbox/CMSCheckbox";
import FieldArray from "./FieldArray";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericDatePicker from "@components/GenericDatePicker/CMSDatePicker";
import Modal from "../../assets/Modal/Modal";
import PropTypes from "prop-types";
import ResizableGenericSelect from "@app/plugins/components/assets/Select/ResizableGenericSelect";

import axiosSession from "@app/config/axiosSession";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";

const SearchModal = ({
  modalIsOpen,
  setModalIsOpen,
  url,
  pluginAttributes
}) => {
  const {
    handleSubmit,
    register,
    errors,
    control,
    setError,
    reset,
    setValue,
    getValues
  } = useForm();
  const { t, i18n } = useTranslation();
  const { notifyError } = useGenericToastify();
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState([{ id: 1 }]);
  const [dateType, setDateType] = useState(null);
  const [historicOptions, setHistoricOptions] = useState([]);
  const [attributesForSelect, setAttributesForSelect] = useState([]);
  const [isSynonyms, setIsSynonyms] = useState(false);
  const [autoSuggest, setAutoSuggest] = useState({ id: null, data: [] });
  const [isAutoSuggestVisible, setIsAutoSuggestVisible] = useState(false);
  const [focusedSuggest, setFocusedSuggest] = useState(null);
  const [activeInput, setActiveInput] = useState(null);

  const autoSuggestElements = useRef([]);

  const maxQueries = 10;
  const saveQueryUrl = "/api/search/frontoffice/advanced-search/";

  const attributesUrl = "/api/attributes/list/";
  const epochsUrl = "/api/search/frontoffice/historical-epochs/";
  const autoSuggestQuery = "/api/search/frontoffice/advanced-autosuggest/";

  const [dates, setDates] = useState([
    { id: 0, name: t("cms.plugins.advancedSearch.dates.custom") },
    {
      id: 1,
      name: t("cms.plugins.advancedSearch.dates.lastMonth"),
      value: "now-1M/d"
    },
    {
      id: 2,
      name: t("cms.plugins.advancedSearch.dates.lastTwoYears"),
      value: "now-2y/d"
    }
  ]);

  const closeModal = () => {
    clear();
    setModalIsOpen(false);
    resetAutoSuggest();
  };

  const getLanguage = () => {
    return (
      i18n.language ||
      (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
      "PL"
    );
  };

  const onSubmit = data => {
    setIsLoading(true);
    const dateRegex = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
    const yearRegex = /^\d{4}$/;
    const numberRegex = /^\d+$/;
    let isError = false;
    data.query.forEach((query, idx) => {
      if (idx === 0) {
        query.operator = "";
      }
      if (
        query.attribute.attribute_type === "date" &&
        query.operation.id === 8
      ) {
        query.value = query.value.id;
      } else if (
        query.attribute.attribute_type === "date" &&
        !dateRegex.test(query.value) &&
        query.operation.id !== 4 &&
        query.operation.id !== 5
      ) {
        if (yearRegex.test(query.value)) {
          query.value = query.value.concat("-01-01");
        } else {
          setError(
            `query[${idx}].value`,
            "",
            t("cms.plugins.advancedSearch.errors.dateFormat")
          );
          isError = true;
        }
      } else if (
        query.attribute.attribute_type === "numeric" &&
        query.operation.id !== 4 &&
        query.operation.id !== 5
      ) {
        if (!numberRegex.test(query.value)) {
          setError(
            `query[${idx}].value`,
            "",
            t("cms.plugins.advancedSearch.errors.onlyDigits")
          );
          isError = true;
        }
      }

      if (query.operator) {
        query.operator = query.operator.key;
      }
      if (query.operation.id !== 4 && query.operation.id !== 5) {
        if (query.value === "" || query.value === undefined) {
          setError(
            `query[${idx}].value`,
            "",
            t("cms.plugins.advancedSearch.errors.noEmpty")
          );
          isError = true;
        }
      } else {
        query.value = "";
      }
      query.operation = query.operation.key;
      query.attribute = query.attribute.id;
    });
    data.settings.date_historical = null;
    if (data.settings.date && !historicOptions.includes(data.settings.date)) {
      if (data.settings.date.id == 0) {
        data.settings.date = data.settings.date.value;
        data.settings.date_from = data.settings.date_from[0]
          .toISOString()
          .substring(0, 10);
        data.settings.date_to = data.settings.date_to[0]
          .toISOString()
          .substring(0, 10);
      } else {
        data.settings.date_to = new Date().toISOString().substring(0, 10);
        data.settings.date_from = data.settings.date.value;
      }
    } else {
      if (data.settings.date) {
        data.settings.date_historical = data.settings.date.id;
      }
      data.settings.date_from = "";
      data.settings.date_to = "";
    }
    delete data.settings.date;

    data.language = getLanguage().toUpperCase();
    data.full_url = url;

    if (!data.settings.synonyms) {
      data.settings.synonyms = false;
    }

    if (!isError) {
      axiosSession
        .post(saveQueryUrl, data)
        .then(({ data }) => {
          window.location = `${url}?id=${data}`;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      notifyError(t("cms.plugins.advancedSearch.errors.searchFailed"));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const [epochs] = await axiosSession
        .all([
          axiosSession.get(epochsUrl, {
            params: { language: getLanguage().toUpperCase() }
          }),
          ...[axiosSession.get(attributesUrl)]
        ])
        .catch(error => {
          console.error(error);
        });
      setAttributesForSelect(pluginAttributes);
      setDates(dates.concat(epochs.data.results));
      setHistoricOptions(epochs.data.results);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (document.activeElement === activeInput) {
      const len = activeInput?.value.length;
      activeInput?.setSelectionRange(len, len);
    }
  }, [activeInput, focusedSuggest]);

  const clear = () => {
    setQuery([{ id: Math.random() }]);
    setDateType(null);
    setValue("query", []);
    setValue("settings.date", null);
    setValue("settings", {});
    reset();
  };

  const removeRow = idx => {
    let tempQuery = [...query];
    tempQuery.splice(idx, 1);
    setQuery(tempQuery);
  };

  const handleChecked = (name, isChecked) => {
    switch (name) {
      case "settings.synonyms":
        if (isChecked) {
          setValue("settings.case_sensitive", false);
          setIsSynonyms(true);
          query.forEach((item, index) => {
            if (
              getValues(`query[${index}].operation`)?.key === "equal" ||
              getValues(`query[${index}].operation`)?.key === "not_equal"
            ) {
              setValue(`query[${index}].operation`, null);
            }
          });
        } else {
          setIsSynonyms(false);
        }
        break;
      case "settings.case_sensitive":
        if (isChecked) {
          setValue("settings.synonyms", false);
          setIsSynonyms(false);
        }
    }
  };

  const resetAutoSuggest = () => {
    setAutoSuggest({ id: null, data: [] });
    setIsAutoSuggestVisible(false);
    setFocusedSuggest(null);
  };

  const changeInput = ([data]) => {
    if (data.target.value === "") {
      resetAutoSuggest();
      return data;
    }
    const dataIndex = parseInt(data.target.name.match(/\d+/g)[0]);
    const attribute = getValues(`query[${dataIndex}].attribute`)?.name;
    if (attribute) {
      axiosSession
        .get(autoSuggestQuery, {
          params: { query: data.target.value, attribute: attribute }
        })
        .then(({ data }) => {
          setAutoSuggest({ id: dataIndex, data });
          if (data.length > 0) {
            setIsAutoSuggestVisible(true);
          } else {
            setIsAutoSuggestVisible(false);
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
    return data;
  };

  const selectSuggest = (item, idx) => {
    setValue(`query[${idx}].value`, item);
    resetAutoSuggest();
  };

  const autoSuggestKeyboardHandle = async e => {
    const attributes = autoSuggestElements.current.filter(
      item => item !== null
    );

    if (e.target.type === "text") {
      setActiveInput(e.target);
      setFocusedSuggest(null);
    }
    switch (e.keyCode) {
      case 40:
        if (autoSuggest.data.length === 0) {
          return;
        }
        if (focusedSuggest === null) {
          attributes[0].focus();
          setFocusedSuggest(0);
          return;
        }
        if (focusedSuggest === autoSuggest.data.length - 1) {
          return;
        }
        attributes[focusedSuggest + 1].focus();
        setFocusedSuggest(origin => origin + 1);
        break;

      case 38:
        if (focusedSuggest === null) {
          return;
        }
        if (focusedSuggest === 0) {
          activeInput?.focus();
          setFocusedSuggest(null);
          return;
        }
        attributes[focusedSuggest - 1]?.focus();
        setFocusedSuggest(origin => origin - 1);
        break;

      default:
        return;
    }
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      if (
        !(
          autoSuggestElements.current.includes(document.activeElement) ||
          document.activeElement === activeInput
        )
      ) {
        resetAutoSuggest();
      }
    }, 0);
  };

  const handleInputFocus = () => {
    setFocusedSuggest(null);
  };

  return (
    <Modal
      open={modalIsOpen}
      onClose={() => {
        closeModal();
      }}
      className="advanced-search-body"
      title={t("cms.plugins.advancedSearch.advancedSearch")}
      content={
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          className="advanced-search"
          id="search-form"
        >
          <div className="advanced-search__content grid">
            {query.map((value, idx) => {
              return (
                <FieldArray
                  id={idx}
                  control={control}
                  errors={errors}
                  notifyErrors
                  attributes={attributesForSelect}
                  historicOptions={historicOptions}
                  key={value.id}
                  reset={reset}
                  setValue={setValue}
                  labelDrawer={true}
                  displayNoneLabel={true}
                  isSynonyms={isSynonyms}
                  resetAfterChange={false}
                  onChange={changeInput}
                  handleInputBlur={handleInputBlur}
                  handleInputFocus={handleInputFocus}
                  autoSuggestKeyboardHandle={autoSuggestKeyboardHandle}
                  isFrontoffice
                  autoSuggest={
                    autoSuggest.id === idx && isAutoSuggestVisible ? (
                      <ul
                        className="advanced-search__body__auto-suggest"
                        onBlur={handleInputBlur}
                      >
                        {autoSuggest.data.map((item, id) => (
                          <li
                            key={item}
                            className={`advanced-search__body__auto-suggest-element`}
                          >
                            <button
                              ref={el => (autoSuggestElements.current[id] = el)}
                              className="advanced-search__body__auto-suggest-element-button"
                              onClick={e => {
                                e.preventDefault();
                                selectSuggest(item, idx);
                              }}
                              onKeyDown={e => autoSuggestKeyboardHandle(e)}
                            >
                              {item}
                            </button>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      undefined
                    )
                  }
                  addRowComponent={
                    <>
                      {idx === 0 ? (
                        <>
                          {query.length < maxQueries && (
                            <span
                              role="button"
                              aria-label={t(
                                "cms.plugins.advancedSearch.altAddCriteria"
                              )}
                              tabIndex="0"
                              className="btn btn--text"
                              onClick={() =>
                                setQuery(query.concat([{ id: Math.random() }]))
                              }
                              onKeyPress={() =>
                                setQuery(query.concat([{ id: Math.random() }]))
                              }
                            >
                              {t("cms.plugins.advancedSearch.addNext")}
                              <span className="advanced-search__content__plus">
                                &nbsp;+
                              </span>
                            </span>
                          )}
                        </>
                      ) : (
                        <button
                          className="btn btn--text"
                          aria-label={t(
                            "cms.plugins.advancedSearch.altRemoveCriteria"
                          )}
                          onClick={() => removeRow(idx)}
                        >
                          <FontAwesomeIcon size="lg" icon={faTrash} />
                        </button>
                      )}
                    </>
                  }
                />
              );
            })}
          </div>
          <div className="advanced-search__settings">
            <CMSCheckbox
              name="settings.synonyms"
              darkTheme={false}
              register={register}
              onChange={e => handleChecked(e.target.name, e.target.checked)}
            >
              {t("cms.plugins.advancedSearch.useSynonyms")}
            </CMSCheckbox>
            <CMSCheckbox
              name="settings.case_sensitive"
              register={register}
              darkTheme={false}
              onChange={e => handleChecked(e.target.name, e.target.checked)}
            >
              {t("cms.plugins.advancedSearch.useCaseSensitive")}
            </CMSCheckbox>

            <ResizableGenericSelect
              isClearable
              name="settings.date"
              label={t("cms.plugins.advancedSearch.publicationDate")}
              alt={`${t("cms.plugins.advancedSearch.publicationDate")}, ${
                dateType !== null ? dateType.name : ""
              }, ${t("app.field_start")} ${dates.length} ${t("app.field_end")}`}
              placeholder={t(
                "cms.plugins.advancedSearch.publicationDatePlaceholder"
              )}
              handleSelectChange={value => {
                setDateType(value[0]);
                return value[0];
              }}
              options={dates}
              control={control}
              errors={errors}
              className="cms-select"
              classNamePrefix="cms-select"
              menuPosition="fixed"
            />

            {dateType && dateType.id === 0 && (
              <div className="grid">
                <div className="col-xs-12 col-sm-6 col-md-3 form-group">
                  <GenericDatePicker
                    label={t("cms.plugins.advancedSearch.startDate")}
                    control={control}
                    errors={errors}
                    name={"settings.date_from"}
                    dateFormat={"dd.MM.yyyy"}
                    defaultValue={[new Date()]}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3  form-group">
                  <GenericDatePicker
                    label={t("cms.plugins.advancedSearch.endDate")}
                    control={control}
                    errors={errors}
                    name={"settings.date_to"}
                    dateFormat={"dd.MM.yyyy"}
                    defaultValue={[new Date()]}
                  />
                </div>
              </div>
            )}
          </div>
        </form>
      }
      buttons={
        <div className="advanced-search__buttons">
          <span
            className="btn btn--text"
            role="button"
            tabIndex="0"
            onClick={clear}
            onKeyPress={clear}
          >
            {t("cms.plugins.advancedSearch.clear")} &nbsp;
            <i className="fas fa-redo-alt advanced-search__buttons__redo-icon"></i>
          </span>
          <button
            form="search-form"
            type="submit"
            className={`btn ${isLoading ? " is-loading" : ""}`}
          >
            {t("cms.plugins.advancedSearch.search")}
          </button>
        </div>
      }
    />
  );
};

SearchModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  setModalIsOpen: PropTypes.func.isRequired,
  url: PropTypes.string
};

export default SearchModal;
