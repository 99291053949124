import React from "react";
import TiledView from "@components/TiledView/TiledView";
import { useTranslation } from "react-i18next";

const RecentlyViewed = ({ config }) => {
  const { t } = useTranslation();
  const latestUrl = "/api/digital_items/visited-list/";
  const noItemMessage = t("cms.plugins.results.no_recently_viewed");
  return <TiledView config={config} url={latestUrl} noItemMessage={noItemMessage}/>;
};

export default RecentlyViewed;
