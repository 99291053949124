import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const Sort = ({ disabled, excludeFilters, setPage, setSort, sort }) => {
  const { t, i18n } = useTranslation();

  const getLanguage = () => {
    return (
      i18n.language ||
      (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
      "PL"
    );
  };

  const handleSortChange = selectedOption => {
    if (selectedOption === sort) return selectedOption;
    setPage(1);
    setSort(selectedOption);
  };

  const sortOptions = [
    {
      label: t("cms.plugins.results.sort.options.score"),
      value: "score"
    },
    {
      label: t("cms.plugins.results.sort.options.newest"),
      value: "-created"
    },
    {
      label: t("cms.plugins.results.sort.options.oldest"),
      value: "created"
    },
    {
      label: t("cms.plugins.results.sort.options.popularity"),
      value: "-popularity_interval_0"
    },
    {
      label: t("cms.plugins.results.sort.options.alphabetically-AZ"),
      value: getLanguage().toUpperCase() + ".title_lowercase"
    },
    {
      label: t("cms.plugins.results.sort.options.alphabetically-ZA"),
      value: "-" + getLanguage().toUpperCase() + ".title_lowercase"
    }
  ].filter(x => !excludeFilters.includes(x.value));
  return (
    <div className="btn-group__select">
      <label>{t("cms.plugins.results.sort.label")}:</label>
      <Select
        options={sortOptions}
        defaultValue={sortOptions[0]}
        className="cms-select"
        isSearchable={false}
        classNamePrefix="cms-select"
        onChange={option => {
          if (option.value) handleSortChange(option.value);
          return option;
        }}
        isDisabled={disabled}
        aria-label={`Sortuj wg. ${
          sortOptions.filter(elem => elem.value === sort)[0].label
        }, ${t("app.field_start")} ${sortOptions.length} ${t("app.field_end")}`}
      />
    </div>
  );
};

Sort.defaultProps = {
  excludeFilters: []
};

export default Sort;
