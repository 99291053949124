import "../i18n";
import "@app/helpers/logout";
import "@app/helpers/sentry";

import AppCms from "@app/containers/AppCms";
import CollectionList from "./components/collectionList/CollectionList";
import CollectionsPlugin from "./components/collections/Collections";
import OCR from "./components/ocr/OCR";
import PluginCollections from "./components/digital_item_preview/Collections";
import PluginComments from "./components/comments/Comments";
import PluginContact from "./components/contact/Contact";
import PluginCookie from "./components/cookies/Cookies";
import PluginDigitalItemCorrection from "./components/digital_item_correction/Correction";
import PluginDigitalItemDetails from "./components/digital_item_preview/Details";
import PluginDigitalItemDownloadFiles from "./components/downloadFile/downloadFile";
import PluginDigitalItemDownloadMeta from "./components/downloadMeta/DownloadMeta";
import PluginDigitalItemDublinCore from "./components/digital_item_preview/DublinCore";
import PluginDigitalItemHeader from "./components/digital_item_preview/Header";
import PluginDigitalItemMarc21 from "./components/digital_item_preview/MARC21";
import PluginDigitalItemRating from "./components/digital_item_rating/Rating";
import PluginDigitalItemRatingDisplay from "./components/digital_item_rating/RatingDisplay";
import PluginDigitalItemStructure from "./components/digital_item_structure/Structure";
import PluginGroupObjectItemsList from "./components/group_object_items_list/GroupObjectItemsList";
import PluginGroupObjectPreview from "./components/group_object_preview/GroupObjectPreview";
import PluginLatest from "./components/latest/Latest";
import PluginMyProfile from "./components/profile/MyProfile";
import PluginNukatViaf from "./components/nukat_viaf/NukatViaf";
import PluginPermalink from "./components/permalink/Permalink";
import PluginPopular from "./components/popular/Popular";
import PluginProfileBookmarks from "./components/bookmarks/Bookmarks";
import PluginProfileComments from "./components/profile/ProfileComments";
import PluginProfileFavorites from "./components/favorites/Favorites";
import PluginProfileHighlightings from "./components/highlightings/Highlightings";
import PluginProfileMeta from "./components/profile/ProfileMeta";
import PluginProfileNewsletters from "./components/newsletters/Newsletters";
import PluginProfileNotes from "./components/notes/Notes";
import PluginProfileSearchHistory from "./components/profile/SearchHistory";
import PluginRecentlyViewed from "./components/recentlyViewed/RecentlyViewed";
import PluginRecommended from "./components/recommended/Recommended";
import PluginRecommendedCollections from "./components/recommendedCollections/RecommendedCollections";
import PluginResults from "./components/results/Results";
import PluginSearch from "./components/search/Search";
import PluginSimilar from "./components/similar/Similar";
import PluginSkiplinks from "./components/skiplinks/Skiplinks";
import PluginThumbnail from "./components/digital_item_preview/Thumbnail";
import PluginUserMenu from "./components/user_menu/PluginUserMenu";
import React from "react";
import ReactDOM from "react-dom";
import ScrollToTop from "@components/ScrollToTop/ScrollToTop";

const mbcApp = document.getElementById("mbc_app");

if (document.body.contains(mbcApp)) {
  ReactDOM.render(
    <AppCms
      component={component}
      messages={messages}
      previous_url={previous_url}
      captcha={registerCaptchaKey}
      confirmation={confirmation}
      unaccepted_terms={unaccepted_terms}
      unaccepted_agreements={unaccepted_agreements}
      uid={uid}
      username={username}
      token={token}
    />,
    mbcApp
  );
}

if (!(typeof plugin_rct === "undefined" || plugin_rct === null)) {
  ReactDOM.render(
    <PluginContact
      selectOptions={plugin_rct_data || []}
      contactId={plugin_rct_id}
      siteKey={site_key}
      userIsLogged={userIsLogged}
    />,
    document.getElementById(plugin_rct)
  );
}

if (!(typeof plugin_digital_item_preview_download_meta === "undefined")) {
  ReactDOM.render(
    <PluginDigitalItemDownloadMeta
      config={plugin_digital_item_preview_download_meta_config}
    />,
    document.getElementById(plugin_digital_item_preview_download_meta)
  );
}

if (!(typeof plugin_cookies === "undefined")) {
  ReactDOM.render(
    <PluginCookie config={plugin_cookies_config} />,
    document.getElementById(plugin_cookies)
  );
}

if (!(typeof plugin_nukat_viaf === "undefined")) {
  ReactDOM.render(
    <PluginNukatViaf config={plugin_nukat_viaf_config} />,
    document.getElementById(plugin_nukat_viaf)
  );
}

if (!(typeof plugin_digital_item_preview_download_files === "undefined")) {
  ReactDOM.render(
    <PluginDigitalItemDownloadFiles
      config={plugin_digital_item_preview_download_files_config}
    />,
    document.getElementById(plugin_digital_item_preview_download_files)
  );
}

if (!(typeof plugin_profile_favorites === "undefined")) {
  ReactDOM.render(
    <PluginProfileFavorites config={plugin_profile_favorites_config} />,
    document.getElementById(plugin_profile_favorites)
  );
}

if (!(typeof plugin_digital_item_preview_comments === "undefined")) {
  ReactDOM.render(
    <PluginComments config={plugin_digital_item_preview_comments_config} />,
    document.getElementById(plugin_digital_item_preview_comments)
  );
}

if (!(typeof plugin_search_simple === "undefined")) {
  ReactDOM.render(
    <PluginSearch config={plugin_search_simple_config} />,
    document.getElementById(plugin_search_simple)
  );
}

if (!(typeof plugin_results === "undefined")) {
  ReactDOM.render(
    <PluginResults config={plugin_results_config} />,
    document.getElementById(plugin_results)
  );
}

if (!(typeof plugin_digital_item_header === "undefined")) {
  ReactDOM.render(
    <PluginDigitalItemHeader
      config={plugin_digital_item_preview_header_config}
    />,
    document.getElementById(plugin_digital_item_header)
  );
}

if (!(typeof plugin_thumbnail_simple === "undefined")) {
  ReactDOM.render(
    <PluginThumbnail config={thumbnail_config} />,
    document.getElementById(plugin_thumbnail_simple)
  );
}

if (!(typeof plugin_group_object_preview === "undefined")) {
  ReactDOM.render(
    <PluginGroupObjectPreview config={plugin_group_object_preview_config} />,
    document.getElementById(plugin_group_object_preview)
  );
}

if (!(typeof plugin_group_object_items_list === "undefined")) {
  ReactDOM.render(
    <PluginGroupObjectItemsList
      config={plugin_group_object_items_list_config}
    />,
    document.getElementById(plugin_group_object_items_list)
  );
}

if (!(typeof plugin_digital_item_details === "undefined")) {
  ReactDOM.render(
    <PluginDigitalItemDetails config={plugin_digital_item_details_config} />,
    document.getElementById(plugin_digital_item_details)
  );
}

if (!(typeof plugin_digital_item_marc21 === "undefined")) {
  ReactDOM.render(
    <PluginDigitalItemMarc21 config={plugin_digital_item_marc21_config} />,
    document.getElementById(plugin_digital_item_marc21)
  );
}

if (!(typeof plugin_digital_item_item_dublin_core === "undefined")) {
  ReactDOM.render(
    <PluginDigitalItemDublinCore
      config={plugin_digital_item_dublin_core_config}
    />,
    document.getElementById(plugin_digital_item_item_dublin_core)
  );
}

if (!(typeof plugin_digital_item_preview_rating_display === "undefined")) {
  ReactDOM.render(
    <PluginDigitalItemRatingDisplay
      config={plugin_digital_item_preview_rating_display_config}
    />,
    document.getElementById(plugin_digital_item_preview_rating_display)
  );
}

if (!(typeof plugin_digital_item_preview_rating === "undefined")) {
  ReactDOM.render(
    <PluginDigitalItemRating id={plugin_digital_item_preview_rating_id} />,
    document.getElementById(plugin_digital_item_preview_rating)
  );
}

if (!(typeof plugin_digital_item_preview_structure === "undefined")) {
  ReactDOM.render(
    <PluginDigitalItemStructure
      config={digital_item_preview_structure_config}
    />,
    document.getElementById(plugin_digital_item_preview_structure)
  );
}

if (!(typeof plugin_digital_item_preview_correction === "undefined")) {
  ReactDOM.render(
    <PluginDigitalItemCorrection
      config={plugin_digital_item_preview_correction_config}
    />,
    document.getElementById(plugin_digital_item_preview_correction)
  );
}

if (!(typeof plugin_profile_search_history === "undefined")) {
  ReactDOM.render(
    <PluginProfileSearchHistory config={plugin_history_config} />,
    document.getElementById(plugin_profile_search_history)
  );
}

if (!(typeof plugin_profile_notes === "undefined")) {
  ReactDOM.render(
    <PluginProfileNotes />,
    document.getElementById(plugin_profile_notes)
  );
}

if (!(typeof plugin_profile_bookmarks === "undefined")) {
  ReactDOM.render(
    <PluginProfileBookmarks />,
    document.getElementById(plugin_profile_bookmarks)
  );
}

if (!(typeof plugin_profile_meta === "undefined")) {
  ReactDOM.render(
    <PluginProfileMeta />,
    document.getElementById(plugin_profile_meta)
  );
}

if (!(typeof plugin_profile_comments === "undefined")) {
  ReactDOM.render(
    <PluginProfileComments config={plugin_profile_comments_config} />,
    document.getElementById(plugin_profile_comments)
  );
}

if (!(typeof plugin_profile_newsletters === "undefined")) {
  ReactDOM.render(
    <PluginProfileNewsletters />,
    document.getElementById(plugin_profile_newsletters)
  );
}

if (!(typeof plugin_profile_highlightings === "undefined")) {
  ReactDOM.render(
    <PluginProfileHighlightings />,
    document.getElementById(plugin_profile_highlightings)
  );
}

if (!(typeof plugin_user_menu === "undefined")) {
  ReactDOM.render(
    <PluginUserMenu config={plugin_user_menu_config} />,
    document.getElementById(plugin_user_menu)
  );
}

if (!(typeof digital_item_preview_collections === "undefined")) {
  ReactDOM.render(
    <PluginCollections config={digital_item_preview_collections_config} />,
    document.getElementById(digital_item_preview_collections)
  );
}

if (!(typeof plugin_profile_ocr === "undefined")) {
  ReactDOM.render(<OCR />, document.getElementById(plugin_profile_ocr));
}

if (!(typeof plugin_popular === "undefined")) {
  ReactDOM.render(
    <PluginPopular config={plugin_popular_config} />,
    document.getElementById(plugin_popular)
  );
}

if (!(typeof plugin_latest === "undefined")) {
  ReactDOM.render(
    <PluginLatest config={plugin_latest_config} />,
    document.getElementById(plugin_latest)
  );
}

if (!(typeof plugin_recently_viewed === "undefined")) {
  ReactDOM.render(
    <PluginRecentlyViewed config={plugin_recently_viewed_config} />,
    document.getElementById(plugin_recently_viewed)
  );
}

if (!(typeof plugin_recommended === "undefined")) {
  ReactDOM.render(
    <PluginRecommended config={plugin_recommended_config} favorite />,
    document.getElementById(plugin_recommended)
  );
}

if (!(typeof plugin_recommended_collections === "undefined")) {
  ReactDOM.render(
    <PluginRecommendedCollections
      config={plugin_recommended_collections_config}
    />,
    document.getElementById(plugin_recommended_collections)
  );
}

if (!(typeof plugin_similar === "undefined")) {
  ReactDOM.render(
    <PluginSimilar config={plugin_similar_config} />,
    document.getElementById(plugin_similar)
  );
}

if (!(typeof plugin_permalink === "undefined")) {
  ReactDOM.render(
    <PluginPermalink config={plugin_permalink_config} />,
    document.getElementById(plugin_permalink)
  );
}

if (!(typeof plugin_collection_preview_collections_item_list === "undefined")) {
  ReactDOM.render(
    <CollectionList
      config={plugin_collection_preview_collections_item_config}
    />,
    document.getElementById(plugin_collection_preview_collections_item_list)
  );
}

if (!(typeof plugin_collection_preview_collections === "undefined")) {
  ReactDOM.render(
    <CollectionsPlugin config={plugin_collection_preview_collections_config} />,
    document.getElementById(plugin_collection_preview_collections)
  );
}

if (!(typeof plugin_my_profile === "undefined")) {
  ReactDOM.render(
    <PluginMyProfile config={plugin_my_profile_config} />,
    document.getElementById(plugin_my_profile)
  );
}

if (!(typeof plugin_skiplinks === "undefined")) {
  ReactDOM.render(
    <PluginSkiplinks config={plugin_skiplinks_config} />,
    document.getElementById(plugin_skiplinks)
  );
}

ReactDOM.render(<ScrollToTop />, document.getElementById("container_footer"));

document.addEventListener("DOMContentLoaded", function() {
  if (window.CMS !== undefined) {
    window.CMS.$(window).on("cms-content-refresh", function() {
      location.reload(true);
    });
  }
});
