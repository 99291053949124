import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const NoItems = ({ title, description }) => {
  const { t } = useTranslation();
  return (
    <div className="no-items">
      <img src="/static/images/no_data.svg" />
      <div className="no-items__title">{title || t("app.table.noItems")}</div>
      {description && <div>{description}</div>}
    </div>
  );
};

NoItems.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

export default NoItems;
