import React, { useEffect, useState } from "react";

import Modal from "react-responsive-modal";
import ModalPlugins from "../../plugins/components/assets/Modal/Modal";
import axiosSession from "@app/config/axiosSession";
import parse from "html-react-parser";
import PropTypes from "prop-types";

const ShowUnacceptedRules = ({ type, backoffice }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [messages, setMessages] = useState({});
  const [unacceptedRulesId, setUnacceptedRulesId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const url = `/api/rules/${type}/unaccepted-by-user/`;
    const typeString = type === "agreements" ? "Zgody" : "Regulamin";

    axiosSession
      .get(url)
      .then(({ data }) => {
        const items = data.results;
        const itemsIds = items.map(f => f.id);
        setUnacceptedRulesId(itemsIds);
        const content = items.map((f, index) =>
          parse(
            `<div key="${index}" class="rules-content-section__element">${
              type === "agreements" ? `<span>${f.short_content}</span>` : ""
            }${f.content}</div>`
          )
        );
        setMessages({
          headerTitle: typeString,
          contentLabel: typeString,
          bodyContent: content,
          btnDeleteNo: "Anuluj",
          btnDeleteYes: "Akceptuj"
        });
        openModal();
      })
      .catch(error => {
        console.error(error);
      });
  }, [type]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const logout = () => {
    axiosSession
      .post("/api/rest-auth/logout/")
      .then(() => {
        window.location.href = "/";
      })
      .catch(error => {
        console.error(error);
      });
  };

  const acceptRules = ids => {
    setIsLoading(true);
    if (type === "agreements") {
      const data = { agreements: ids };
      axiosSession
        .post("/api/rules/agreements/accept/", data)
        .then(() => {
          window.location.reload();
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => setIsLoading(false));
    } else if (type == "terms") {
      const data = { terms: ids };
      axiosSession
        .post("/api/rules/terms/accept/", data)
        .then(() => {
          window.location.reload();
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      {backoffice ? (
        <Modal
          open={modalIsOpen}
          center
          showCloseIcon={false}
          closeOnOverlayClick={false}
          classNames={{
            modal: "GenericModal GenericModal__Frontoffice"
          }}
        >
          <div className="GenericModal__Header--with-border">
            <p className="GenericModal__Header__Title">
              {messages.headerTitle}
            </p>
          </div>

          <div className="GenericModal__Body rules-content-section">
            {messages.bodyContent}
          </div>

          <div className="GenericModal__Footer">
            <p className="GenericModal__Footer__Buttons buttons is-right is-fullwidth">
              <button
                className="button button--is-frontoffice GenericModal__Footer__Button"
                onClick={logout}
              >
                {messages.btnDeleteNo}
              </button>
              <button
                className={`button button--is-orange ${
                  isLoading ? "is-loading" : ""
                } GenericModal__Footer__Button`}
                onClick={() => acceptRules(unacceptedRulesId)}
              >
                {messages.btnDeleteYes}
              </button>
            </p>
          </div>
        </Modal>
      ) : (
        <ModalPlugins
          open={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          title={messages?.headerTitle ?? ""}
          content={messages?.bodyContent ?? ""}
          onCloseVisibility={false}
          buttons={
            <div className="button-container">
              <button className="btn" onClick={logout}>
                {messages.btnDeleteNo}
              </button>
              <button
                className="btn"
                onClick={() => acceptRules(unacceptedRulesId)}
              >
                {messages.btnDeleteYes}
              </button>
            </div>
          }
        />
      )}
    </>
  );
};

ShowUnacceptedRules.propTypes = {
  type: PropTypes.string.isRequired,
  backoffice: PropTypes.bool
};

ShowUnacceptedRules.defaultProps = {
  backoffice: false
};

export default ShowUnacceptedRules;
