import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import ReactStars from "react-rating-stars-component";
import ToggleSection from "@app/plugins/components/assets/ToggleSection/ToggleSection";
import axiosSession from "@app/config/axiosSession";
import { useTranslation } from "react-i18next";
import TextRow from "../../components/assets/Typography/TextRow";

const Rating = ({ id, asElement, setIsText, getUpdatedRate }) => {
  const { t } = useTranslation();
  const [rate, setRate] = useState(null);
  const [showSection, setShowSection] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const primaryColor =
    typeof plugin_digital_item_preview_mirador_config !== "undefined"
      ? plugin_digital_item_preview_mirador_config?.primary_color
      : primaryColor
      ? primaryColor
      : "#d11722";
  const endpointURL = `/api/digital_items/${id}/user-rate/`;
  const minRate = 0.5;
  const maxRate = 5;

  useEffect(() => {
    if (showSection || asElement) {
      axiosSession
        .get(endpointURL)
        .then(({ data }) => {
          setRate(data.rate);
          setIsLogged(true);
        })
        .catch(err => {
          console.error(err);
          setIsLogged(false);
          setIsText(true);
        })
        .finally(() => setHasLoaded(true));
    }
  }, [showSection, endpointURL, id]);

  const onChange = value => {
    if (value >= minRate && value <= maxRate) {
      setRate(value);
      axiosSession
        .put(endpointURL, { value: value })
        .then(() => getUpdatedRate())
        .catch(err => console.error(err));
    }
  };

  return (
    <div className="plugin-digital-item-rating">
      {id && !asElement && (
        <ToggleSection
          title={t("cms.plugins.rating.name")}
          showSection={showSection}
          setShowSection={setShowSection}
        />
      )}

      {((showSection && hasLoaded) || asElement) &&
        (isLogged ? (
          <div>
            {!asElement ? (
              <TextRow
                title={t("cms.plugins.rating.addRate")}
                content={
                  <ReactStars
                    classNames="plugin-digital-item-rating__stars"
                    count={5}
                    value={rate}
                    onChange={onChange}
                    activeColor={primaryColor}
                    isHalf={true}
                  />
                }
              />
            ) : (
              <ReactStars
                classNames="plugin-digital-item-rating__stars"
                count={5}
                value={rate}
                onChange={onChange}
                activeColor={primaryColor}
                isHalf={true}
              />
            )}
          </div>
        ) : (
          <div className="comments__statement">
            <span>
              {t("cms.plugins.rating.addLoginRequired")}{" "}
              <a
                className="comments__login-link"
                href={`/login/?next=${window.location.pathname}${window.location.search}`}
              >
                {t("cms.plugins.comments.logIn")}
              </a>
            </span>
          </div>
        ))}
    </div>
  );
};

Rating.propTypes = {
  id: PropTypes.string,
  asElement: PropTypes.bool,
  setIsText: PropTypes.func,
  getUpdatedRate: PropTypes.func
};

Rating.defaultProps = {
  asElement: false
};

export default Rating;
