 import {
  BarsOutlined,
  DownloadOutlined,
  FilterOutlined,
  HourglassOutlined,
  ReconciliationOutlined,
  StarOutlined,
  TableOutlined
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
  getAdvancedResults,
  getSimpleResults,
  newsletterSignUp
} from "@app/services/frontofficeServices.js";
import useWindowWidth from "@app/hooks/useWindowResize";
import axiosSession from "@app/config/axiosSession";

import AdvancedListView from "./views/AdvancedView";
import ChangePageSize from "./pagination/ChangePageSize";
import Cookies from "js-cookie";
import DownloadModal from "./DownloadModal";
import Filters from "./Filters";
import ListView from "./views/ListView";
import LoadMore from "./pagination/LoadMore";
import Newsletter from "./Newsletter";
import Sort from "./Sort";
import TilesView from "./views/TilesView";
import TimelineView from "./views/TimelineView";
import isEmpty from "lodash/isEmpty";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";

const Results = ({ config }) => {
  const {
    advanced_search_id: advancedSearchId,
    indexer_url: indexerUrl,
    detail_view_url: detailViewUrl,
    default_view: defaultView,
    user_pk: userId,
    plugin_id: pluginId,
    can_download_many: canDownload,
    query_type: queryType,
    tiles_view,
    list_view,
    advanced_view,
    display_filter_plugin,
    filter_plugin_name,
    query,
    timeline_view,
    history
  } = config;
  const { notifyError, notifySuccess } = useGenericToastify();
  const { t } = useTranslation();
  const mobileBreakpoint = 768;
  const isMobile = useWindowWidth() < mobileBreakpoint;

  const [isLoading, setIsLoading] = useState(true);
  const [lastPage, setLastPage] = useState(true);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState("score");

  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState([]);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [collectionsDict, setCollectionsDict] = useState({});
  const [isMounted, setIsMounted] = useState(false);
  const [currentLoading, setCurrentLoading] = useState(true);
  const [isFiltersVisible, setIsFiltersVisible] = useState(
    window.innerWidth > mobileBreakpoint
  );
  const [activeView, setActiveView] = useState(defaultView);
  const [editingView, setEditingView] = useState(true);
  const [queryId, setQueryId] = useState(advancedSearchId);
  const [downloadEnable, setDownloadEnable] = useState(true)

  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadList, setDownloadList] = useState([]);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);

  const noImageUrl = "/static/images/no_image.png";
  const queryString = JSON.parse(query).query;
  const textMaxLength = 60;
  const urlQuery = new URLSearchParams(window.location.search).get("query");
  const urlType = new URLSearchParams(window.location.search).get("type");
  const redirectParams = `${urlQuery ? `&query=${urlQuery}` : ""}${urlType ? `&type=${urlType}` : ""
    }`;
  const favoriteUrl = "/api/digital_items/user/favorite/";
  const createFavouriteEditUrl = id =>
    `/api/digital_items/${id}/user/favorite/`;

  const getData = () => {
    const params = {
      limit,
      page,
      sort,
      view: activeView,
      id: queryId,
      plugin_id: pluginId,
      ...(isEmpty(filters) && history ? { history: 1 } : {})
    };

    if (activeView === "timeline") {
      params.sort = "PL.marc21_publication_date";
    }
    if (activeView === "tiles") {
      delete params.view;
    }

    const searchUrl =
      indexerUrl === "simple"
        ? getSimpleResults(params)
        : getAdvancedResults(params);
    searchUrl
      .then(({ data }) => {
        setTotal(data.total);
        setItems(origin => [...origin, ...data.items]);
        setFilters(data.filter_values);
        setDefaultFilters(data?.search_settings?.filters?.[0] ?? {});

        setLastPage(data.last_page);
        setCollectionsDict(data?.collections_dict ?? {});
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        setIsMounted(true);
      });
  };

  useEffect(() => {
    const activeViewCookie = Cookies.get("activeView");
    if (activeViewCookie) {
      setActiveView(activeViewCookie);
    }
    setEditingView(false);
  }, []);

  useEffect(() => {
    if (!editingView) {
      setIsLoading(true);

      Cookies.set("activeView", activeView);
      if (page === 1) {
        setItems([]);
      }
      getData();
    }
  }, [activeView, editingView, limit, page, queryId, sort]);

  useEffect(() => {
    setDownloadEnable(activeView === "tiles")
  }, [activeView])

  const download = id =>
    setDownloadList(origin =>
      origin.includes(id) ? origin.filter(x => x !== id) : origin.concat(id)
    );

  const handleFavorite = (id, isLiked) => {
    if (userId) {
      if (!isLoading) {
        setIsLoading(true);
        if (isLiked) {
          setItems(
            items.map(item =>
              item.identifier === id ? { ...item, is_liked: false } : item
            )
          );
          axiosSession
            .delete(createFavouriteEditUrl(Number(id)))
            .then(() => {
              getData();
              notifySuccess(t("cms.plugins.favorites.deleteSuccess"));
            })
            .catch(() => notifyError(t("cms.plugins.favorites.deleteFail")))
            .finally(() => setIsLoading(false));
        } else {
          setItems(
            items.map(item =>
              item.identifier === id ? { ...item, is_liked: true } : item
            )
          );
          axiosSession
            .post(favoriteUrl, { digital_item: Number(id) })
            .then(() => {
              getData();
              notifySuccess(t("cms.plugins.favorites.additionSuccess"));
            })
            .catch(() => notifyError(t("cms.plugins.favorites.additionFail")))
            .finally(() => setIsLoading(false));
        }
      }
    } else {
      notifyError(t("cms.plugins.results.notLogged"));
    }
  };

  const getResultsView = viewType => {
    switch (viewType) {
      case "tiles":
        return (
          <TilesView
            items={items}
            isDownloading={isDownloading}
            downloadList={downloadList}
            urls={urls}
            download={download}
            withRedirectModal={true}
            redirectParams={redirectParams}
            halfSize
            favorite
            handleFavorite={handleFavorite}
          />
        );
      case "list":
        return (
          <ListView
            items={items}
            urls={urls}
            textMaxLength={textMaxLength}
            isDownloading={isDownloading}
            redirectParams={redirectParams}
            favorite
            handleFavorite={handleFavorite}
          />
        );
      case "expanded":
        return (
          <AdvancedListView
            items={items}
            urls={urls}
            isDownloading={isDownloading}
            redirectParams={redirectParams}
            favorite
            handleFavorite={handleFavorite}
          />
        );
      case "timeline":
        return (
          <TimelineView
            items={items}
            urls={urls}
            redirectParams={redirectParams}
            favorite
            handleFavorite={handleFavorite}
          />
        );
      default:
        return "";
    }
  };

  const handleViewChange = view => {
    if (isLoading) return;
    if (view === activeView) return view;
    setIsLoading(true);
    setItems([]);
    setPage(1);
    setActiveView(view);
  };

  const switchFiltersVisibility = () => {
    setIsFiltersVisible(origin => !origin);
  };

  const handleSubscribe = () => {
    const id = queryId;
    if (userId) {
      setCurrentLoading(id);
      newsletterSignUp({ id })
        .then(() => notifySuccess("Zapisano na newsletter"))
        .catch(e => notifyError(e.response.data.id))
        .finally(() => setCurrentLoading(null));
    } else {
      notifyError(t("cms.plugins.results.notLogged"));
    }
  };

  const urls = { detailViewUrl, noImageUrl };
  return (
    <div className="plugin-results">
      {/* {canDownload && ( */}
      <DownloadModal
        downloadList={downloadList}
        downloadModalOpen={downloadModalOpen}
        setDownloadModalOpen={setDownloadModalOpen}
        pluginId={pluginId}
        items={items}
        setDownloadList={setDownloadList}
        userId={userId}
      />
      {/* )} */}
      <div className="section wrapper__outside">
        <div className="section__header-container">
          <h2 className="header--decorated">
            {t("cms.plugins.results.title")}
          </h2>
          <span className="section__header-note">
            {t("cms.plugins.results.total")}: {total}
          </span>
        </div>
        <div className="section__container--side">
          <div
            className={`side-menu${isFiltersVisible ? "" : " side-menu--hidden"
              }`}
          >
            <Filters
              componentName={filter_plugin_name}
              filters={filters}
              defaultFilters={defaultFilters}
              setQueryId={setQueryId}
              queryId={queryId}
              setPage={setPage}
              collectionsDict={collectionsDict}
              queryString={queryString}
              disabled={isDownloading}
              queryType={queryType}
              scrollable={false}
            />
          </div>
          <div className="section__content">
            <div className="btn-group btn-group--panel">
              <div
                className={`btn-group ${isMobile ? "btn-group--no-margin" : ""
                  }`}
              >
                <Tooltip
                  title={
                    isFiltersVisible
                      ? t("cms.plugins.results.hideFilters")
                      : t("cms.plugins.results.showFilters")
                  }
                >
                  <button
                    className="side-menu__close btn btn--text db"
                    onClick={switchFiltersVisibility}
                  >
                    <FilterOutlined
                      className="anticon-dark"
                      aria-label={
                        isFiltersVisible
                          ? t("cms.plugins.results.hideFilters")
                          : t("cms.plugins.results.showFilters")
                      }
                    />
                  </button>
                </Tooltip>
                {downloadEnable ?( !isDownloading ? (
                  <button
                    onClick={() => setIsDownloading(true)}
                    className="btn btn--text with-icon"
                    aria-label={t("cms.plugins.results.downloading")}
                  >
                    {isMobile ? (
                      ""
                    ) : (
                      <p>{t("cms.plugins.results.downloading")} </p>
                    )}
                    <DownloadOutlined className="anticon-dark btn__icon" />
                  </button>
                ) : (
                  <div className="btn-group btn-group--no-space">
                    <button
                      className="btn btn--text"
                      onClick={() => {
                        downloadList.length
                          ? setDownloadModalOpen(true)
                          : notifyError(t("cms.plugins.results.choseFiles"));
                      }}
                      aria-label={t("cms.plugins.results.download")}
                    >
                      <span>{t("cms.plugins.results.download")}</span>
                    </button>
                    <button
                      onClick={() => {
                        setIsDownloading(false);
                        setDownloadList([]);
                      }}
                      className="btn btn--text"
                      aria-label={t("cms.plugins.results.cancel")}
                    >
                      <span>{t("cms.plugins.results.cancel")}</span>
                    </button>
                  </div>
                )): ("")}
                <Tooltip
                  title={t("cms.plugins.results.saveSearchTooltip")

                  }
                >
                  <button
                    className="side-menu__close btn btn--text db with-icon"
                    onClick={handleSubscribe}
                    alt={t("cms.plugins.results.saveSearch")}
                  >
                    {isMobile ? (
                      ""
                    ) : (
                      <p>{t("cms.plugins.results.saveSearch")} </p>
                    )}
                    <StarOutlined aria-label={null} className="anticon-dark" />
                  </button>
                </Tooltip>
              </div>

              <div className="btn-group__spacer"></div>
              <Sort
                setPage={setPage}
                setSort={setSort}
                sort={sort}
                disabled={isDownloading}
              />
              <ChangePageSize setPage={setPage} setLimit={setLimit} />
              <ul>
                <li>
                  {tiles_view && (
                    <Tooltip title={t("cms.plugins.results.tilesView")}>
                      <button
                        onClick={() => handleViewChange("tiles")}
                        className={`btn btn--text${activeView === "tiles" ? " active" : ""
                          }`}
                        aria-label={t("cms.plugins.results.tilesView")}
                      >
                        <TableOutlined />
                      </button>
                    </Tooltip>
                  )}
                </li>
                <li>
                  {list_view && (
                    <Tooltip title={t("cms.plugins.results.listView")}>
                      <button
                        onClick={() => handleViewChange("list")}
                        className={`btn btn--text${activeView === "list" ? " active" : ""
                          }`}
                        aria-label={t("cms.plugins.results.listView")}
                      >
                        <BarsOutlined />
                      </button>
                    </Tooltip>
                  )}
                </li>
                <li>
                  {timeline_view && (
                    <Tooltip title={t("cms.plugins.results.timelineView")}>
                      <button
                        onClick={() => handleViewChange("timeline")}
                        className={`btn btn--text${activeView === "timeline" ? " active" : ""
                          }`}
                        aria-label={t("cms.plugins.results.timelineView")}
                      >
                        <HourglassOutlined />
                      </button>
                    </Tooltip>
                  )}
                </li>
                <li>
                  {advanced_view && (
                    <Tooltip title={t("cms.plugins.results.advancedView")}>
                      <button
                        onClick={() => handleViewChange("expanded")}
                        className={`btn btn--text${activeView === "expanded" ? " active" : ""
                          }`}
                        aria-label={t("cms.plugins.results.advancedView")}
                      >
                        <ReconciliationOutlined />
                      </button>
                    </Tooltip>
                  )}
                </li>
              </ul>
            </div>

            {isLoading && page === 1 ? (
              <div className="grid">
                <div className="col-xs-12 col-sm-4"></div>
                <div className="col-xs-12 col-sm-8">
                  <p>{t("cms.plugins.results.loading")}</p>
                </div>
              </div>
            ) : (
              getResultsView(activeView)
            )}

            {isMounted && items.length > 0 ? (
              <div className="section__content__more">
                <LoadMore
                  setPage={setPage}
                  isLoading={isLoading}
                  isLastPage={lastPage}
                />
              </div>
            ) : (
              !isLoading && (
                <div className="no-data">
                  <div className="no-data__header">
                    <h3>{t("cms.plugins.results.no_results.header")}</h3>
                  </div>
                  <div className="no-data__p-margin-auto">
                    <p>{t("cms.plugins.results.no_results.hint")}</p>
                  </div>
                  <div className="no-data__p">
                    <p>{t("cms.plugins.results.no_results.savePrompt")}</p>
                  </div>
                  <div className="no-data__buttonPanel">
                    <button
                      className="no-data__buttonPanel__btn btn"
                      onClick={handleSubscribe}
                    >
                      {t("cms.plugins.results.no_results.save")}{" "}
                      <StarOutlined />
                    </button>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
