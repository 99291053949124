import axiosSession from "@app/config/axiosSession";

const getAdvancedResults = params => {
  return axiosSession.get("/api/search/frontoffice/advanced-search/results/", {
    params
  });
};

const postSimpleSearch = data => {
  return axiosSession.post("/api/search/frontoffice/simple-search/", data);
};

const getSimpleResults = params => {
  return axiosSession.get("/api/search/frontoffice/simple-search/results/", {
    params
  });
};

const newsletterSignUp = data => {
  return axiosSession.post("/api/search/frontoffice/subscribe/", data);
};

const getFavouritesIds = () => {
  return axiosSession.get("/api/digital_items/user/favorite/ids/");
};

const likeItem = data => {
  return axiosSession.post("/api/digital_items/user/favorite/", data);
};

const dislikeItem = id => {
  return axiosSession.delete(`/api/digital_items/${id}/user/favorite/`);
};

export {
  getAdvancedResults,
  postSimpleSearch,
  getSimpleResults,
  newsletterSignUp,
  getFavouritesIds,
  likeItem,
  dislikeItem,
};
