import React, { useEffect, useState } from "react";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  ArrowRightOutlined
} from "@ant-design/icons";
import Input from "../../components/Input/Input";
import axiosSession from "../../config/axiosSession";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import useGenericToastify from "../../hooks/useGenericToastify";
import { useTranslation } from "react-i18next";

const SetPassword = ({ uid, token, username }) => {
  const { handleSubmit, register, errors, getValues, setError } = useForm();
  const [inputError, setInputError] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { notifyError } = useGenericToastify();

  const { t } = useTranslation();

  const InputTypes = {
    text: "text",
    password: isPasswordVisible ? "text" : "password"
  };

  const resetErrors = () => {
    setInputError(false);
  };

  useEffect(() => {
    !isEmpty(errors)
      ? notifyError(t("cms.plugins.setPassword.errors.formError"))
      : null;
  }, [errors, notifyError]);

  const onSubmit = data => {
    resetErrors();
    data.uid = uid;
    data.token = token;
    axiosSession
      .post("/set-password/confirmation/", data)
      .then(res => {
        window.location.href = res.data.url;
      })
      .catch(error => {
        notifyError(t("cms.plugins.setPassword.errors.formError"));

        if (error.response.data.new_password1) {
          setError("new_password1", "", error.response.data.new_password1);
        }
        if (error.response.data.new_password2) {
          let pass2Errors = error.response.data.new_password2;
          pass2Errors = pass2Errors.map(error => {
            return error.replace(/użytkownik/g, "loginu");
          });
          setError("new_password2", "", pass2Errors);
        }

        if (error.response.data.non_field_errors) {
          setInputError(error.response.data.non_field_errors[0]);
        }
      });
  };
  return (
    <section className="section wrapper">
      <nav className="breadcrumbs">
        <ul>
          <li>
            <a
              className="breadcrumbs__frontoffice-link"
              href="/"
              title={t("cms.plugins.setPassword.mainPage")}
            >
              {t("cms.plugins.setPassword.mainPage")}
            </a>
          </li>
          <li>
            <span>&rarr;</span>
          </li>
          <li>
            <span className="breadcrumbs__current">
              {t("cms.plugins.setPassword.setPassword")}
            </span>
          </li>
        </ul>
      </nav>
      <section className="section">
        <h1 id="mbc_header">{t("cms.plugins.setPassword.setPassword")}</h1>
        <div className="set-password__title-row">
          <h2 className="header--decorated">
            {t("cms.plugins.setPassword.setPassword")}
          </h2>
        </div>
        <div className="set-password__container">
          <form
            className="form-group"
            onSubmit={handleSubmit(onSubmit)}
            autocomplete="on"
          >
            <Input
              label={t("cms.plugins.setPassword.loginLabel")}
              type="text"
              value={username}
              name={"username"}
              disabled={true}
            />

            <div className="input-container">
              <Input
                placeholder={t("cms.plugins.placeholders.password")}
                className="login__password-field"
                name="new_password1"
                label={t("cms.plugins.setPassword.passwordLabel")}
                required
                type={InputTypes.password}
                register={register({
                  required: t("cms.plugins.setPassword.errors.required")
                })}
                errors={errors}
              />
              <button
                className="icon-end"
                type="button"
                onClick={() => setIsPasswordVisible(prev => !prev)}
              >
                {isPasswordVisible ? (
                  <EyeInvisibleOutlined className="show-password-btn__icon" />
                ) : (
                  <EyeOutlined className="show-password-btn__icon" />
                )}
              </button>
            </div>

            <div className="input-container">
              <Input
                placeholder={t("cms.plugins.placeholders.password")}
                className="login__password-field"
                name={"new_password2"}
                label={t("cms.plugins.setPassword.repeatPasswordLabel")}
                required
                type={InputTypes.password}
                register={register({
                  required: t("cms.plugins.setPassword.errors.required"),
                  validate: value =>
                    value === getValues()["new_password1"] ||
                    t("cms.plugins.setPassword.errors.passwordMismatch")
                })}
                errors={errors}
              />
              <button
                className="icon-end"
                type="button"
                onClick={() => setIsPasswordVisible(prev => !prev)}
              >
                {isPasswordVisible ? (
                  <EyeInvisibleOutlined className="show-password-btn__icon" />
                ) : (
                  <EyeOutlined className="show-password-btn__icon" />
                )}
              </button>
            </div>

            <button className="btn" type="submit">
              {t("cms.plugins.setPassword.setPassword")}
              &nbsp;
              <ArrowRightOutlined aria-hidden="true" />
            </button>
          </form>
        </div>
      </section>
    </section>
  );
};
export default SetPassword;
