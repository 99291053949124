import React, { useEffect } from "react";

import isEmpty from "lodash/isEmpty";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";
import ArrowButton from "../../plugins/components/assets/Button/ArrowButton";

const RegisterEmail = ({ message }) => {
  const { t } = useTranslation();
  const { notifySuccess } = useGenericToastify();

  useEffect(() => {
    if (!isEmpty(message.messages)) {
      notifySuccess(message.messages[0]);
    }
  }, [message, notifySuccess]);

  return (
    <section className="section wrapper">
      <nav className="breadcrumbs">
        <ul>
          <li>
            <a
              className="breadcrumbs__frontoffice-link"
              href="/"
              title={t("cms.plugins.registerEmail.mainPage")}
            >
              {t("cms.plugins.registerEmail.mainPage")}
            </a>
          </li>

          <li>
            <span>&rarr;</span>
          </li>
          <li>
            <a className="breadcrumbs__frontoffice-link" href="/login">
              {t("cms.plugins.registerEmail.login")}
            </a>
          </li>

          <li>
            <span>&rarr;</span>
          </li>
          <li>
            <a className="breadcrumbs__frontoffice-link" href="/rejestracja">
              {t("cms.plugins.registerEmail.register")}
            </a>
          </li>
          <li>
            <span>&rarr;</span>
          </li>
          <li>
            <span className="breadcrumbs__current">
              {t("cms.plugins.registerEmail.activate")}
            </span>
          </li>
        </ul>
      </nav>

      <section className="section">
        <h1 id="mbc_header">{t("cms.plugins.registerEmail.activate")}</h1>
        <div className="login__title-row">
          <h2 className="header--decorated">
            {t("cms.plugins.registerEmail.activate")}
          </h2>
          <ArrowButton href="/login" className="login__title-row__register">
            {t("cms.plugins.login.login")}
          </ArrowButton>
        </div>
        <div className="login__container">
          <p>{t("cms.plugins.registerEmail.emailSend")}.&nbsp;</p>
          <ArrowButton href="/login" className="login__links__register">
            {t("cms.plugins.registerEmail.login")}
          </ArrowButton>
        </div>
      </section>
    </section>
  );
};

export default RegisterEmail;
