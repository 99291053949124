import React, { useEffect, useState } from "react";

import GenericFieldArray from "@app/components/GenericFieldArray/GenericFieldArray";
import GenericSelect from "@app/components/Select/GenericSelect";
import axiosSession from "@app/config/axiosSession";
import find from "lodash/find";
import handleErrors from "@app/helpers/handleErrors";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";
import ArrowButton from "../assets/Button/ArrowButton";
import Modal from "../assets/Modal/Modal";

const Correction = ({ config }) => {
  const {
    id,
    files,
    metadata,
    meta_categories,
    files_categories,
    user_pk
  } = config;
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useGenericToastify();
  const { getValues, handleSubmit, control, errors, setError } = useForm();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [metaCats, setMetaCats] = useState([]);
  const [filesCats, setFilesCats] = useState([]);
  const [secondSelectOptions, setSecondSelectOptions] = useState([
    {
      object_id: "",
      category: "",
      content: ""
    }
  ]);
  const [removeAllArray, setRemoveAllArray] = useState(false);
  const [label, setLabel] = useState(
    t("cms.plugins.metaCorrection.modal.metaLabel")
  );
  const [isTypeSelected, setIsTypeSelected] = useState(false);
  const typeSelect = [
    { id: 2, name: t("cms.plugins.metaCorrection.modal.metadata") },
    { id: 1, name: t("cms.plugins.metaCorrection.modal.files") }
  ];
  const [correctionType, setCorrectionType] = useState(null);

  useEffect(() => {
    const cats = meta_categories.map((elem, idx) => ({ id: idx, name: elem }));
    setMetaCats(cats);
    setSecondSelectOptions(cats);
    setFilesCats(
      files_categories.map((elem, idx) => ({ id: idx, name: elem }))
    );
  }, [meta_categories, files_categories]);

  const onSubmit = data => {
    if (data.values === undefined) {
      notifyError(t("cms.plugins.metaCorrection.emptySubmit"));
      return;
    }

    const formData = {
      digital_item: id,
      correction_type: correctionType,
      values: data.values.map(elem => ({
        ...elem,
        object_id: elem.object_id.id,
        category: elem.category.name
      }))
    };

    axiosSession
      .post(`/api/digital_items/correction/`, formData)
      .then(() => {
        notifySuccess(t("cms.plugins.metaCorrection.success"));
        setModalIsOpen(false);
      })
      .catch(err => {
        console.error(err);
        notifyError(t("cms.plugins.metaCorrection.error"));
        handleErrors(err.response.data, setError);
      });
    onClose();
  };

  const onClose = () => {
    setSelectOptions([]);
    setSecondSelectOptions([]);
    setCorrectionType(null);
    setModalIsOpen(false);
    setIsTypeSelected(false);
  };

  const onChange = ([selectedOption]) => {
    if (selectedOption.id === correctionType) {
      return selectedOption;
    }
    const isMeta = selectedOption.id === 2;
    setCorrectionType(selectedOption.id);
    setSelectOptions(
      isMeta
        ? metadata?.map(item => ({ ...item, visible: true }))
        : files?.map(item => ({ ...item, visible: true }))
    );
    setSecondSelectOptions(isMeta ? metaCats : filesCats);
    setLabel(
      isMeta
        ? t("cms.plugins.metaCorrection.modal.metaLabel")
        : t("cms.plugins.metaCorrection.modal.fileLabel")
    );
    setRemoveAllArray(true);
    setIsTypeSelected(selectedOption.name !== "");
    return selectedOption;
  };

  const onAttributeChange = ([selectedOption, description]) => {
    const selectedValues = getValues({ nest: true })[
      "values"
    ].map((item, index) =>
      `values[${index}].object_id` === description.name
        ? selectedOption
        : item.object_id
    );
    const filteredOptionsForSelect = selectOptions.map(item => ({
      ...item,
      visible: !find(selectedValues, { id: item.id, name: item.name })
    }));
    setSelectOptions(filteredOptionsForSelect);
    return selectedOption;
  };

  const onClick = () => {
    if (user_pk > 0) {
      setModalIsOpen(true);
    } else {
      notifyError(t("cms.plugins.metaCorrection.loginMessage"));
    }
  };

  return (
    <div className="item-correction">
      {id && (
        <>
          <ArrowButton onClick={onClick}>
            {t("cms.plugins.metaCorrection.button")}
          </ArrowButton>
          <Modal
            open={modalIsOpen}
            onClose={onClose}
            title={t("cms.plugins.metaCorrection.modal.title")}
            content={
              <form
                className="item-correction__modal"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="grid item-correction__modal__row">
                  <div className="col-xs-12 col-md-6">
                    <GenericSelect
                      options={typeSelect}
                      control={control}
                      errors={errors}
                      name="meta"
                      label={t("cms.plugins.metaCorrection.type")}
                      defaultValue={{
                        name: t("cms.plugins.metaCorrection.select")
                      }}
                      handleSelectChange={onChange}
                      isSearchable={false}
                      noOptionsMessage={() => t("app.select")}
                      className="cms-select"
                      classNamePrefix="cms-select"
                      menuPosition="fixed"
                    />
                  </div>
                  <div className="col-xs-12 col-md-6 item-correction__modal__tip-container">
                    <span className="item-correction__modal__tip">
                      {t("cms.plugins.metaCorrection.changeMessage")}
                    </span>
                  </div>
                </div>
                {isTypeSelected && isEmpty(selectOptions) ? (
                  <p>{t("cms.plugins.metaCorrection.resourceHasNoItems")}</p>
                ) : (
                  <GenericFieldArray
                    isFrontoffice
                    handleSelectChange={onAttributeChange}
                    name="values"
                    control={control}
                    errors={errors}
                    addButtonText={t(
                      "cms.plugins.metaCorrection.modal.addButtonText"
                    )}
                    deleteButtonTitle={t(
                      "cms.plugins.metaCorrection.modal.deleteButton"
                    )}
                    selectName="object_id"
                    selectOptions={selectOptions.filter(item => item.visible)}
                    selectLabel={label}
                    className="cms-select"
                    classNamePrefix="cms-select"
                    menuPosition="fixed"
                    defaultValue=""
                    secondDefaultValue=""
                    inputName="content"
                    inputLabel={`${t(
                      "cms.plugins.metaCorrection.modal.inputLabel"
                    )} *`}
                    appendOption={{
                      object_id: "",
                      category: "",
                      content: ""
                    }}
                    removeAllArray={removeAllArray}
                    setRemoveAllArray={setRemoveAllArray}
                    disabled={false}
                    secondSelectOptions={secondSelectOptions}
                    secondSelectName="category"
                    secondSelectLabel={t(
                      "cms.plugins.metaCorrection.modal.category"
                    )}
                    secondSelect={true}
                    secondRequired={{
                      required: t(
                        "cms.plugins.metaCorrection.modal.errors.required"
                      ),
                      maxLength: {
                        value: 100,
                        message: t(
                          "cms.plugins.metaCorrection.modal.errors.maxLength"
                        )
                      }
                    }}
                    noOptionsMessage={() => t("app.select")}
                    isFirstOptionVisible
                    isTypeSelected={isTypeSelected}
                  />
                )}
              </form>
            }
            buttons={
              <>
                <button
                  className="btn btn--text"
                  onClick={onClose}
                  onKeyPress={onClose}
                >
                  {t("cms.plugins.metaCorrection.modal.cancle")}
                </button>
                <button
                  className="btn"
                  onClick={() => handleSubmit(onSubmit)()}
                >
                  {t("cms.plugins.metaCorrection.modal.submit")}
                </button>
              </>
            }
          />
        </>
      )}
    </div>
  );
};

export default Correction;
