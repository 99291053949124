const createFocusTrap = ({
  containerElement,
  containerSelector,
  onEscape
} = {}) => {
  const container =
    containerElement || document.querySelector(containerSelector);
  const focusableElements = container?.querySelectorAll("a,  button, input");
  if (focusableElements?.length > 0) {
    const firstFocusableEl = focusableElements[0];
    const lastFocusableEl = focusableElements[focusableElements.length - 1];

    const focusTrap = {
      onEscape,
      remberElement: null,
      keyboardHandler(e) {
        if (e.code === "Tab") {
          if (!container.contains(document.activeElement)) {
            e.preventDefault();
            firstFocusableEl.focus();
            return;
          }
          if (e.shiftKey && document.activeElement === firstFocusableEl) {
            e.preventDefault();
            lastFocusableEl.focus();
          } else if (
            !e.shiftKey &&
            document.activeElement === lastFocusableEl
          ) {
            e.preventDefault();
            firstFocusableEl.focus();
          }
        } else if (e.code === "Escape") {
          if (focusTrap.onEscape) {
            focusTrap.onEscape(focusTrap);
          }
        }
      },
      trap() {
        document.addEventListener("keydown", focusTrap.keyboardHandler);
        focusTrap.remberElement = document.activeElement;
        setTimeout(() => {
          firstFocusableEl.focus();
        });
      },
      release() {
        document.removeEventListener("keydown", focusTrap.keyboardHandler);
        const el = focusTrap.remberElement || document.body;
        el.focus();
      }
    };

    return focusTrap;
  } else {
    throw new Error("Didn't find any element to trap focus");
  }
};

export { createFocusTrap };
