import "./ResizableGenericSelect.scss";

import CustomStyles from "./CustomStyles";
import GenericSelect from "@app/components/Select/GenericSelect";
import React from "react";
import { useTranslation } from "react-i18next";

const ResizableGenericSelect = props => {
  const { t } = useTranslation();

  return (
    <GenericSelect
      {...props}
      className="cms-select resizable-generic-select"
      styles={CustomStyles}
      noOptionsMessage={() => t("app.select")}
    />
  );
};

export default ResizableGenericSelect;
