import React, { useState } from "react";
import {
  ArrowRightOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";

import axiosSession from "@app/config/axiosSession";
import handleErrors from "@app/helpers/handleErrors";
import useGenericToastify from "@app/hooks/useGenericToastify";
import CMSTable from "@components/Table/CMSTable";
import Input from "@components/Input/CMSInput";
import Modal from "../assets/Modal/Modal";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Space } from "antd";

const ProfileComments = ({ config }) => {
  const { handleSubmit, register, errors, setError } = useForm();
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useGenericToastify();
  const [deleteItemData, setDeleteItemData] = useState({});
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [shouldTableRefresh, setShouldTableRefresh] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [editableItem, setEditableItem] = useState({});
  const [CMSTableView, setCMSTableView] = useState("table");

  const urls = {
    get: "/api/digital_items/user/comments/"
  };

  const columns = [
    {
      title: t("cms.plugins.profileComments.structure.title"),
      dataIndex: "name",
      key: "digital_item__name",
      sorter: true,
      width: "30%"
    },
    {
      title: t("cms.plugins.profileComments.structure.content"),
      dataIndex: "content",
      key: "content",
      sorter: true,
      width: "30%"
    },
    {
      title: t("cms.plugins.profileComments.structure.created"),
      dataIndex: "date",
      key: "created",
      sorter: true,
      width: "15%"
    },
    {
      title: t("cms.plugins.profileComments.structure.actions"),
      dataIndex: "",
      sorter: false,
      width: "12%",
      fixed: "right",
      render: (text, record) => (
        <Space
          size="small"
          direction={CMSTableView === "list" ? "vertical" : "horizontal"}
        >
          <a
            className="btn btn--text-black"
            href={`${config.detail_view_url}${record.slug}`}
            title={t("cms.plugins.profileComments.actions.goto")}
            aria-label={t("cms.plugins.profileComments.actions.goto")}
          >
            <ArrowRightOutlined className="anticon-dark" aria-hidden="true" />{" "}
            {CMSTableView === "list" &&
              t("cms.plugins.profileComments.actions.goto")}
          </a>
          <button
            className="btn btn--text-black"
            title={t("cms.plugins.profileComments.actions.edit")}
            aria-label={t("cms.plugins.profileComments.actions.edit")}
            onClick={() => onEdit(record)}
          >
            <EditOutlined className="anticon-dark" aria-hidden="true" />{" "}
            {CMSTableView === "list" &&
              t("cms.plugins.profileComments.actions.edit")}
          </button>
          <button
            className="btn btn--text-black"
            title={t("cms.plugins.profileComments.actions.delete")}
            aria-label={t("cms.plugins.profileComments.actions.delete")}
            onClick={() => onDelete(record)}
          >
            <DeleteOutlined className="anticon-dark" aria-hidden="true" />{" "}
            {CMSTableView === "list" &&
              t("cms.plugins.profileComments.actions.delete")}
          </button>
        </Space>
      )
    }
  ];

  const onDelete = item => {
    setDeleteItemData({ id: item.id, name: item.name });
    setDeleteModalIsOpen(true);
  };

  const onConfirmDelete = id => {
    axiosSession
      .delete(`/api/digital_items/${id}/user/comments/`)
      .then(() => {
        notifySuccess(t("cms.plugins.profileComments.message.deleteSuccess"));
        setShouldTableRefresh(true);
      })
      .catch(err => {
        notifyError(t("cms.plugins.profileComments.message.deleteError"));
        console.error(err);
      })
      .finally(() => {
        setDeleteModalIsOpen(false);
        setShouldTableRefresh(false);
      });
  };

  const onEdit = item => {
    setEditableItem(item);
    setEditModalIsOpen(true);
  };

  const onConfirmEdit = data => {
    axiosSession
      .put(`/api/digital_items/${editableItem.id}/user/comments/`, {
        id: editableItem.id,
        digital_item: editableItem.digital_item,
        content: data.comment
      })
      .then(() => {
        notifySuccess(t("cms.plugins.profileComments.message.editSuccess"));
        setShouldTableRefresh(true);
        setEditModalIsOpen(false);
      })
      .catch(err => {
        notifyError(t("cms.plugins.profileComments.message.editError"));
        console.error(err);
        handleErrors(err.response.data, setError);
      })
      .finally(() => setShouldTableRefresh(false));
  };
  return (
    <>
      <Modal
        open={editModalIsOpen}
        onClose={() => setEditModalIsOpen(false)}
        title={t("cms.plugins.profileComments.editModal.title")}
        content={
          <form onSubmit={handleSubmit(onConfirmEdit)}>
            <div
              key={editableItem.id}
              className="form-group form-group--full-width"
            >
              <div>
                {t("cms.plugins.profileComments.editModal.forPublication")}
                <span className="bold"> {editableItem.name}</span>
              </div>
              <div>
                {t("cms.plugins.profileComments.editModal.fromDate")}
                <span className="bold">
                  {" "}
                  {format(new Date(), "dd.MM.yyyy")}
                </span>
              </div>
              <Input
                name="comment"
                tag="textarea"
                placeholder={t("cms.plugins.profileComments.structure.content")}
                alt={t("cms.plugins.profileComments.structure.content")}
                defaultValue={editableItem.content}
                register={register({
                  required: t("cms.plugins.profileComments.editModal.required")
                })}
                label=""
              />
            </div>
          </form>
        }
        buttons={
          <>
            <button
              className="btn btn--text"
              onClick={() => setEditModalIsOpen(false)}
              onKeyPress={() => setEditModalIsOpen(false)}
            >
              {t("cms.plugins.profileComments.editModal.cancle")}&nbsp;
              <CloseOutlined className="anticon-dark" aria-label={null} />
            </button>
            <button
              className="btn"
              onClick={() => handleSubmit(onConfirmEdit)()}
            >
              {t("cms.plugins.profileComments.editModal.save")}&nbsp;
              <ArrowRightOutlined aria-label={null} />
            </button>
          </>
        }
      />
      <Modal
        open={deleteModalIsOpen}
        onClose={() => setDeleteModalIsOpen(false)}
        title={t("cms.plugins.profileComments.message.headerTitle")}
        content={
          <div className="only-text" key={deleteItemData.id}>
            <DeleteOutlined aria-label={null} />
            <span>{t("cms.plugins.profileComments.message.bodyContent")}</span>
          </div>
        }
        buttons={
          <>
            <button
              className="btn btn--text"
              onClick={() => setDeleteModalIsOpen(false)}
              onKeyPress={() => setDeleteModalIsOpen(false)}
            >
              {t("cms.plugins.profileComments.message.btnDeleteNo")}&nbsp;
              <CloseOutlined className="anticon-dark" aria-label={null} />
            </button>
            <button
              className="btn"
              onClick={() => onConfirmDelete(deleteItemData.id)}
            >
              {t("cms.plugins.profileComments.message.btnDeleteYes")}&nbsp;
              <DeleteOutlined aria-label={null} />
            </button>
          </>
        }
      />
      <CMSTable
        columns={columns}
        dataUrl={urls.get}
        listView
        shouldTableRefresh={shouldTableRefresh}
        mobileBreakpoint={1366}
        noItemsProps={{
          title: t("cms.plugins.profileComments.noItems.title"),
          description: t("cms.plugins.profileComments.noItems.description")
        }}
        setUpperView={setCMSTableView}
      />
    </>
  );
};

export default ProfileComments;
