import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { MoreOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Popover from "@material-ui/core/Popover";

const CMSListElem = ({ data, scheme }) => {
  const { t } = useTranslation();
  const [element, setElement] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = e => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const classAssign = name => {
    if (
      name === t("cms.plugins.profileComments.structure.title") ||
      name === t("cms.plugins.searchHistory.structure.query")
    )
      return "title";
    else if (
      name === t("cms.plugins.profileComments.structure.created") ||
      name === t("cms.plugins.searchHistory.structure.created")
    )
      return "date";
    return "";
  };

  useEffect(() => {
    const newElem = {};
    scheme.map(part =>
      "render" in part
        ? (newElem[part.title] = part.render("text", data))
        : (newElem[part.title] = data[part.dataIndex])
    );
    setElement(newElem);
  }, []);

  return (
    <>
      <Popover
        open={open}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
      >
        {element["Akcje"]}
      </Popover>
      <div className="element">
        <div className="left-side">
          {Object.keys(element).map(
            (key, index) =>
              key !== "Akcje" && (
                <p key={index} className={classAssign(key)}>
                  {element[key]}
                </p>
              )
          )}
        </div>
        <div className="right-side">
          <MoreOutlined
            onClick={handlePopoverOpen}
            style={{ fontSize: "24px" }}
          />
        </div>
      </div>
    </>
  );
};

CMSListElem.propTypes = {
  data: PropTypes.object.isRequired,
  scheme: PropTypes.array.isRequired
};

export default CMSListElem;
