import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import useWindowWidth from "@app/hooks/useWindowResize";

const PluginUserMenu = ({ config }) => {
  const currentURL = menuOption => {
    if (window.location.pathname === `/${config.lang}${menuOption}`) {
      return "active";
    } else {
      return "";
    }
  };
  const menuOptions = Object.values(config.menu_options).map(
    elem => `/${config.lang}${elem}`
  );
  const inMenuOption = menuOptions.includes(window.location.pathname);
  const width = useWindowWidth();
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    if (width < 768 && !inMenuOption) {
      setCollapsed(false);
    }
  }, [width]);

  return (
    <>
      <div className="side-menu__header">
        <button
          onClick={() => setCollapsed(prev => !prev)}
          className={`side-menu__trigger-md btn btn--text ${
            collapsed ? "collapsed" : ""
          }`}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          <span> Menu</span>
        </button>
      </div>
      <ul className={`side-menu__list-md ${collapsed ? "collapsed" : ""}`}>
        {!isEmpty(config.menu_options) &&
          Object.entries(config.menu_options).map(([item, value], index) => (
            <li key={index}>
              <a href={value} className={currentURL(value)}>
                {item}
              </a>
            </li>
          ))}
      </ul>
    </>
  );
};

export default PluginUserMenu;
