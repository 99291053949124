import React, { useEffect, useState } from "react";
import {
  getSimpleResults,
  postSimpleSearch
} from "@app/services/frontofficeServices.js";

import ChangePageSize from "@app/plugins/components/results/pagination/ChangePageSize";
import LoadMore from "@app/plugins/components/results/pagination/LoadMore";
import NoItems from "@components/NoItems/NoItems";
import Sort from "@app/plugins/components/results/Sort";
import TilesView from "@app/plugins/components/results/views/TilesView";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";

const CollectionList = ({
  config: { group_id, detail_view_url: detailViewUrl }
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState("-created");
  const [items, setItems] = useState([]);
  const [lastPage, setLastPage] = useState(true);
  const [query, setQuery] = useState("");
  const { t } = useTranslation();
  const { notifyError } = useGenericToastify();

  const urls = {
    detailViewUrl: detailViewUrl,
    noImageUrl: "/static/images/no_image.png"
  };

  const getLanguage = () => {
    return (
      t.language ||
      (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
      "PL"
    );
  };

  useEffect(() => {
    setIsLoading(true);
    if (group_id) {
      postSimpleSearch({
        query: group_id,
        search_type: "groups",
        full_url: "",
        filters: [],
        language: getLanguage().toUpperCase()
      })
        .then(({ data }) => {
          if (query !== data) {
            setPage(1);
            setQuery(data);
          }
        })
        .catch(() => {
          notifyError(t("cms.plugins.advancedSearch.errors.searchFailed"));
        })
        .finally(() => setIsLoading(false));
    }
  }, [group_id]);

  useEffect(() => {
    setIsLoading(true);
    if (page === 1) {
      setItems([]);
    }
    const params = {
      limit,
      page,
      sort,
      id: query,
      plugin_id: ""
    };
    if (query) {
      getSimpleResults(params)
        .then(({ data }) => {
          setTotal(data.total);
          setItems(origin => [...origin, ...data.items]);
          setLastPage(data.last_page);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [query, limit, page, sort]);

  return (
    <>
      <div className="btn-group btn-group--panel">
        <Sort
          setPage={setPage}
          setSort={setSort}
          sort={sort}
          disabled={false}
          excludeFilters={["score"]}
        />
        <div className="btn-group__spacer"></div>
        <ChangePageSize setPage={setPage} setLimit={setLimit} />
        <div>
          <span>{`${t("cms.plugins.group_object_items_list.total")}:`}</span>
          &nbsp;
          <strong>{total}</strong>
        </div>
      </div>
      {items.length > 0 ? (
        <>
          <TilesView items={items} urls={urls} downloadList={[]} halfSize />
          <div className="section__content__more">
            <LoadMore
              setPage={setPage}
              isLoading={isLoading}
              isLastPage={lastPage}
            />
          </div>
        </>
      ) : (
        <div className="text-center">
          {isLoading ? (
            <p>{t("cms.plugins.results.loading")}</p>
          ) : (
            <NoItems title={t("cms.plugins.group_object_items_list.empty")} />
          )}
        </div>
      )}
    </>
  );
};

export default CollectionList;
