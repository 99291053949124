import { Comment, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { deleteComment, deleteCommentReason } from "@app/services/services";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericInput from "@app/components/Input/GenericInput";
import ResizableGenericInput from "@app/plugins/components/assets/Input/ResizableGenericInput";
import ToggleSection from "@app/plugins/components/assets/ToggleSection/ToggleSection";
import axiosSession from "@app/config/axiosSession";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";
import Modal from "../assets/Modal/Modal";

const Comments = ({ config }) => {
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useGenericToastify();
  const {
    errors,
    control,
    reset,
    setValue,
    handleSubmit,
    setError
  } = useForm();
  const {
    errors: modalErrors,
    control: modalControl,
    handleSubmit: modalHandleSubmit
  } = useForm();

  const commentsUrlPost = "/api/digital_items/user/comments/ ";
  const commentsUrlGet = `/api/digital_items/${config.id}/comments/`;
  const pageSize = 5;

  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showSection, setShowSection] = useState(false);
  const [count, setCount] = useState(false);
  const [comments, setComments] = useState([]);
  const [page, setPage] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [extendedComments, setExtendedComments] = useState([]);
  const [isAdminDelete, setIsAdminDelete] = useState(false);

  const adminMessages = {
    bodyContent: t("cms.plugins.comments.deleteLabel"),
    headerTitle: t("cms.plugins.comments.deleting"),
    btnDeleteYes: t("cms.plugins.comments.deleteComment"),
    btnDeleteNo: t("cms.plugins.comments.cancel")
  };

  const userMessages = {
    bodyContent: t("cms.plugins.comments.confirmDelete"),
    headerTitle: t("cms.plugins.comments.deleting"),
    btnDeleteYes: t("cms.plugins.comments.yes"),
    btnDeleteNo: t("cms.plugins.comments.no")
  };

  const onSubmit = data => {
    if (data.comment.length > 750) {
      setError("comment", "", t("cms.plugins.comments.tooLarge"));
    } else {
      axiosSession
        .post(commentsUrlPost, {
          content: data.comment,
          digital_item: config.id
        })
        .then(() => {
          notifySuccess(t("cms.plugins.comments.addSucces"));
          setValue("comment", "");
          setShouldRefresh(true);
        })
        .catch(() => notifyError(t("cms.plugins.comments.addFailure")));
    }
  };

  useEffect(() => {
    if (showSection && shouldRefresh) {
      axiosSession
        .get(commentsUrlGet, {
          params: {
            limit: pageSize,
            ...(page && page > 1 ? { page: page } : {})
          }
        })
        .then(({ data }) => {
          setCount(data.count);
          setComments(data.results);
        })
        .catch(error => console.error(error))
        .finally(() => {
          reset();
          setShouldRefresh(false);
          setIsLoading(false);
        });
    }
  }, [showSection, page, shouldRefresh]);

  const onPaginationChange = inPage => {
    if (inPage <= Math.ceil(count / pageSize)) {
      setPage(inPage);
    }
    setShouldRefresh(true);
  };
  const onInputPaginationChange = e => {
    if (e.keyCode == 13) {
      const newPagination = parseInt(e.target.value);
      if (newPagination >= 0 && newPagination <= Math.ceil(count / pageSize)) {
        setPage(e.target.value);
        setShouldRefresh(true);
      }
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onConfirm = data => {
    isAdminDelete
      ? deleteCommentReason(itemId, data.content)
          .then(() => {
            notifySuccess(t("cms.plugins.comments.removeSucces"));
            setShouldRefresh(true);
          })
          .catch(err => {
            console.error(err);
            notifyError(t("cms.plugins.comments.removeFailure"));
          })
      : deleteComment(itemId)
          .then(() => {
            notifySuccess(t("cms.plugins.comments.removeSucces"));
            setShouldRefresh(true);
          })
          .catch(err => {
            console.error(err);
            notifyError(t("cms.plugins.comments.removeFailure"));
          });

    closeModal();
  };

  const onCancel = () => {
    closeModal();
  };

  const CustomComment = ({ comment, isStaff, username }) => {
    const [isEditing, setIsEditing] = useState(false);
    const { errors, control, handleSubmit, setError } = useForm();
    const urlCommentEdit = `/api/digital_items/${comment.id}/user/comments/`;

    const onDelete = () => {
      if (comment.username === username) {
        setIsAdminDelete(false);
      } else {
        setIsAdminDelete(true);
      }
      setItemId(comment.id);
      openModal("delete");
    };

    const onEditSubmit = data => {
      if (data.comment_edit.length > 750) {
        setError("comment_edit", "", t("cms.plugins.comments.tooLarge"));
      } else {
        axiosSession
          .put(urlCommentEdit, {
            content: data.comment_edit,
            digital_item: config.id
          })
          .then(() => {
            notifySuccess(t("cms.plugins.comments.editSucces"));
            setShouldRefresh(true);
          })
          .catch(() => notifyError(t("cms.plugins.comments.editFailure")))
          .finally(() => setIsEditing(false));
      }
    };

    const actions = [
      ...(username === comment.username
        ? [
            <span key="0">
              <FontAwesomeIcon
                icon={faEdit}
                className="has-object"
                onClick={() => setIsEditing(true)}
                onKeyPress={() => setIsEditing(true)}
                tabIndex="0"
                title={t("cms.plugins.comments.editComment")}
              />
            </span>
          ]
        : []),
      ...(isStaff || username === comment.username
        ? [
            <span key="1">
              <FontAwesomeIcon
                icon={faTrash}
                className="has-object"
                onClick={onDelete}
                onKeyPress={onDelete}
                tabIndex="0"
                title={t("cms.plugins.comments.deleteComment")}
              />
            </span>
          ]
        : [])
    ];

    const handleExtendComment = () => {
      const extendedCommentsList = Array.from(extendedComments);
      if (extendedCommentsList.includes(comment.id)) {
        extendedCommentsList.splice(
          extendedCommentsList.indexOf(comment.id),
          1
        );
      } else {
        extendedCommentsList.push(comment.id);
      }
      setExtendedComments(extendedCommentsList);
    };

    const createContent = () => {
      const displayedTextLength = 200;
      const substringLength = 180;
      const createdContent =
        comment.content.length > displayedTextLength ? (
          <div>
            <p>
              {extendedComments.includes(comment.id)
                ? comment.content
                : `${comment.content.substring(0, substringLength)}...`}
            </p>
            <button className="show-more-button" onClick={handleExtendComment}>
              {extendedComments.includes(comment.id)
                ? t("cms.plugins.comments.showLess")
                : t("cms.plugins.comments.showMore")}
            </button>
          </div>
        ) : (
          <div>
            <p>{comment.content}</p>
          </div>
        );
      return createdContent;
    };

    return (
      <>
        {isEditing ? (
          <form onSubmit={handleSubmit(onEditSubmit)}>
            <div className="comments__add-comment">
              <ResizableGenericInput
                tag={"textarea"}
                name={"comment_edit"}
                errors={errors}
                control={control}
                required={{
                  required: t("cms.plugins.comments.required")
                }}
                label={`${t("cms.plugins.comments.edit")}:`}
                defaultValue={comment.content}
              />

              <button className="btn" type="submit">
                {t("cms.plugins.comments.edit")}
              </button>
              <button
                className="btn btn--text"
                onClick={() => setIsEditing(false)}
              >
                {t("cms.plugins.comments.cancel")}
              </button>
            </div>
          </form>
        ) : (
          <>
            <Comment
              author={comment.username}
              content={createContent()}
              datetime={`${comment.date} ${comment.time}`}
              actions={actions}
            />
          </>
        )}
      </>
    );
  };

  return (
    <div className="comments">
      <form onSubmit={modalHandleSubmit(onConfirm)}>
        <Modal
          open={modalIsOpen}
          onClose={onCancel}
          title={userMessages.headerTitle}
          content={
            <>
              {isAdminDelete ? (
                <GenericInput
                  label={adminMessages.bodyContent}
                  placeholder=""
                  name="content"
                  darkTheme={true}
                  control={modalControl}
                  tag="textarea"
                  required={{ required: "To pole jest wymagane" }}
                  errors={modalErrors}
                />
              ) : (
                userMessages.bodyContent
              )}
            </>
          }
          buttons={
            <>
              <button className="btn btn--text" onClick={onCancel}>
                {isAdminDelete
                  ? adminMessages.btnDeleteNo
                  : userMessages.btnDeleteNo}
              </button>
              <button
                className="btn"
                onClick={modalHandleSubmit(data => onConfirm(data))}
              >
                {isAdminDelete
                  ? adminMessages.btnDeleteYes
                  : userMessages.btnDeleteYes}
              </button>
            </>
          }
        />
      </form>
      {config.id && (
        <ToggleSection
          title={`${t("cms.plugins.comments.comments")} (${
            typeof count === "number" ? count : config.count
          })`}
          showSection={showSection}
          setShowSection={setShowSection}
        />
      )}

      {showSection && !isLoading && (
        <>
          {config.user === "" ? (
            <div className="comments__statement">
              <span>
                {t("cms.plugins.comments.addLoginRequired")}{" "}
                <a
                  className="comments__login-link"
                  href={`/login/?next=${window.location.pathname}${window.location.search}`}
                >
                  {t("cms.plugins.comments.logIn")}
                </a>
              </span>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="comments__add-comment">
                <ResizableGenericInput
                  tag="textarea"
                  name="comment"
                  errors={errors}
                  control={control}
                  required={{
                    required: "To pole jest wymagane!"
                  }}
                  label={`${t("cms.plugins.comments.addComment")}:`}
                />
                <button
                  className="comments__add-comment__button btn"
                  type="submit"
                >
                  {t("cms.plugins.comments.save")}
                </button>
              </div>
            </form>
          )}
          <div className="comments__comments-list">
            {comments.length > 0 && (
              <div className="comments__comments-list__pagination">
                <div className="comments__comments-list__pagination__container">
                  <Pagination
                    size="small"
                    total={count}
                    onChange={onPaginationChange}
                    pageSize={pageSize}
                    current={parseInt(page)}
                  />
                </div>
                <span>{t("cms.plugins.comments.goTo")}</span>
                <input
                  className="comments__comments-list__pagination__quick-jump"
                  onKeyDown={onInputPaginationChange}
                ></input>
              </div>
            )}
            {comments.length > 0 ? (
              comments.map(comment => {
                return (
                  <CustomComment
                    key={comment.id}
                    comment={comment}
                    isStaff={config.is_staff}
                    username={config.user}
                  />
                );
              })
            ) : (
              <div className="comments__statement">
                <span>{t("cms.plugins.comments.noComments")}</span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Comments;
