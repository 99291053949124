import { DownOutlined, UndoOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import CMSCheckbox from "@components/Checkbox/CMSCheckbox";
import CollectionsFilter from "./CollectionsFilter";
import axiosSession from "../../../config/axiosSession";
import isEmpty from "lodash/isEmpty";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";

const FilterSection = ({
  values,
  title,
  displayTitle,
  defaultFilters,
  filterOptions,
  setFilterOptions
}) => {
  const [showSection, setShowSection] = useState(!isEmpty(defaultFilters));
  const { t } = useTranslation();

  const stateSetter = (item, e = null) => {
    if (e === null || e.keyCode === 13) {
      const usedKey = title === "copyright" ? "id" : "value";
      setFilterOptions(origin => {
        let temp = Object.assign({}, origin);
        temp[title].includes(item[usedKey])
          ? (temp[title] = temp[title].filter(x => {
              return x !== item[usedKey];
            }))
          : temp[title].push(item[usedKey]);
        return temp;
      });
    }
  };

  const handleRootPositionAction = e => {
    if (!e.key || e.key === "Enter") {
      if (values.length !== 0) {
        setShowSection(origin => !origin);
      }
    }
  };

  return (
    <li>
      <span
        role="button"
        className={`${values.length === 0 ? "disabled" : ""}`}
        tabIndex={values.length > 0 ? 0 : null}
        onClick={handleRootPositionAction}
        onKeyDown={handleRootPositionAction}
        aria-label={`${displayTitle}, ${t("app.listView")}`}
      >
        {displayTitle} ({values.length})
      </span>
      <DownOutlined
        rotate={showSection ? 180 : 0}
        aria-hidden="true"
        onClick={handleRootPositionAction}
      />

      <ul className={`with-checkbox ${!showSection ? "hidden" : ""}`}>
        {Object.values(values).map((item, index) => (
          <li
            key={index}
            onKeyDown={e => {
              e.stopPropagation();
              stateSetter(item, e);
            }}
            onClick={e => {
              e.stopPropagation();
              stateSetter(item);
            }}
          >
            <CMSCheckbox
              key={`${title}-${index}-${filterOptions[title].includes(
                item.value
              ) || filterOptions[title].includes(item.id)}`}
              name={`${item.value} (${item.count})`}
              value={item.value}
              checked={
                filterOptions[title].includes(item.value) ||
                filterOptions[title].includes(item.id)
              }
            >
              {`${item.value} (${item.count})`}
            </CMSCheckbox>
          </li>
        ))}
      </ul>
    </li>
  );
};

const Filters = ({
  componentName,
  filters,
  defaultFilters,
  setQueryId,
  setPage,
  collectionsDict,
  queryString,
  queryId,
  disabled,
  queryType,
  scrollable
}) => {
  const emptyFilters = {
    author: [],
    frequency: [],
    language: [],
    publication_place: [],
    release_date: [],
    keywords: [],
    object_type: [],
    coauthor: [],
    publisher: [],
    copyright: [],
    catalog: [],
    collections: []
  };

  const { t } = useTranslation();
  const [filterOptions, setFilterOptions] = useState(
    JSON.parse(JSON.stringify(emptyFilters))
  );
  const { notifyError } = useGenericToastify();

  const simpleSearchUrl = "/api/search/frontoffice/simple-search/";

  const titlesTranslations = {
    object_type: t("cms.plugins.filters.object_type"),
    author: t("cms.plugins.filters.author"),
    coauthor: t("cms.plugins.filters.coauthor"),
    publisher: t("cms.plugins.filters.publisher"),
    publication_place: t("cms.plugins.filters.publication_place"),
    language: t("cms.plugins.filters.language"),
    frequency: t("cms.plugins.filters.frequency"),
    keywords: t("cms.plugins.filters.keywords"),
    release_date: t("cms.plugins.filters.release_date"),
    copyright: t("cms.plugins.filters.copyright"),
    catalog: t("cms.plugins.filters.catalog")
  };

  const getLanguage = () => {
    return (
      t.language ||
      (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
      "PL"
    );
  };

  const filter = (clear = false) => {
    axiosSession
      .post(simpleSearchUrl, {
        query: queryString,
        search_type: queryType,
        full_url: "",
        filters: clear ? [emptyFilters] : [filterOptions],
        language: getLanguage().toUpperCase()
      })
      .then(({ data }) => {
        if (queryId !== data) {
          setPage(1);
          setQueryId(data);
        }
      })
      .catch(() => {
        notifyError(t("cms.plugins.advancedSearch.errors.searchFailed"));
      });
  };

  const clearFilters = () => {
    setFilterOptions(JSON.parse(JSON.stringify(emptyFilters)));
    filter(true);
  };

  useEffect(() => {
    let tempFitlers = JSON.parse(JSON.stringify(filterOptions));
    Object.keys(defaultFilters).forEach(key => {
      tempFitlers[key] = defaultFilters[key];
    });
    setFilterOptions(tempFitlers);
  }, [defaultFilters]);

  return (
    <>
      <CollectionsFilter
        defaultCollection={defaultFilters?.["collections"] ?? []}
        collectionsDict={collectionsDict}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
      />
      {!isEmpty(filters) ? (
        <>
          <div className="side-menu__header">{componentName}</div>
          <ul
            className={` side-menu__list side-menu__list--small side-menu__list${
              scrollable ? "--scrollable" : ""
            } plugin-filters-list`}
          >
            {Object.keys(titlesTranslations).map(key => {
              if (filters?.[key] ?? null) {
                return (
                  <FilterSection
                    values={filters[key]}
                    title={key}
                    displayTitle={titlesTranslations[key]}
                    defaultFilters={defaultFilters?.[key] ?? []}
                    setFilterOptions={setFilterOptions}
                    key={key}
                    filterOptions={filterOptions}
                  />
                );
              }
            })}
          </ul>
          <div className="btn-group btn-group--column">
            <button
              className="btn"
              onClick={() => {
                filter();
              }}
            >
              {t("cms.plugins.filters.showResults")}
            </button>
            <button
              className="btn btn--text"
              onClick={() => {
                clearFilters();
              }}
              alt={t("cms.plugins.filters.clearFilters")}
            >
              <span>{t("cms.plugins.filters.clearFilters")}</span>
              <UndoOutlined aria-label={null} />
            </button>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

Filters.propTypes = {
  componentName: PropTypes.string.isRequired,
  filters: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  defaultFilters: PropTypes.object.isRequired,
  setQueryId: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  collectionsDict: PropTypes.object.isRequired,
  queryString: PropTypes.string.isRequired,
  queryId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  queryType: PropTypes.string.isRequired,
  scrollable: PropTypes.bool
};

Filters.defaultProps = {
  scrollable: true
};

export default Filters;
