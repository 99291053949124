import "./ResizableGenericInput.scss";

import { Controller, ErrorMessage } from "react-hook-form";

import PropTypes from "prop-types";
import React from "react";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";

const ResizableGenericInput = ({
  tag: Tag,
  type,
  control,
  name,
  label,
  required,
  disabled,
  darkTheme,
  onKeyPress,
  placeholder,
  onChange,
  value,
  defaultValue,
  errors,
  onBlur
}) => {
  const inputStyle = Tag === "textarea" ? "textarea" : "input";
  const whiteText = darkTheme
    ? "mbc-resizable-input__label"
    : "mbc-resizable-input__label--light";
  const isMultiError = false;
  return (
    <div className="field">
      <label
        htmlFor={name}
        className={"mbc-resizable-input__label " + whiteText}
      >
        {label}
      </label>
      <div className="control mbc-resizable-input">
        <Controller
          as={<Tag type={type} onBlur={onBlur} />}
          control={control}
          className={`${inputStyle} ${
            !isEmpty(errors) && has(errors, name) ? "is-danger" : ""
          }`}
          name={name}
          id={name}
          rows={7}
          disabled={disabled}
          rules={required}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
        />
      </div>
      {!isEmpty(errors) && has(errors, name) && isMultiError ? (
        errors[name].message.map((message, index) => (
          <p key={index} className="help is-danger">
            {message}
          </p>
        ))
      ) : !isEmpty(errors) && has(errors, name) && !isMultiError ? (
        <ErrorMessage
          as={<p className="help is-danger" />}
          errors={errors}
          name={name}
        />
      ) : null}
    </div>
  );
};

ResizableGenericInput.propTypes = {
  tag: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  darkTheme: PropTypes.bool,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  errors: PropTypes.object
};

ResizableGenericInput.defaultProps = {
  tag: "input",
  type: "text",
  errors: {},
  defaultValue: "",
  darkTheme: true
};

export default ResizableGenericInput;
