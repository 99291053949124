import React, { useEffect, useState } from "react";

import ArrowButton from "../../plugins/components/assets/Button/ArrowButton";
import ConfirmForgotUsername from "./ConfirmForgotUsername";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "@app/components/Input/CMSInput";
import { Portal } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";

import axiosSession from "@app/config/axiosSession";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import handleSkiplinkClick from "@app/helpers/handleSkiplinkClick";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";

const ForgotUsername = props => {
  const reCaptchaSiteKey = props.captcha || "";
  const loginUrl = "/login";
  const forgotUsernameUrl = "/api/accounts/forgot_username/";
  const { handleSubmit, register, errors, setError, setValue } = useForm();
  const [inputErrorCaptcha, setInputErrorCaptcha] = useState(false);
  const [inputError, setInputError] = useState(false);
  const { notifyError } = useGenericToastify();
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();

  const resetErrors = () => {
    setInputErrorCaptcha(false);
    setInputError(false);
  };

  useEffect(() => {
    !isEmpty(errors)
      ? notifyError(t("cms.plugins.register.errors.formError"))
      : null;
  }, [errors, notifyError]);

  useEffect(() => {
    register({ name: "recaptcha" });
  });

  const onVerifyCaptcha = token => {
    setValue("recaptcha", token);
  };

  const onSubmit = data => {
    resetErrors();

    axiosSession
      .post(forgotUsernameUrl, data)
      .then(() => {
        setSuccess(true);
      })
      .catch(error => {
        notifyError(t("cms.plugins.register.errors.formError"));

        if (error.response.data.recaptcha) {
          setInputErrorCaptcha(error.response.data.recaptcha[0]);
        }

        if (error.response.data.email) {
          setError("email", "", error.response.data.email);
        }

        if (error.response.data.non_field_errors) {
          setInputError(error.response.data.non_field_errors[0]);
        }
      });
  };

  return success ? (
    <ConfirmForgotUsername />
  ) : (
    <>
      <Portal container={document.querySelector(".skiplinks")}>
        <button
          className="skiplink btn"
          onClick={() => handleSkiplinkClick(".header__nav-open")}
        >
          {t("cms.plugins.login.skiplinks.menu")}
        </button>
        <button
          className="skiplink btn"
          onClick={() => handleSkiplinkClick(".login__container")}
        >
          {t("cms.plugins.login.skiplinks.form")}
        </button>
        <button
          className="skiplink btn"
          onClick={() => handleSkiplinkClick(".login__title-row__register")}
        >
          {t("cms.plugins.register.skiplinks.login")}
        </button>
      </Portal>
      <section className="section wrapper">
        <nav className="breadcrumbs">
          <ul>
            <li>
              <a
                className="breadcrumbs__frontoffice-link"
                href="/"
                title={t("cms.plugins.login.mainPage")}
              >
                {t("cms.plugins.login.mainPage")}
              </a>
            </li>
            <li>
              <span>&rarr;</span>
            </li>
            <li>
              <a className="breadcrumbs__frontoffice-link" href={loginUrl}>
                {t("cms.plugins.register.login")}
              </a>
            </li>
            <li>
              <span>&rarr;</span>
            </li>
            <li>
              <span className="breadcrumbs__current">
                {t("cms.plugins.forgotUsername.label")}
              </span>
            </li>
          </ul>
        </nav>
        <section className="section">
          <h1 id="mbc_header">{t("cms.plugins.forgotUsername.header")}</h1>
          <div className="login__title-row">
            <h2 className="header--decorated">
              {t("cms.plugins.forgotUsername.label")}
            </h2>
            <ArrowButton href="/login" className="login__title-row__register">
              {t("cms.plugins.login.login")}
            </ArrowButton>
          </div>
        </section>
        <div className="login__container">
          <span>{t("cms.plugins.forgotUsername.caption")}</span>
          <form className="form-group" onSubmit={handleSubmit(onSubmit)}>
            <Input
              placeholder={t("cms.plugins.placeholders.email")}
              ariaLabel={t("cms.plugins.placeholders.email")}
              name="email"
              label="Email"
              required
              darkTheme={true}
              register={register({
                required: t("cms.plugins.register.errors.required"),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: t("cms.plugins.forgotUsername.errors.email")
                }
              })}
              errors={errors}
            />
            <div className="login__links">
              <ReCAPTCHA
                className="captcha"
                onChange={onVerifyCaptcha}
                sitekey={reCaptchaSiteKey}
              />
              <p className="is-error-text">
                {errors.recaptcha && t("cms.plugins.register.errors.required")}
                {inputErrorCaptcha}
              </p>
              <button className="btn" type="submit">
                {t("cms.plugins.forgotUsername.send")}
                &nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
              <ArrowButton href="/login" className="login__links__register">
                {t("cms.plugins.registerEmail.login")}
              </ArrowButton>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default ForgotUsername;
