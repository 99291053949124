import React from "react";

const genericAdvancedQuery = item => {
  const advancedQuery = [];
  item.query.map((element, index) => {
    advancedQuery.push(
      <p key={index} className="advanced-query-element">
        {`${
          element.operator !== ""
            ? `(${element.operator === "and" ? "i" : "lub"})`
            : ""
        } ${element.attribute} (${element.operation}): `}
        <strong>{element.value}</strong>
        {index + 1 !== item.query.length ? "," : ""}
      </p>
    );
  });
  return advancedQuery;
};

export default genericAdvancedQuery;
