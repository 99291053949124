import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

const ToggleSection = ({ title, showSection, setShowSection }) => {
  const { t } = useTranslation();
  const toggle = () => {
    setShowSection(showSection => !showSection);
  };

  return (
    <div className="toggle-section">
      <hr className="toggle-section__hr" />
      <span
        className="toggle-section__title"
        onClick={toggle}
        onKeyPress={toggle}
        role="button"
        tabIndex="-1"
        alt={`${
          showSection
            ? t("cms.plugins.collections.expand")
            : t("cms.plugins.collections.collapse")
        } ${title}`}
      >
        {title}
      </span>
      <span
        onClick={toggle}
        onKeyPress={toggle}
        role="button"
        tabIndex="0"
        className={`toggle-section__main_actions toggle-section__main_actions--${
          showSection ? "open" : "closed"
        }`}
        aria-label={`${
          showSection
            ? t("cms.plugins.collections.collapse")
            : t("cms.plugins.collections.expand")
        } ${title}`}
      >
        {showSection ? (
          <FontAwesomeIcon icon={faChevronUp} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} />
        )}
      </span>
    </div>
  );
};

export default ToggleSection;

ToggleSection.propTypes = {
  title: PropTypes.string.isRequired,
  showSection: PropTypes.bool.isRequired,
  setShowSection: PropTypes.func.isRequired
};
