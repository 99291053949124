import { Controller, ErrorMessage, useForm } from "react-hook-form";
import DatePicker from "react-date-picker";
import PropTypes from "prop-types";
import React from "react";
import { CalendarOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const GenericDatePicker = ({
  name,
  dateFormat,
  label,
  errors,
  required,
  control,
  defaultValue,
  onBlur,
  maxDate,
  minDate,
  checkDate
}) => {
  const { i18n } = useTranslation();

  const { watch, setValue } = useForm();
  return (
    <>
      <label htmlFor={name}>{label}</label>
      <Controller
        as={
          <DatePicker
            locale={i18n?.language || "pl"}
            format={dateFormat}
            calendarIcon={<CalendarOutlined fill="#363636" />}
            onBlur={onBlur}
            className="cms-datepicker"
          />
        }
        minDate={minDate}
        maxDate={maxDate}
        defaultValue={defaultValue}
        value={watch(name)}
        onChange={date => {
          setValue(name, date);
          checkDate();
          return date;
        }}
        clearIcon={null}
        rules={required}
        name={`${name}`}
        control={control}
      />
      <ErrorMessage
        as={<p className="is-error-text" />}
        errors={errors}
        name={name}
      />
    </>
  );
};

GenericDatePicker.propTypes = {
  name: PropTypes.string,
  dateFormat: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.object,
  required: PropTypes.object,
  control: PropTypes.object
};

GenericDatePicker.defaultProps = {
  dateFormat: "dd.MM.yyyy",
  defaultValue: null,
  maxDate: null,
  minDate: new Date("1299-01-01"),
  checkDate: () => null
};

export default GenericDatePicker;
