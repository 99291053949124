import "antd/dist/antd.css";

import React, { useEffect, useState } from "react";

import ToggleSection from "@app/plugins/components/assets/ToggleSection/ToggleSection";
import { Tree } from "antd";
import axiosSession from "@app/config/axiosSession";
import { useTranslation } from "react-i18next";

const Structure = ({ config }) => {
  const {
    id,
    object_type,
    digital_item_url,
    group_object_url,
    default_visible
  } = config;

  const { t } = useTranslation();
  const [showSection, setShowSection] = useState(default_visible);
  const [key, setKey] = useState([]);
  const [title, setTitle] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id && showSection) {
      axiosSession
        .get(`/api/directories/tree/${id}/opened/`, {
          params: { type: object_type }
        })
        .then(({ data }) => {
          setIsLoading(true);
          setKey([data.object.parent_key]);
          setTitle(data.object.title);
          setData(updateTreeData(data.tree, data.object.key));
        })
        .catch(err => console.error(err))
        .finally(() => setIsLoading(false));
    }
  }, [id, showSection]);

  const Link = ({ node, currentKey }) => {
    return (
      <a
        className={`plugin-digital-item-structure__node ${
          node.key === currentKey
            ? "plugin-digital-item-structure__node--current"
            : ""
        }`}
        href={
          node.key.startsWith("digital_item")
            ? `${digital_item_url}${node.slug}`
            : `${group_object_url}?group_id=${node.id}`
        }
      >
        {node.title}
      </a>
    );
  };

  const updateTreeData = (list, key) => {
    return list.map(node => {
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key),
          title: <Link node={node} currentKey={key} />
        };
      }

      return {
        ...node,
        title: <Link node={node} currentKey={key} />
      };
    });
  };

  const onSelect = (_, info) => {
    if (info.node.key.startsWith("digital_item")) {
      window.location = `${digital_item_url}${info.node.slug}`;
    } else {
      window.location = `${group_object_url}?group_id=${info.node.id}`;
    }
  };

  return (
    <div className="plugin-digital-item-structure">
      {id && (
        <>
          <ToggleSection
            title={t("cms.plugins.structure.name")}
            showSection={showSection}
            setShowSection={setShowSection}
          />

          {showSection && !isLoading && (
            <div>
              <div className="plugin-digital-item-structure__item-title">
                {title}
              </div>
              <Tree
                treeData={data}
                defaultExpandedKeys={key}
                onSelect={onSelect}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Structure;
