import React, { useEffect, useState } from "react";

import ChangePageSize from "@app/plugins/components/results/pagination/ChangePageSize";
import Input from "@app/components/Input/CMSInput";
import LoadMore from "@app/plugins/components/results/pagination/LoadMore";
import NoItems from "../NoItems/NoItems";
import PropTypes from "prop-types";
import Select from "react-select";
import axiosSession from "@app/config/axiosSession";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { SearchOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const TiledView = ({
  config,
  url,
  noItemMessage,
  noItemMessageMore,
  favorite
}) => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [lastPage, setLastPage] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [sort, setSort] = useState("-pk");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const isMounted = React.useRef(null);
  const { notifyError, notifySuccess } = useGenericToastify();
  const noImageUrl = "/static/images/no_image.png";

  const { handleSubmit } = useForm();

  const urlList = url;

  const sortOptions = [
    {
      label: t("cms.plugins.results.sort.options.newest"),
      value: "-pk"
    },
    {
      label: t("cms.plugins.results.sort.options.oldest"),
      value: "pk"
    }
  ];

  const getData = () => {
    const params = {
      limit: limit,
      page: page,
      ordering: sort,
      ...(searchQuery ? { search_field: searchQuery } : {})
    };
    axiosSession
      .get(urlList, {
        params
      })
      .then(({ data }) => {
        setItems(data.results);
        setLastPage(data.last_page);
      })
      .catch(err => {
        setLastPage(false);
        console.error(err);
      })
      .finally(() => {
        if (isMounted.current) {
          setIsLoading(false);
        }
      });
  };

  const handleSortChange = selectedOption => {
    if (selectedOption === sort) return selectedOption;
    setPage(1);
    setSort(selectedOption);
  };

  const deleteFromFavorites = id => {
    if (!isLoading) {
      setIsLoading(true);
      axiosSession
        .delete(`/api/digital_items/${id}/user/favorite/`)
        .then(() => notifySuccess(t("cms.plugins.favorites.deleteSuccess")))
        .catch(() => notifyError(t("cms.plugins.favorites.deleteFail")))
        .finally(() => getData());
    }
  };

  const getAltForImg = item => {
    if (item.title) {
      return item.title;
    } else if (item.marc21_values.title.length > 0) {
      return item.marc21_values.title;
    } else {
      return t("cms.plugins.favorites.emptyAlt");
    }
  };

  useEffect(() => {
    if (page === 1) {
      setItems([]);
    }
    if (isMounted) {
      setIsLoading(true);
      getData();
    }
  }, [sort, searchQuery, page, limit]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      <div className="btn-group btn-group--panel">
        <div className="btn-group__select">
          <label>{t("cms.plugins.results.sort.label")}:</label>
          <Select
            aria-label={`${t("cms.plugins.results.sort.label")}, ${
              sortOptions.filter(elem => elem.value === sort)[0].label
            }, ${t("app.field_start")} ${sortOptions.length} ${t(
              "app.field_end"
            )}`}
            options={sortOptions}
            defaultValue={sortOptions[0]}
            className="cms-select"
            isSearchable={false}
            classNamePrefix="cms-select"
            onChange={option => {
              if (option.value) handleSortChange(option.value);
              return option;
            }}
          />
        </div>
        <ChangePageSize setPage={setPage} setLimit={setLimit} />
        <div className="btn-group__spacer"></div>

        <form onSubmit={handleSubmit(() => setSearchQuery(searchInputValue))}>
          <div className="btn-group btn-group--no-space">
            <Input
              name="search"
              placeholder={t("cms.plugins.results.search.label")}
              ariaLabel={`${t("app.table.search.button")}, ${t("app.textbox")}`}
              value={searchInputValue}
              label=""
              onChange={e => setSearchInputValue(e.target.value)}
            />
            <button
              type="submit"
              className="btn btn--text btn--with-border"
              aria-label={t("cms.plugins.results.search.label")}
            >
              <SearchOutlined className="anticon-dark" />
            </button>
          </div>
        </form>
      </div>
      {isLoading && page === 1 ? (
        <div className="grid">
          <div className="col-xs-12 col-sm-4"></div>
          <div className="col-xs-12 col-sm-8">
            <p>{t("cms.plugins.results.loading")}</p>
          </div>
        </div>
      ) : items.length === 0 ? (
        <NoItems title={noItemMessage} description={noItemMessageMore} />
      ) : (
        <ul className="grid">
          {items.map(item => {
            const previewUrl = `${
              config.result_url ? config.result_url : "/pl/podglad/"
            }${item.slug}/?file_type=2`;
            return (
              <li
                key={item.id}
                className={`'card ${
                  favorite ? "favorite" : ""
                } col-xs-6 col-md-3 col-sm-6'`}
              >
                <a href={previewUrl}>
                  <picture className="card__cover">
                    <img
                      src={item.thumbnail ? item.thumbnail : noImageUrl}
                      alt={getAltForImg(item)}
                      className="profile"
                    />
                  </picture>
                </a>
                <div className="card__content">
                  {!item.mbc && <p className="card__date">{item.catalog}</p>}

                  <a href={previewUrl} className="card__title">
                    {item.title ? item.title : item.marc21_values.title}
                  </a>
                  <p className="card__description">
                    {item.authors ? item.authors : item.marc21_values.authors}
                  </p>
                  {item.created && <p className="card__date">{item.created}</p>}
                </div>
                {favorite && (
                  <div
                    onClick={() => deleteFromFavorites(item.identifier)}
                    role="button"
                    className="heart heart-left"
                  >
                    <i className="fas fa-heart plugin-digital-item-rating-display__heart__icon" />
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      )}
      {isMounted && items.length > 0 ? (
        <div className="section__content__more">
          <LoadMore
            setPage={setPage}
            isLoading={isLoading}
            isLastPage={lastPage}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

TiledView.propTypes = {
  config: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  noItemMessage: PropTypes.string,
  noItemMessageMore: PropTypes.string,
  favorite: PropTypes.bool
};

TiledView.defaultProps = {
  noItemMessage: "",
  noItemMessageMore: "",
  favorite: false
};

export default TiledView;
