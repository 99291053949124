import Cookies from "js-cookie";
import axiosSession from "@app/config/axiosSession";

window.logout = () => {
  axiosSession
    .post("/api/rest-auth/logout/")
    .then(() => {
      window.location.href = "/";
      Cookies.remove("activeView");
    })
    .catch(error => {
      console.error(error);
    });
};
