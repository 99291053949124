import "./Input.module.scss";

import PropTypes from "prop-types";
import React from "react";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";

const Input = ({
  tag: Tag,
  type,
  name,
  label,
  register,
  disabled,
  darkTheme,
  onKeyPress,
  placeholder,
  onChange,
  value,
  defaultValue,
  errors,
  children,
  isInputGray,
  isRequired,
  className
}) => {
  const inputStyle = Tag === "textarea" ? "textarea" : "input";
  const whiteText = darkTheme ? "mbc-input__label" : "";
  const isMultiError =
    !isEmpty(errors) && has(errors, name) && Array.isArray(errors[name].message)
      ? true
      : false;
  return (
    <div className={`field ${className}`}>
      <label htmlFor={name} className={"label " + whiteText}>
        {label}
        {isRequired && " *"}
      </label>
      <div className={`control mbc-input ${children ? "mbc-input-icon" : ""}`}>
        <Tag
          type={type}
          className={`${inputStyle} ${
            !isEmpty(errors) && has(errors, name) ? "is-danger" : ""
          } ${isInputGray ? "input__gray" : ""}`}
          name={name}
          id={name}
          disabled={disabled}
          ref={register}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
        />
        {children}
      </div>
      {!isEmpty(errors) && has(errors, name) && isMultiError ? (
        errors[name].message.map((message, index) => (
          <p key={index} className="help is-danger">
            {message}
          </p>
        ))
      ) : !isEmpty(errors) && has(errors, name) && !isMultiError ? (
        <p className="help is-danger">{errors[name].message}</p>
      ) : null}
    </div>
  );
};

Input.propTypes = {
  tag: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  darkTheme: PropTypes.bool,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  isInputGray: PropTypes.bool,
  isRequired: PropTypes.bool,
  className: PropTypes.string
};

Input.defaultProps = {
  tag: "input",
  type: "text",
  errors: {},
  children: "",
  isInputGray: false,
  isRequired: false,
  className: ""
};

export default Input;
