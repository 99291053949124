import "./Cookies.scss";

import React, { useEffect, useState } from "react";

import Cookies from "js-cookie";
import parse from "html-react-parser";

const Cookie = ({ config }) => {
  const { name, days, content, button } = config;
  const [displayCookie, setDisplayCookie] = useState(false);
  const handleClick = () => {
    Cookies.set(name, "accept", { expires: days });
    setDisplayCookie(false);
  };

  useEffect(() => {
    if (typeof Cookies.get(name) === "undefined") {
      setDisplayCookie(true);
    }
  });

  return (
    <>
      {displayCookie && (
        <div className="alert">
          <div className="alert__content">
            <p className="alert__header">Cookies</p>
            {parse(content)}
          </div>
          <div className="alert__content alert__confirm">
            <button
              className="btn btn--text-white"
              onClick={() => handleClick()}
            >
              {button}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Cookie;
