import React, { useState } from "react";

import { useTranslation } from "react-i18next";

const ChangePageSize = ({ setPage, setLimit }) => {
  const [currentLimit, setCurrentLimit] = useState(20);
  const { t } = useTranslation();

  const onChangeLimit = limit => {
    if (currentLimit === limit) return;
    setCurrentLimit(limit);
    setPage(1);
    setLimit(limit);
  };

  return (
    <div>
      <span>{t("cms.plugins.results.pagination.show")}:</span>
      <ul>
        <li>
          <button
            className={`btn btn--text${currentLimit === 20 ? " active" : ""}`}
            aria-label={`${t("cms.plugins.results.pagination.show")} 20`}
            onClick={() => onChangeLimit(20)}
          >
            20
          </button>
        </li>
        <li>
          <button
            className={`btn btn--text${currentLimit === 50 ? " active" : ""}`}
            aria-label={`${t("cms.plugins.results.pagination.show")} 50`}
            onClick={() => onChangeLimit(50)}
          >
            50
          </button>
        </li>
        <li>
          <button
            className={`btn btn--text${currentLimit === 100 ? " active" : ""}`}
            aria-label={`${t("cms.plugins.results.pagination.show")} 100`}
            onClick={() => onChangeLimit(100)}
          >
            100
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ChangePageSize;
