import "./Permalink.scss";

import { FacebookShareButton, TwitterShareButton } from "react-share";
import React, { useEffect, useRef, useState } from "react";
import { faCopy, faLink, faShare } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faTwitterSquare
} from "@fortawesome/free-brands-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosSession from "@app/config/axiosSession";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";

const Permalink = ({ config }) => {
  const {
    shareName,
    permalinkName,
    showPermalink,
    showShare: pluginShowShare
  } = config;
  const { t } = useTranslation();
  const { notifySuccess } = useGenericToastify();

  const [link, setLink] = useState(null);
  const [showLink, setShowLink] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const linkRef = useRef();
  const generatePermalinkUrl = "/api/users/links/";
  const url = `${window.location.pathname}${window.location.search}`;

  useEffect(() => {
    axiosSession
      .post(generatePermalinkUrl, { url: url })
      .then(({ data }) => {
        setLink(data.permlink);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  const handleGetPermalink = () => {
    setShowLink(!showLink);
    if (link) return;
  };

  const handleCopy = () => {
    linkRef.current.select();
    document.execCommand("copy");
    notifySuccess(t("cms.plugins.permalinkAndShare.copied"));
  };

  return (
    <div className="plugin-actions">
      {showPermalink && (
        <div className="plugin-actions__action">
          <Tooltip
            placement="top"
            arrow
            title={
              <Typography variant="body2">
                {t("cms.plugins.permalinkAndShare.copyIcon")}
              </Typography>
            }
          >
            <div
              className="plugin-actions__action__icon"
              onClick={handleGetPermalink}
              onKeyPress={handleGetPermalink}
              tabIndex="0"
              role="button"
              title={permalinkName}
            >
              <FontAwesomeIcon icon={faLink} />
            </div>
          </Tooltip>
          {showLink && (
            <>
              <Tooltip
                placement="top"
                arrow
                title={
                  <Typography variant="body2">
                    {t("cms.plugins.permalinkAndShare.copy")}
                  </Typography>
                }
              >
                <div className="plugin-actions__action__link">
                  {link ? (
                    <>
                      <input ref={linkRef} value={`https://${link}`}></input>
                      <span className="buttonText" onClick={handleCopy}>
                        {t("cms.plugins.permalinkAndShare.copyIcon")}
                      </span>
                    </>
                  ) : (
                    `${t("app.loading")}...`
                  )}
                  <div className="plugin-actions__action__copy-icon">
                    <FontAwesomeIcon
                      title={t("cms.plugins.permalinkAndShare.copy")}
                      icon={faCopy}
                      onClick={handleCopy}
                    />
                  </div>
                </div>
              </Tooltip>
            </>
          )}
        </div>
      )}
      {pluginShowShare && (
        <div className="plugin-actions__action">
          <Tooltip
            arrow
            title={
              <Typography variant="body2">
                {t("cms.plugins.permalinkAndShare.shareIcon")}
              </Typography>
            }
          >
            <div
              className="plugin-actions__action__icon"
              onClick={() => setShowShare(origin => !origin)}
              onKeyPress={() => setShowShare(origin => !origin)}
              tabIndex="0"
              role="button"
              title={shareName}
            >
              <FontAwesomeIcon icon={faShare} />
            </div>
          </Tooltip>
          {showShare && (
            <>
              <Tooltip
                arrow
                title={
                  <Typography variant="body2">
                    {t("cms.plugins.permalinkAndShare.shareTw")}
                  </Typography>
                }
              >
                <div
                  className="plugin-actions__action__icon"
                  title={t("cms.plugins.permalinkAndShare.shareTw")}
                >
                  <span className="buttonText">Twitter</span>
                  <TwitterShareButton url={link}>
                    <FontAwesomeIcon icon={faTwitterSquare} />
                  </TwitterShareButton>
                </div>
              </Tooltip>
              <Tooltip
                arrow
                title={
                  <Typography variant="body2">
                    {t("cms.plugins.permalinkAndShare.shareFb")}
                  </Typography>
                }
              >
                <div
                  className="plugin-actions__action__icon"
                  title={t("cms.plugins.permalinkAndShare.shareFb")}
                >
                  <span className="buttonText">Facebook</span>
                  <FacebookShareButton url={link}>
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </FacebookShareButton>
                </div>
              </Tooltip>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Permalink;
