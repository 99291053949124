import React, { useState } from "react";

import ToggleSection from "@app/plugins/components/assets/ToggleSection/ToggleSection";
import { useTranslation } from "react-i18next";
import ArrowButton from "../assets/Button/ArrowButton";

const DownloadMeta = ({ config }) => {
  const { t } = useTranslation();
  const [showSection, setShowSection] = useState(false);

  const downloadUrl = type =>
    `/api/digital_items/${config.id}/${type}/download`;

  return (
    <div className="download-meta">
      {config.id && (
        <ToggleSection
          title={t("cms.plugins.downloadMeta.title")}
          showSection={showSection}
          setShowSection={setShowSection}
        />
      )}

      {showSection && (
        <div className="download-meta__content">
          {config.dublin_core && (
            <ArrowButton href={downloadUrl("dc")}>dublin core</ArrowButton>
          )}
          {config.marctxt && (
            <ArrowButton href={downloadUrl("marc")}>marc21 txt</ArrowButton>
          )}
          {config.marcxml && (
            <ArrowButton href={downloadUrl("marcxml")}> marc21 xml</ArrowButton>
          )}
          {config.mods && (
            <ArrowButton href={downloadUrl("mods")}> Mods</ArrowButton>
          )}
        </div>
      )}
    </div>
  );
};

export default DownloadMeta;
