// import "./DownloadModal.scss";

import React, { useEffect, useState } from "react";

// import Modal from "react-responsive-modal";
import Modal from "../assets/Modal/Modal";
import ResizableSelect from "../assets/Select/ResizableSelect";
import axiosSession from "@app/config/axiosSession";
import { useTranslation } from "react-i18next";

const DownloadModal = ({
  downloadList,
  setDownloadList,
  setDownloadModalOpen,
  downloadModalOpen,
  pluginId,
  items,
  userId
}) => {
  const { t } = useTranslation();
  const filename = "files";
  const filetypeOptions = [
    { value: 1, label: "PDF" },
    {
      value: 2,
      label: t("cms.plugins.fileList.fileTypes.original")
    },
    {
      value: 3,
      label: t("cms.plugins.fileList.fileTypes.representation")
    },
    {
      value: 5,
      label: t("cms.plugins.fileList.fileTypes.ocr")
    },
    {
      value: 6,
      label: t("cms.plugins.fileList.fileTypes.djvu")
    }
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [usedType, setUsedType] = useState(filetypeOptions[0].value);
  const [availableList, setAvailableList] = useState([]);
  const [errors, setErrors] = useState(false);
  const validateFilesUrl = "/api/digital_items/files/check/many/";
  const downloadUrl = "/api/digital_items/files/download/many/";

  useEffect(() => {
    if (downloadList && downloadModalOpen) {
      setIsLoading(true);
      const params = {};

      downloadList.forEach((item, idx) => (params[`ids[${idx}]`] = item));

      axiosSession
        .get(validateFilesUrl, {
          params
        })
        .then(({ data }) => {
          setAvailableList(
            downloadList.filter(x => data.includes(parseInt(x)))
          );
        })
        .catch(e => console.error(e))
        .finally(() => setIsLoading(false));
    }
  }, [downloadModalOpen]);

  useEffect(() => {
    setAvailableList([]);
    setErrors(false);
  }, [downloadModalOpen]);

  const onTypeChange = item => {
    setUsedType(item.value);
    return item;
  };

  const download = () => {
    setIsLoading(true);
    const params = {};
    downloadList.forEach((item, idx) => (params[`ids[${idx}]`] = item));
    axiosSession
      .get(downloadUrl, {
        params: { plugin_id: pluginId, file_type: usedType, ...params },
        responseType: "blob"
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${filename}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setDownloadModalOpen(false);
      })
      .catch(({ response: { data } }) => {
        data.text().then(text => setErrors(JSON.parse(text)));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      className={"download_files"}
      open={downloadModalOpen}
      onClose={() => {
        setDownloadModalOpen(false);
      }}
      title={`${t("cms.plugins.results.downloadModal.downloading")} ${
        downloadList.length
      }
          ${t(
            `cms.plugins.results.downloadModal.file${
              downloadList.length > 1 ? "s" : ""
            }`
          )}`}
      content={
        <>
          {isLoading ? (
            <p>{t("cms.plugins.results.loading")}</p>
          ) : errors ? (
            <div className="file-list__file-list-container">
              <p className="GenericModal__Body__Form__Text">
                {t("cms.plugins.results.downloadModal.errorDownloading")}
              </p>
              <p className="GenericModal__Body__Form__Text">
                {errors?.files?.join("; ")}
                {errors["files_too_big_limit="] &&
                  `${t("cms.plugins.results.downloadModal.exceedsSize")}: ${
                    errors["files_too_big_limit="]
                  } B`}
              </p>
            </div>
          ) : availableList.length === downloadList.length ? (
            <>
              <div className="filetype-container">
                <p className="GenericModal__Body__Form__Text">
                  {t("cms.plugins.results.downloadModal.choseType")}
                </p>
                <div className="form-group">
                  <ResizableSelect
                    options={filetypeOptions}
                    name="file_type"
                    handleSelectChange={onTypeChange}
                    isSearchable={false}
                    isClearable={false}
                    defaultValue={filetypeOptions[0]}
                    onChange={item => onTypeChange(item)}
                    classNamePrefix="cms-select"
                  />
                </div>
              </div>
            </>
          ) : (
            t("cms.plugins.results.downloadModal.notAuthenticated")
          )}
        </>
      }
      buttons={
        <div className="advanced-search__buttons">
          <button
            className="btn btn--text"
            onClick={() => {
              setDownloadModalOpen(false);
            }}
          >
            {t("cms.plugins.results.cancel")}
          </button>
          <button className="btn" onClick={download}>
            {t("cms.plugins.results.downloadModal.download")}
          </button>
        </div>
      }
    />
    /* <div className="GenericModal__Header--with-border">
        <p className="GenericModal__Header__Title">
          {t("cms.plugins.results.downloadModal.downloading")}{" "}
          {downloadList.length}{" "}
          {t(
            `cms.plugins.results.downloadModal.file${
              downloadList.length > 1 ? "s" : ""
            }`
          )}
        </p>
      </div>
      <div className="GenericModal__Body">
        <div className="columns is-centered is-variable is-10">
          {isLoading ? (
            <p className="GenericModal__Header__Title">
              {t("cms.plugins.results.loading")}
            </p>
          ) : (
            <>
              {errors ? (
                <div className="file-list__file-list-container">
                  <p className="GenericModal__Body__Form__Text">
                    {t("cms.plugins.results.downloadModal.errorDownloading")}
                  </p>
                  <p className="GenericModal__Body__Form__Text">
                    {errors?.files?.join("; ")}
                    {errors["files_too_big_limit="] &&
                      `${t("cms.plugins.results.downloadModal.exceedsSize")}: ${
                        errors["files_too_big_limit="]
                      } B`}
                  </p>
                  <div className="GenericModal__Body__Form__Buttons-Layout">
                    <button
                      className={`button GenericModal__Footer__Button download-files__download-button button--is-frontoffice`}
                      onClick={() => {
                        setDownloadModalOpen(false);
                      }}
                    >
                      {t("cms.plugins.results.downloadModal.close")}
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  {availableList.length === downloadList.length ? (
                    <>
                      <div className="download-files__filetype-container">
                        <p className="GenericModal__Body__Form__Text">
                          {t("cms.plugins.results.downloadModal.choseType")}
                        </p>
                        <div className="download-files__select-container">
                          <div className="download-files__select-container__select">
                            <ResizableSelect
                              options={filetypeOptions}
                              name="file_type"
                              handleSelectChange={onTypeChange}
                              isSearchable={false}
                              isClearable={false}
                              defaultValue={filetypeOptions[0]}
                              onChange={item => onTypeChange(item)}
                              classNamePrefix="react-select"
                            />
                          </div>
                        </div>
                        <div className="GenericModal__Body__Form__Buttons-Layout">
                          <button
                            className={`button GenericModal__Footer__Button download-files__download-button button--is-frontoffice`}
                            onClick={() => {
                              setDownloadModalOpen(false);
                            }}
                          >
                            {t("cms.plugins.results.cancel")}
                          </button>
                          <button
                            className={`button GenericModal__Footer__Button button--is-frontoffice download-files__download-button ${isLoading &&
                              "is-loading"}`}
                            onClick={download}
                          >
                            {t("cms.plugins.results.downloadModal.download")}
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="download-files__file-list-container">
                        {availableList.length === 0 ? (
                          <>
                            <p className="GenericModal__Body__Form__Text">
                              {userId ?
                              t(
                                "cms.plugins.results.downloadModal.noAvalaible"
                              ) : t(
                                "cms.plugins.results.downloadModal.notAuthenticated"
                              )}
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="GenericModal__Body__Form__Text">
                              {t(
                                "cms.plugins.results.downloadModal.unavalailbe"
                              )}
                            </p>
                            <div className="download-files__files-list">
                              <ul>
                                {downloadList
                                  .filter(x => !availableList.includes(x))
                                  .map(x => {
                                    return (
                                      <li key={x}>
                                        &#9675;{" "}
                                        {
                                          items.find(item => item.id === x)
                                            .title
                                        }
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                            <p className="GenericModal__Body__Form__Text">
                              {t(
                                "cms.plugins.results.downloadModal.downloadRest"
                              )}
                            </p>
                          </>
                        )}
                        <div className="GenericModal__Body__Form__Buttons-Layout">
                          {availableList.length !== 0 && (
                            <button
                              className={`button GenericModal__Footer__Button button--is-frontoffice`}
                              onClick={() => {
                                setDownloadModalOpen(false);
                              }}
                            >
                              {t("cms.plugins.results.cancel")}
                            </button>
                          )}
                          <button
                            className={`button GenericModal__Footer__Button button--is-frontoffice `}
                            onClick={() =>
                              availableList.length === 0
                                ? setDownloadModalOpen(false)
                                : setDownloadList(availableList)
                            }
                          >
                            {availableList.length === 0
                              ? t("cms.plugins.results.cancel")
                              : t("cms.plugins.results.downloadModal.goNext")}
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div> */
    /* </Modal> */
  );
};

export default DownloadModal;
