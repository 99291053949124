import React, { useEffect, useState } from "react";
import ToggleSection from "@app/plugins/components/assets/ToggleSection/ToggleSection";
import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import TextRow from "../../components/assets/Typography/TextRow";

const NukatViaf = ({ config }) => {
  const { id, component_name, default_visible } = config;

  const { t } = useTranslation();
  const [showSection, setShowSection] = useState(default_visible);
  const [viafAndNucatInfo, setViafAndNucatInfo] = useState({});
  const [shouldRefresh, setShouldRefresh] = useState(true);

  const url = `/api/digital_items/${id}/nukat_viaf/`;

  useEffect(() => {
    if (id && showSection && shouldRefresh) {
      axiosSession
        .get(url)
        .then(res => {
          setViafAndNucatInfo(res.data);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(setShouldRefresh(false));
    }
  }, [id, showSection]);

  return (
    <div className="plugin-nukat-viaf">
      {id && (
        <ToggleSection
          title={component_name}
          showSection={showSection}
          setShowSection={setShowSection}
        />
      )}

      {showSection && !isEmpty(viafAndNucatInfo) && (
        <div>
          <TextRow
            title="NUKAT"
            link={viafAndNucatInfo?.nukat}
            containLine
            content={
              viafAndNucatInfo?.nukat
                ? t("cms.plugins.nukatViaf.nukatItem")
                : t("cms.plugins.nukatViaf.noItemsNukat")
            }
          />
          <TextRow
            title="VIAF"
            isLink={!!viafAndNucatInfo.viaf}
            content={
              !isEmpty(viafAndNucatInfo.viaf)
                ? viafAndNucatInfo.viaf.map(item => (
                    <a
                      href={item.link}
                      target="_blank"
                      key={item.link}
                      className="plugin-nukat-viaf__attribute_value"
                      title={item.author}
                    >
                      {item.author}
                    </a>
                  ))
                : t("cms.plugins.nukatViaf.noItemsViaf")
            }
          />
        </div>
      )}
    </div>
  );
};

export default NukatViaf;
