import ConfirmEmail from "@app/pages/ConfirmEmail/ConfirmEmail";
import ForgotPassword from "@app/pages/ForgotPassword/ForgotPassword";
import ForgotUsername from "@app/pages/ForgotUsername/ForgotUsername";
import Login from "@app/pages/Login/Login";
import React from "react";
import Register from "@app/pages/Register/Register";
import RegisterEmail from "@app/pages/Register/RegisterEmail";
import SetPassword from "@app/pages/SetPassword/SetPassword";
import ShowUnacceptedRules from "@app/pages/Rules/ShowUnacceptedRules";
import ActivationLinkRepeat from "@app/pages/ActivationLinkRepeat/ActivationLinkRepeat";

window.sessionStorage.clear();

const AppCms = ({
  component,
  messages,
  captcha,
  confirmation,
  uid,
  token,
  username,
  previous_url,
  unaccepted_terms,
  unaccepted_agreements
}) => {
  if (unaccepted_terms) {
    return <ShowUnacceptedRules type="terms" />;
  }
  if (unaccepted_agreements) {
    return <ShowUnacceptedRules type="agreements" />;
  }
  switch (component) {
    case "Login":
      return <Login message={messages} previous_url={previous_url} />;
    case "Register":
      return <Register captcha={captcha} />;
    case "RegisterEmail":
      return <RegisterEmail message={messages} />;
    case "ConfirmEmail":
      return <ConfirmEmail confirmation={confirmation} />;
    case "SetPassword":
      return <SetPassword uid={uid} token={token} username={username} />;
    case "ForgotUsername":
      return <ForgotUsername captcha={captcha} />;
    case "ForgotPassword":
      return <ForgotPassword captcha={captcha} />;
    case "ActivationLinkRepeat":
      return <ActivationLinkRepeat captcha={captcha} />;
    default:
      return null;
  }
};

export default AppCms;
