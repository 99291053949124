import React, { useState } from "react";

import ArrowButton from "../assets/Button/ArrowButton";
import GenericSelect from "@app/components/Select/GenericSelect";
import Modal from "../assets/Modal/Modal";
import ToggleSection from "@app/plugins/components/assets/ToggleSection/ToggleSection";
import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";

const DownloadFiles = ({ config }) => {
  const { id, file_types, is_public } = config;
  const { t } = useTranslation();
  const { notifyError } = useGenericToastify();
  const { control, errors, watch } = useForm();
  const [showSection, setShowSection] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoaderModalOpen, setIsLoaderModalOpen] = useState(false);
  const [pages, setPages] = useState(null);
  const [singlePageId, setSinglePageId] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [errorOccured, setErrorOccured] = useState(false);
  const watchFields = watch();

  const availablePagesUrl = (type) => `/api/digital_items/${id}/${type}/pages/`;
  const downloadUrl = (pageId) =>
    `/api/digital_items/${pageId}/files/download/`;

  const onChange = (item) => {
    setSinglePageId(item[0].id);
  };

  const downloadFile = (downloadType, fileType) => {
    const windowLocationUrl = `${downloadUrl(id)}?id=${
      downloadType === "single"
        ? singlePageId
        : `all&file_type=${fileType ? fileType : selectedFileType}`
    }`;

    setModalIsOpen(false);
    setIsLoaderModalOpen(true);

    axiosSession
      .get(windowLocationUrl, { responseType: "blob" })
      .then((response) => {
        const fileName = `digitalItem_${id}`;

        const blobUrl = URL.createObjectURL(
          new File([response.data], fileName, { type: response.data?.type })
        );

        const el = document.createElement("a");
        el.style.display = "none";
        el.setAttribute("href", blobUrl);
        el.setAttribute("target", "_blank");
        el.setAttribute("download", fileName);
        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);
        setIsLoaderModalOpen(false);
      })
      .catch((err) => {
        setErrorOccured(true);
        console.error(err);
      });
  };

  const selectFileType = (type) => {
    setSelectedFileType(type);
    axiosSession
      .get(availablePagesUrl(type))
      .then(({ data }) => {
        if (isEmpty(data.results)) {
          notifyError(t("cms.plugins.downloadFiles.noFiles"));
        } else {
          data.results.forEach((item) => {
            item.name = item.page_number;
          });
          if (type === 1 || type === 4 || type === 6) {
            downloadFile("all", type);
          } else {
            setSinglePageId(data.results[0].id);
            setPages(data.results);
            setModalIsOpen(true);
          }
        }
      })
      .catch((err) => console.error(err));
  };

  const onClose = () => {
    setModalIsOpen(false);
  };

  const onLoadingModalClose = () => {
    setIsLoaderModalOpen(false);
    setErrorOccured(false)
  }

  return (
    <>
      {is_public && (
        <>
          <div className="download-file">
            {id && (
              <ToggleSection
                title={t("cms.plugins.downloadFiles.title")}
                showSection={showSection}
                setShowSection={setShowSection}
              />
            )}
            {showSection && (
              <div className="download-file__content">
                {file_types.map((item) =>
                  Object.entries(item).map(([name, id]) => {
                    return (
                      <ArrowButton key={id} onClick={() => selectFileType(id)}>
                        {name}
                      </ArrowButton>
                    );
                  })
                )}
              </div>
            )}
          </div>
          <Modal
            open={modalIsOpen}
            onClose={onClose}
            title={t("cms.plugins.downloadFiles.fileDownload")}
            className="download-file"
            content={
              <div className="grid download-file__modal">
                <div className="col-xs-12 download-file__modal__subtitle">
                  <span>{t("cms.plugins.downloadFiles.downloadType")}</span>
                </div>
                <div className="col-xs-6">
                  {pages && (
                    <GenericSelect
                      className="download-file__modal__select cms-select"
                      classNamePrefix="cms-select"
                      options={pages}
                      name="page_number"
                      label={t("cms.plugins.downloadFiles.selectPageNumber")}
                      defaultValue={pages[0]}
                      handleSelectChange={onChange}
                      control={control}
                      errors={errors}
                      isSearchable={false}
                      menuPosition={"fixed"}
                      noOptionsMessage={() => t("app.select")}
                      alt={`${t(
                        "cms.plugins.downloadFiles.selectPageNumber"
                      )}, ${watchFields.page_number?.name ?? ""}, ${t(
                        "app.field_start"
                      )} ${pages.length} ${t("app.field_end")}`}
                    />
                  )}
                  <button
                    className="btn"
                    onClick={() => downloadFile("single")}
                    aria-label={t(
                      "cms.plugins.downloadFiles.downloadSinglePage"
                    )}
                  >
                    {t("cms.plugins.downloadFiles.singlePage")}
                  </button>
                </div>
                <div className="col-xs-6 download-file__modal__all-files">
                  <button
                    className="btn"
                    onClick={() => downloadFile("all")}
                    aria-label={t("cms.plugins.downloadFiles.downloadAllPages")}
                  >
                    {t("cms.plugins.downloadFiles.allPages")}
                  </button>
                </div>
              </div>
            }
            buttons={
              <>
                <button
                  className="btn btn--text"
                  onClick={onClose}
                  aria-label={t("cms.plugins.downloadFiles.cancel")}
                >
                  {t("cms.plugins.downloadFiles.cancel")}
                </button>
              </>
            }
          />

          <Modal
            open={isLoaderModalOpen}
            onClose={onLoadingModalClose}
            title={t("cms.plugins.downloadFiles.fileDownload")}
            className="download-file"
            content={
              <div className="grid download-file__modal">
                <div className="col-xs-12 text-center">
                  {errorOccured ? (
                    <p className="download-file__modal__subtitle">
                      {t("cms.plugins.downloadFiles.downloadError")}
                    </p>
                  ) : (
                    <>
                      <ClipLoader size={90} color={"black"} loading={true} />
                      <br />
                      <p className="download-file__modal__subtitle">
                        {t("cms.plugins.downloadFiles.downloadInProgress")}
                      </p>
                    </>
                  )}
                </div>
              </div>
            }
          />
        </>
      )}
    </>
  );
};

export default DownloadFiles;
