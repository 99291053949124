import React, { useState } from "react";

import PropTypes from "prop-types";
import RedirectModal from "./RedirectModal";
import isEmpty from "lodash/isEmpty";
import useGenericToastify from "@app/hooks/useGenericToastify";
import useWindowWidth from "@app/hooks/useWindowResize";
import { useTranslation } from "react-i18next";

const ListViewItem = ({
  item,
  previewUrl,
  noImage,
  favorite,
  handleFavorite
}) => {
  const { t } = useTranslation();
  const mobileBreakpoint = 768;
  const isMobile = useWindowWidth() < mobileBreakpoint;

  return (
    <>
      <li
        className={`card ${
          favorite ? "favorite col-xs-5" : "col-xs-6"
        } col-md-3`}
      >
        <a
          href={`${!item.mbc ? item.link : previewUrl}${
            item?.annotation_id !== ""
              ? `&annotation_id=${item.annotation_id}`
              : ""
          }`}
          target="_blank"
          tabIndex={-1}
        >
          <picture className="card__cover">
            <img
              src={item.thumbnail ? item.thumbnail : noImage}
              alt={t("cms.plugins.search.objectThumbnail")}
            />
          </picture>
        </a>
        {favorite && !isMobile && (
          <div
            onClick={() => handleFavorite(item.identifier, item?.is_liked)}
            role="button"
            className="heart"
          >
            <i
              className={`${
                item?.is_liked ? "fas" : "far"
              } fa-heart plugin-digital-item-rating-display__heart__icon`}
            />
          </div>
        )}
      </li>
      <li className="card col-xs-6 col-md-9">
        <div className="card__content">
          {!item.mbc && <p className="card__date">{item.catalog}</p>}

          <a
            href={!item.mbc ? item.link : previewUrl}
            target="_blank"
            className="card__title"
          >
            {item.title}
          </a>
          <p className="card__description">{item.authors}</p>
          <div className="search__info">
            {!isEmpty(item.marc21_values["publisher"]) ? (
              <p>
                <span>{t("cms.plugins.results.labels.publisher")}: </span>
                <strong>{item.marc21_values["publisher"]}</strong>
              </p>
            ) : (
              ""
            )}
            {!isEmpty(item.marc21_values["release_date"]) ? (
              <p>
                <span>{t("cms.plugins.results.labels.releaseDate")}: </span>
                <strong>{item.marc21_values["release_date"]}</strong>
              </p>
            ) : (
              ""
            )}
            {!isEmpty(item.marc21_values["publication_place"]) ? (
              <p>
                <span>{t("cms.plugins.results.labels.releasePlace")}: </span>
                <strong>{item.marc21_values["publication_place"]}</strong>
              </p>
            ) : (
              ""
            )}
            {!isEmpty(item.formats) ? (
              <p>
                <span>{t("cms.plugins.results.labels.format")}: </span>
                <strong>{item.formats.join(", ")}</strong>
              </p>
            ) : (
              ""
            )}
            {!isEmpty(item.marc21_values["keywords"]) ? (
              <p>
                <span>{t("cms.plugins.results.labels.keywords")}: </span>
                <strong>{item.marc21_values["keywords"].join("; ")}</strong>
              </p>
            ) : (
              ""
            )}
            {!isEmpty(item.marc21_values["object_type"]) ? (
              <p>
                <span>{t("cms.plugins.results.labels.objectType")}: </span>
                <strong>{item.marc21_values["object_type"]}</strong>
              </p>
            ) : (
              ""
            )}
            {!isEmpty(item.marc21_values["language"]) ? (
              <p>
                <span>{t("cms.plugins.results.labels.language")}: </span>
                <strong>{item.marc21_values["language"]}</strong>
              </p>
            ) : (
              ""
            )}
            {!isEmpty(item.identifier) ? (
              <p>
                <span>{t("cms.plugins.results.labels.identifier")}: </span>
                <strong>{item.identifier}</strong>
              </p>
            ) : (
              ""
            )}
            {!isEmpty(item.text) ? (
              <p>
                <span>{t("cms.plugins.results.labels.ocr")}: </span>
                <strong dangerouslySetInnerHTML={{ __html: item.text }} />
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </li>
      {favorite && isMobile && (
        <li className={`card col-xs-1`}>
          <div
            onClick={() => handleFavorite(item.identifier, item?.is_liked)}
            role="button"
            className="heart"
          >
            <i
              className={`${
                item?.is_liked ? "fas" : "far"
              } fa-heart plugin-digital-item-rating-display__heart__icon`}
            />
          </div>
        </li>
      )}
    </>
  );
};

const ListView = ({
  items,
  isDownloading,
  urls,
  textMaxLength,
  redirectParams,
  favorite,
  handleFavorite
}) => {
  const [resourceData, setResourceData] = useState(false);
  const { notifyError } = useGenericToastify();
  const noCoverImg = "/static/images/no_image.png";
  const onClicked = (item, e = false) => {
    if (!e || e.key === "Enter") {
      setResourceData({
        catalog: item.catalog,
        link: item.link
      });
    }
  };
  return (
    <ul className="grid search__grid">
      {items.map(item => {
        const previewUrl = `${urls.detailViewUrl}${item.slug}?${
          item?.page_number ? `page=${item.page_number}` : ""
        }${redirectParams}`;
        return (
          <ListViewItem
            key={`lv-${item.id}`}
            item={item}
            noImage={noCoverImg}
            previewUrl={previewUrl}
            favorite={favorite}
            handleFavorite={handleFavorite}
          />
        );
      })}
    </ul>
  );
};

ListView.propTypes = {
  items: PropTypes.array.isRequired,
  isDownloading: PropTypes.bool,
  urls: PropTypes.object.isRequired,
  textMaxLength: PropTypes.number.isRequired,
  redirectParams: PropTypes.string,
  favorite: PropTypes.bool,
  handleFavorite: PropTypes.func
};

ListView.defaultProps = {
  isDownloading: false,
  redirectParams: "",
  favorite: false,
  handleFavorite: () => {}
};

export default ListView;
