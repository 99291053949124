import React, { useEffect, useState } from "react";

import NoItems from "@components/NoItems/NoItems";
import PropTypes from "prop-types";
import axiosSession from "@app/config/axiosSession";
import useGenericToastify from "@app/hooks/useGenericToastify";
import useWindowWidth from "@app/hooks/useWindowResize";
import { useTranslation } from "react-i18next";

const Recommended = ({ config, favorite }) => {
  const { t } = useTranslation();
  const { notifyError, notifySuccess } = useGenericToastify();

  const recommendedUrl = "/api/search/frontoffice/proposed/";
  const noImageUrl = "/static/images/no_image.png";
  const favoriteUrl = "/api/digital_items/user/favorite/";
  const createFavouriteEditUrl = id =>
    `/api/digital_items/${id}/user/favorite/`;

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const mobileBreakpoint = 1365;
  const isMobile = useWindowWidth() < mobileBreakpoint;

  const getData = () => {
    axiosSession
      .get(recommendedUrl, { params: { limit: 9 } })
      .then(({ data }) => setItems(origin => [...origin, ...data.items]))
      .catch(err => console.error(err));
  };

  const handleFavorite = (id, isLiked) => {
    // TODO: add account verification, only loged can add/delete favorites objects (add userId to config)
    if (!isLoading) {
      setIsLoading(true);

      setItems(items =>
        items.map(item =>
          item.identifier === id ? { ...item, is_liked: !isLiked } : item
        )
      );

      const service = isLiked
        ? axiosSession.delete(createFavouriteEditUrl(Number(id)))
        : axiosSession.post(favoriteUrl, { digital_item: Number(id) });
      const successMsg = isLiked
        ? t("cms.plugins.favorites.deleteSuccess")
        : t("cms.plugins.favorites.additionSuccess");
      const errorMsg = isLiked
        ? t("cms.plugins.favorites.deleteFail")
        : t("cms.plugins.favorites.additionFail");

      service
        .then(() => {
          getData();
          notifySuccess(successMsg);
        })
        .catch(() => notifyError(errorMsg))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
    setIsLoading(false);
  }, []);

  return isLoading ? (
    <div className="text-center">
      <p>{t("cms.plugins.results.loading")}</p>
    </div>
  ) : items.length === 0 ? (
    <NoItems title={t("cms.plugins.results.no_recommended")} />
  ) : (
    <ul className="grid">
      {items.map(item => (
        <li
          key={item.id}
          className={`card ${favorite ? "favorite" : ""} col-xs-6 col-md-4`}
        >
          <a
            href={`${config.result_url || "/pl/podglad/"}${item.slug}?file_type=2`}
          >
            <picture className="card__cover">
              <img src={item.thumbnail || noImageUrl} alt={item.title} />
            </picture>
          </a>
          <div className="card__content">
            {!item.mbc && <p className="card__date">{item.catalog}</p>}
            {favorite && !isMobile ? (
              <a
                href={`${config.result_url || "/pl/podglad/"}${item.slug}?file_type=2`}
                className="card__title"
              >
                {item.title || item.marc21_values.title}
              </a>
            ) : (
              <div className="flex">
                <a
                  href={`${config.result_url || "/pl/podglad/"}${item.slug}?file_type=2`}
                  className="card__title"
                >
                  {item.title || item.marc21_values.title}
                </a>
                <div
                  onClick={() =>
                    handleFavorite(item.identifier, item?.is_liked)
                  }
                  role="button"
                >
                  <i
                    className={`${
                      item?.is_liked ? "fas" : "far"
                    } fa-heart plugin-digital-item-rating-display__heart__icon`}
                  />
                </div>
              </div>
            )}
            <p className="card__description">
              {item.authors || item.marc21_values.authors}
            </p>
          </div>
          {favorite && (
            <div
              onClick={() => handleFavorite(item.identifier, item?.is_liked)}
              role="button"
              className="heart"
            >
              <i
                className={`${
                  item?.is_liked ? "fas" : "far"
                } fa-heart plugin-digital-item-rating-display__heart__icon`}
              />
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

Recommended.propTypes = {
  config: PropTypes.object.isRequired,
  favorite: PropTypes.bool
};

Recommended.defaultProps = {
  favorite: false
};

export default Recommended;
