const properButtonsColor = isAuthMobile => {
  const fontWithLanguageBtn = document.querySelectorAll(
    ".header__top .wrapper ul li a"
  );

  if (!isAuthMobile) {
    fontWithLanguageBtn.forEach(elem => {
      elem.classList.add("btn--red-focus");
    });
  } else {
    fontWithLanguageBtn.forEach(elem => {
      elem.classList.remove("btn--red-focus");
    });
  }
};

export default properButtonsColor;
