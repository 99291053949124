import React from "react";

import ArrowButton from "../../plugins/components/assets/Button/ArrowButton";
import { useTranslation } from "react-i18next";

const ConfirmEmail = confirmation => {
  const { t } = useTranslation();

  return (
    <section className="section wrapper">
      <nav className="breadcrumbs">
        <ul>
          <li>
            <a
              className="breadcrumbs__frontoffice-link"
              href="/"
              title={t("cms.plugins.confirmEmail.mainPage")}
            >
              {t("cms.plugins.confirmEmail.mainPage")}
            </a>
          </li>

          <li>
            <span>&rarr;</span>
          </li>
          <li>
            <span className="breadcrumbs__current">
              {t("cms.plugins.confirmEmail.confirmation")}
            </span>
          </li>
        </ul>
      </nav>

      <section className="section">
        <div className="login__title-row">
          <h2 className="header--decorated">
            {t("cms.plugins.confirmEmail.confirmation")}
          </h2>
          <ArrowButton href="/login" className="login__title-row__register">
            {t("cms.plugins.login.login")}
          </ArrowButton>
        </div>
        <div className="login__container">
          {confirmation.confirm != "None" ? (
            <p>{t("cms.plugins.confirmEmail.activeAccount")}</p>
          ) : (
            <p> {t("cms.plugins.confirmEmail.linkExpired")}</p>
          )}

          <ArrowButton href="/login" className="login__links__register">
            {t("cms.plugins.login.login")}
          </ArrowButton>
        </div>
      </section>
    </section>
  );
};

export default ConfirmEmail;
