import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosSession from "@app/config/axiosSession";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";
import vmsg from "vmsg";

const SpeechRecognition = ({ setInputValue, recording_limit }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timeoutObject, setTimeoutObject] = useState(null);
  const [timeLeft, setTimeLeft] = useState(recording_limit);
  const [blob, setBlob] = useState(null);

  const { notifyError } = useGenericToastify();
  const { t } = useTranslation();

  const [recorder, setRecorder] = useState(
    new vmsg.Recorder({
      wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm"
    })
  );

  const speechAPIUrl = "/api/digital_items/speech_to_text/";

  useEffect(() => {
    if (isRecording) {
      const tempTimeout = setTimeout(async () => {
        setIsRecording(false);
        const tempblob = await recorder.stopRecording();
        setBlob(tempblob);
      }, recording_limit * 1000);
      setTimeoutObject(tempTimeout);
    } else {
      clearTimeout(timeoutObject);
    }
  }, [isRecording]);

  useEffect(() => {
    let interval = null;
    if (isRecording) {
      interval = setInterval(() => {
        setTimeLeft(timeLeft => timeLeft - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setTimeLeft(recording_limit);
    }
    return () => clearInterval(interval);
  }, [isRecording, timeLeft]);

  const record = async () => {
    if (isRecording) {
      setIsRecording(false);
      const tempblob = await recorder.stopRecording();
      setBlob(tempblob);
    } else if (!isLoading) {
      try {
        await recorder.initAudio();
        await recorder.initWorker();
        recorder.startRecording();
        setIsRecording(true);
      } catch (e) {
        console.error(e);
        setIsRecording(false);
      }
    }
  };

  useEffect(() => {
    if (!isRecording && blob !== null) {
      setIsLoading(true);
      const form = new FormData();
      form.append("file", blob, "record.mp3");
      axiosSession({
        method: "POST",
        url: speechAPIUrl,
        config: { headers: { "content-type": "multipart/form-data" } },
        data: form
      })
        .then(({ data }) => {
          setInputValue(data);
        })
        .catch(error => {
          if (error.response.data.messages) {
            notifyError(error.response.data.messages);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
      setBlob(null);
    }
  }, [isRecording, blob]);

  return (
    <>
      <div
        className={`icon-container ${isRecording ? "is-recording" : ""}`}
        onClick={record}
        onKeyPress={record}
        role="button"
        tabIndex="0"
        aria-label={t("cms.plugins.search.voiceSearch")}
      >
        {isRecording ? (
          <FontAwesomeIcon icon="stop-circle" />
        ) : (
          <FontAwesomeIcon icon="microphone" />
        )}
      </div>
      <div className="simple-search__input-container__info">
        {isRecording && (
          <span>
            {t("cms.plugins.search.speechToText.info")}
            {timeLeft}s
          </span>
        )}
        {isLoading && (
          <span>{t("cms.plugins.search.speechToText.processing")}</span>
        )}
      </div>
    </>
  );
};

export default SpeechRecognition;
