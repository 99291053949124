import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CMSListElem from "./CMSListElem";

import { Pagination } from "antd";

const CMSList = ({ data, scheme }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [currentData, setCurrentData] = useState(
    data.slice(page - 1, pageSize)
  );

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const DataPreview = () => {
    return (
      <>
        {currentData.map(elem => (
          <CMSListElem data={elem} scheme={scheme} />
        ))}
      </>
    );
  };

  useEffect(() => {
    const fromData = (page - 1) * pageSize;
    const toData = fromData + pageSize;
    setCurrentData(data.slice(fromData, toData));
  }, [data, page, pageSize]);

  return (
    <div className="list-group groupUp">
      <DataPreview />
      <Pagination
        className="pagination"
        defaultCurrent={1}
        onChange={handlePageChange}
        total={data.length}
        pageSize={5}
      />
    </div>
  );
};

CMSList.propTypes = {
  data: PropTypes.array.isRequired,
  scheme: PropTypes.array.isRequired
};

export default CMSList;
