import { ConfigProvider, Table } from "antd";
import React, { useEffect, useState } from "react";

import Input from "@app/components/Input/CMSInput";
import NoItems from "@app/components/NoItems/NoItems";
import CMSList from "@app/components/List/CMSList";
import PropTypes from "prop-types";
import { SearchOutlined } from "@ant-design/icons";
import axiosSession from "@app/config/axiosSession";
import { useTranslation } from "react-i18next";
import useWindowWidth from "@app/hooks/useWindowResize";
import Box from "@material-ui/core/Box";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const CMSTable = ({
  columns,
  dataUrl,
  listView,
  mobileBreakpoint,
  shouldTableRefresh,
  extraParams,
  noItemsProps,
  setUpperView
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState("");
  const [view, setView] = useState("table");
  const width = useWindowWidth();
  const { t } = useTranslation();

  const handleTableChange = (pagination, _, sorter) => {
    setPage(pagination.pages_number);
    if (sorter?.order ?? false) {
      setOrdering(
        `${sorter.order === "descend" ? "-" : ""}${sorter.columnKey}`
      );
    }
    fetchData({
      ordering:
        sorter?.order ?? false
          ? `${sorter.order === "descend" ? "-" : ""}${sorter.columnKey}`
          : "",
      page: pagination.pages_number,
      search_field: searchValue
    });
  };

  const fetchData = async (params = {}) => {
    setLoading(true);
    const mergeParams = { ...params, ...extraParams };
    await axiosSession
      .get(dataUrl, { params: { ...mergeParams } })
      .then(res => {
        setData(res.data.results);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();

    // Add ability to focus and click sorter header with keyboard
    document.querySelectorAll(".ant-table-column-has-sorters").forEach(el => {
      el.setAttribute("tabindex", "0");
      el.addEventListener("keydown", e => {
        if (e.code === "Enter") {
          el.click();
        }
      });
    });
  }, []);

  const handleFetchData = () => {
    fetchData({ page: page, ordering: ordering, search_field: searchValue });
  };

  const handleView = (e, newValue) => {
    if (newValue != null) {
      setView(newValue);
      setUpperView(newValue);
    }
  };

  useEffect(() => {
    if (shouldTableRefresh) {
      handleFetchData();
    }
  }, [shouldTableRefresh]);

  useEffect(() => {
    const carets = document.querySelectorAll(
      "span.anticon-caret-up, span.anticon-caret-down"
    );

    carets.forEach(caret => {
      caret.ariaLabel = caret.className.includes("anticon-caret-up")
        ? t("app.table.asc")
        : t("app.table.desc");
    });
  }, []);

  return (
    <>
      {listView && width <= mobileBreakpoint && (
        <Box
          sx={{ display: { xs: "block", md: "none" } }}
          className="btn-group btn-group--center marginBottom"
        >
          <ToggleButtonGroup value={view} exclusive onChange={handleView}>
            <ToggleButton value="table">{t("app.tableView")}</ToggleButton>
            <ToggleButton value="list">{t("app.listView")}</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
      <div className="btn-group btn-group--no-space">
        <Input
          name="search"
          placeholder={t("app.table.search.placeholder")}
          ariaLabel={`${t("app.table.search.button")}, ${t("app.textbox")}`}
          className={`${width > mobileBreakpoint ? "w-30" : "w-100"}`}
          value={searchValue}
          label=""
          onChange={e => setSearchValue(e.target.value)}
          onKeyDown={e => e.key === "Enter" && handleFetchData()}
        />
        <button
          type="submit"
          className="btn btn--text btn--with-border"
          onClick={handleFetchData}
          aria-label={t("app.table.search.altButton")}
        >
          <SearchOutlined className="anticon-dark" />
        </button>
      </div>
      {view === "list" ? (
        data.length > 0 ? (
          <CMSList data={data} scheme={columns} />
        ) : (
          <NoItems {...noItemsProps} />
        )
      ) : (
        <ConfigProvider renderEmpty={() => <NoItems {...noItemsProps} />}>
          <Table
            columns={columns}
            dataSource={data}
            showSorterTooltip={false}
            pagination={{ defaultCurrent: 1 }}
            onChange={handleTableChange}
            loading={loading}
            scroll={{ x: 1024, y: null }}
          />
        </ConfigProvider>
      )}
    </>
  );
};

CMSTable.propTypes = {
  columns: PropTypes.array,
  dataUrl: PropTypes.string,
  listView: PropTypes.bool,
  mobileBreakpoint: PropTypes.number,
  shouldTableRefresh: PropTypes.bool,
  extraParams: PropTypes.object,
  noItemsProps: PropTypes.object,
  setUpperView: PropTypes.func
};

CMSTable.defaultProps = {
  columns: [],
  dataUrl: "",
  listView: false,
  mobileBreakpoint: 768,
  shouldTableRefresh: false,
  extraParams: {},
  noItemsProps: {},
  setUpperView: () => {}
};

export default CMSTable;
