import "./Input.module.scss";

import { Controller, ErrorMessage } from "react-hook-form";
import React, { useState } from "react";

import DictionariesCheckExternalValue from "@app/pages/Dictionaries/DictionariesCheckExternalValue";
import { Drawer } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";

const GenericInput = ({
  tag: Tag,
  type,
  control,
  name,
  label,
  displayNoneLabel,
  required,
  disabled,
  darkTheme,
  onKeyPress,
  placeholder,
  onChange,
  value,
  defaultValue,
  errors,
  onBlur,
  drawer,
  dictionary_id,
  labelDrawer,
  showLabel,
  autoSuggest,
  autoSuggestKeyboardHandle,
  onFocus
}) => {
  const inputStyle = Tag === "textarea" ? "textarea" : "input";
  const whiteText = darkTheme ? "mbc-input__label" : "";
  const isMultiError = false;

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };
  return (
    <div className="field">
      {drawer && (
        <Drawer
          width={400}
          placement="right"
          closable={true}
          onClose={handleClose}
          visible={visible}
          mask={false}
        >
          <DictionariesCheckExternalValue dictID={dictionary_id} />
        </Drawer>
      )}
      {showLabel && labelDrawer && (
        <div className="label-with-drawer">
          <label
            htmlFor={name}
            className={`label ${whiteText} ${displayNoneLabel &&
              "simple-search__hidden-label"}`}
          >
            {label}
          </label>
          {drawer && (
            <div
              className="label-with-drawer__button"
              onClick={showDrawer}
              onKeyPress={showDrawer}
              role="button"
              tabIndex="0"
              title="Wyszukaj w deskryptorze"
            >
              <FontAwesomeIcon icon={faSearch} />
            </div>
          )}
        </div>
      )}
      <div className="control mbc-input">
        <Controller
          as={<Tag type={type} onBlur={onBlur} onFocus={onFocus} />}
          control={control}
          className={`${inputStyle} ${
            !isEmpty(errors) && has(errors, name) ? "is-danger" : ""
          }`}
          name={name}
          id={name}
          rows={7}
          disabled={disabled}
          rules={required}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          onBlur={onBlur}
          onKeyDown={e => autoSuggestKeyboardHandle(e)}
        />
        {autoSuggest}
      </div>
      {!isEmpty(errors) && has(errors, name) && isMultiError ? (
        errors[name].message.map((message, index) => (
          <p key={index} className="help is-danger">
            {message}
          </p>
        ))
      ) : !isEmpty(errors) && has(errors, name) && !isMultiError ? (
        <ErrorMessage
          as={<p className="help is-danger" />}
          errors={errors}
          name={name}
        />
      ) : null}
    </div>
  );
};

GenericInput.propTypes = {
  tag: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  displayNoneLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  darkTheme: PropTypes.bool,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  drawer: PropTypes.bool,
  dictionary_id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelDrawer: PropTypes.bool,
  showLabel: PropTypes.bool
};

GenericInput.defaultProps = {
  tag: "input",
  type: "text",
  errors: {},
  defaultValue: "",
  drawer: false,
  displayNoneLabel: false,
  labelDrawer: true,
  showLabel: true,
  autoSuggestKeyboardHandle: () => {}
};

export default GenericInput;
