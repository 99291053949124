import React from "react";
import handleSkiplinkClick from "@app/helpers/handleSkiplinkClick";

const PluginSkiplinks = ({ config }) => {
  return (
    <div className="skiplinks">
      {Object.entries(config.menu_options).map(([key, value]) => (
        <button
          key={key}
          className="skiplink btn"
          onClick={() => handleSkiplinkClick(value)}
        >
          {key}
        </button>
      ))}
    </div>
  );
};

export default PluginSkiplinks;
