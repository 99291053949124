import React from "react";
import Timeline from "@components/Timeline/Timeline";
import PropTypes from "prop-types";

const TimelineView = ({ items, urls, favorite, handleFavorite }) => {
  return (
    <div className="timeline">
      {items.map(item => (
        <Timeline
          key={item.id}
          item={item}
          detailUrl={urls.detailViewUrl}
          favorite={favorite}
          handleFavorite={handleFavorite}
        />
      ))}
    </div>
  );
};

TimelineView.propTypes = {
  items: PropTypes.array.isRequired,
  urls: PropTypes.object.isRequired,
  favorite: PropTypes.bool,
  handleFavorite: PropTypes.func
};

TimelineView.defaultProps = {
  favorite: false,
  handleFavorite: () => {}
};

export default TimelineView;
