import { ErrorMessage } from "react-hook-form";
import PropTypes from "prop-types";
import React from "react";

const CMSCheckbox = ({
  name,
  value,
  register,
  children,
  onChange,
  checked,
  disabled,
  errors
}) => {
  return (
    <label>
      <input
        type="checkbox"
        name={name}
        value={value}
        ref={register}
        onChange={onChange}
        defaultChecked={checked}
        disabled={disabled}
      />
      <span className="checkbox"></span>
      <span>{children}</span>
      <ErrorMessage
        as={<p className="is-error-text" />}
        errors={errors}
        name={name}
      />
    </label>
  );
};

CMSCheckbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  register: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  darkTheme: PropTypes.bool
};

CMSCheckbox.defaultProps = {
  errors: {},
  disabled: false,
  darkTheme: true
};

export default CMSCheckbox;
