import React, { useEffect, useState } from "react";

import TextRow from "../../components/assets/Typography/TextRow";
import ToggleSection from "@app/plugins/components/assets/ToggleSection/ToggleSection";
import axiosSession from "@app/config/axiosSession";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";

const Details = ({ config }) => {
  const {
    id,
    component_name,
    results_url,
    default_visible,
    digitalItemInfo,
    copyright
  } = config;

  const { t } = useTranslation();
  const [showSection, setShowSection] = useState(default_visible);

  const visitedUrl = "/api/digital_items/visit/";

  useEffect(() => {
    if (id) {
      axiosSession.post(visitedUrl, { digital_item: id }).catch(err => {
        console.error(err);
      });
    }
  }, [id]);

  const generateInnerRows = obj => {
    return obj.map(elem => {
      return Object.entries(elem).map(([key, innerValue]) => {
        if (innerValue !== "") {
          return <TextRow key={key} title={key} content={innerValue} />;
        }
      });
    });
  };

  const generateTextRows = data => {
    return data.map(item =>
      Object.entries(item).map(([name, value]) => {
        if (name === "keywords") {
          return (
            <TextRow
              title={t("cms.plugins.groupObjectPreview.keywords")}
              content={value.map((keyword, idx) => {
                return (
                  <>
                    <a
                      key={`btn-${keyword.value}`}
                      href={`${results_url}?id=${keyword.search_id}&query=${keyword.value}`}
                    >
                      {keyword.value}
                      {value.length - 1 === idx ? "" : ","}
                    </a>
                    &nbsp;
                  </>
                );
              })}
              link={null}
            />
          );
        }

        if (isArray(value)) {
          if (value !== []) {
            return (
              <div key={name.toString()}>
                <p
                  style={{
                    fontSize: "1.125rem",
                    lineHeight: "140%",
                    fontWeight: "bold"
                  }}
                >
                  {name}
                </p>
                <div style={{ marginLeft: "1rem", marginTop: "0.5rem" }}>
                  {generateInnerRows(value)}
                </div>
              </div>
            );
          }
        } else {
          if (value !== "") {
            return <TextRow title={name} content={value} />;
          }
        }
      })
    );
  };

  return (
    <>
      {id && (
        <ToggleSection
          title={component_name}
          showSection={showSection}
          setShowSection={setShowSection}
        />
      )}
      {showSection &&
        !isEmpty(digitalItemInfo) &&
        generateTextRows(digitalItemInfo)}

      {showSection && !isEmpty(copyright) && (
        <div key={copyright.toString()}>
          <TextRow
            title={t("cms.plugins.groupObjectPreview.copyrights")}
            content={copyright}
          />
        </div>
      )}
    </>
  );
};

export default Details;
