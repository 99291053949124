import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";

const LoadMore = ({ setPage, isLastPage, isLoading }) => {
  const { t } = useTranslation();

  return (
    !isLastPage &&
    isLastPage !== null && (
      <button
        className="btn"
        onClick={() => {
          setPage(origin => origin + 1);
        }}
      >
        <span>{t("cms.plugins.results.more")}</span>
        {isLoading ? <LoadingOutlined /> : ""}
      </button>
    )
  );
};
export default LoadMore;
