import PropTypes from "prop-types";
import React from "react";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";

const Input = ({
  ariaLabel,
  tag: Tag,
  type,
  name,
  label,
  register,
  disabled,
  onKeyDown,
  onKeyPress,
  placeholder,
  onChange,
  value,
  defaultValue,
  errors,
  children,
  required,
  className
}) => {
  const isMultiError =
    !isEmpty(errors) && has(errors, name) && Array.isArray(errors[name].message)
      ? true
      : false;
  return (
    <>
      <label htmlFor={name} className="label">
        {label} {required && <span className="label__required">*</span>}
      </label>
      <Tag
        type={type}
        className={
          !isEmpty(errors) && has(errors, name)
            ? `${className} is-error`
            : `${className}`
        }
        name={name}
        id={name}
        disabled={disabled}
        ref={register}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        aria-label={ariaLabel ? ariaLabel : null}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
      />
      {children}
      {!isEmpty(errors) && has(errors, name) && isMultiError ? (
        errors[name].message.map((message, index) => (
          <p key={index} className="is-error-text">
            {message}
          </p>
        ))
      ) : !isEmpty(errors) && has(errors, name) && !isMultiError ? (
        <p className="is-error-text">{errors[name].message}</p>
      ) : null}
    </>
  );
};

Input.propTypes = {
  ariaLabel: PropTypes.string,
  tag: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  required: PropTypes.bool,
  className: PropTypes.string
};

Input.defaultProps = {
  ariaLabel: "",
  tag: "input",
  type: "text",
  errors: {},
  children: "",
  required: false,
  className: ""
};

export default Input;
