import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import axiosSession from "@app/config/axiosSession";
import { useTranslation } from "react-i18next";
import useWindowWidth from "@app/hooks/useWindowResize";
import NoItems from "../NoItems/NoItems";


const CustomSlide = props => {
  const { t } = useTranslation();
  const noImageUrl = "/static/images/no_image.png";

  const { item, detailViewUrl, itemPerSlide } = props;
  return (
    <li
      className={`card col-xs-12 col-sm-6 col-md-${
        itemPerSlide === 6 ? "2" : "3"
      }`}
    >
      <a href={`${detailViewUrl}${item.slug}`}>
        <picture className="card__cover--ratio">
          <img
            src={item.thumbnail || noImageUrl}
            alt={item.thumbnail !== noImageUrl ? (item.title  ? item.title : item.marc21_values.title || t("cms.plugins.search.objectThumbnail")) : t("cms.plugins.search.noThumbnail")}
          />
       </picture>
        <div className="card__content">
          <p className="card__title">
            {item.title ? item.title : item.marc21_values.title}
          </p>
          <p className="card__description">
            {item.authors ? item.authors : item.marc21_values.authors}
          </p>
        </div>
      </a>
    </li>
  );
};

const Slider = ({ config, url, noItemMessage, itemPerSlide }) => {
  const {
    name,
    interval,
    number_of_items,
    detail_view_url: detailViewUrl
  } = config;
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const width = useWindowWidth();
  const fontSize = getComputedStyle(document.documentElement).getPropertyValue(
    "--font-size"
  );
  const paginationSize = width > 1365 ? itemPerSlide : width > 767 ? 2 : 1;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const carouselRef = React.useRef(null);

  const getLanguage = () => {
    return (
      t.language ||
      (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
      "PL"
    );
  };

  const chunkItems = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
      arr.slice(i * size, i * size + size)
    );

  const nextSlide = () => {
    const fontSizeNumber = parseInt(fontSize.replace("px", ""));
    const gapSize = width > 767 ? fontSizeNumber * 1.5 : fontSizeNumber;
    carouselRef.current.scrollLeft += carouselRef.current.offsetWidth + gapSize;
  };
  const prevSlide = () => {
    const fontSizeNumber = parseInt(fontSize.replace("px", ""));
    const gapSize = width > 767 ? fontSizeNumber * 1.5 : fontSizeNumber;
    carouselRef.current.scrollLeft -= carouselRef.current.offsetWidth + gapSize;
  };

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);

    axiosSession
      .get(url, {
        params: {
          limit: number_of_items || 6,
          interval: interval || 30,
          language: getLanguage().toUpperCase()
        }
      })
      .then(({ data }) => {
        setItems(data.items);
      })
      .catch(({ error }) => {
        console.error(error);
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [interval, number_of_items, url]);

  return (
    <div className="carousel" ref={carouselRef}>
      {items.length > 0 && paginationSize > 1 ? (
        <>
          <button
            className="btn btn--text home-section__prev"
            aria-label={`${t("cms.plugins.carousel.leftBtn")} ${name}`}
            onClick={prevSlide}
          >
            <LeftOutlined />
          </button>
          <button
            className="btn btn--text home-section__next"
            aria-label={`${t("cms.plugins.carousel.rigthBtn")} ${name}`}
            onClick={nextSlide}
          >
            <RightOutlined />
          </button>
        </>
      ) : (
        items.length === 0 && <NoItems title={noItemMessage} />
      )}
      {chunkItems(items, paginationSize).map((chunkedItems, index) => (
        <ul className="grid" key={index}>
          {chunkedItems.map(item => (
            <CustomSlide
              key={item.id}
              item={item}
              detailViewUrl={detailViewUrl}
              itemPerSlide={itemPerSlide}
            />
          ))}
        </ul>
      ))}
    </div>
  );
};

Slider.defaultProps = {
  itemPerSlide: 6
};

export default Slider;
