import React, { useEffect, useState } from "react";

import ToggleSection from "@app/plugins/components/assets/ToggleSection/ToggleSection";
import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import TextRow from "../../components/assets/Typography/TextRow";

const MARC21 = ({ config }) => {
  const { t } = useTranslation();
  const [digitalItemInfo, setDigitalItemInfo] = useState({});
  const [showSection, setShowSection] = useState(config.default_visible);
  const [haveFetched, setHaveFetched] = useState(false);
  const url =
    config.lang === "pl"
      ? `/api/digital_items/${config.id}/marc?plugin_id=${config.plugin_id}`
      : `/api/digital_items/${config.id}/marc?language=${config.lang}&plugin_id=${config.plugin_id}`;

  useEffect(() => {
    if (config.id) {
      axiosSession
        .get(url)
        .then(res => {
          setDigitalItemInfo(res.data);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => setHaveFetched(true));
    }
  }, [config.id, config.lang]);

  //TODO poprawić na ładniejsze iterowanie
  const Marc21Attributes = () => {
    return Object.entries(digitalItemInfo).map((item, index) =>
      Object.values(item[1]).map(value => (
        <TextRow
          key={value.tag}
          title={`${value.tag} ${value.subfield_tag}`}
          content={value.value}
        />
      ))
    );
  };

  return (
    <div className="plugin-digital-item-marc21">
      {config.id && (
        <ToggleSection
          title={config.component_name}
          showSection={showSection}
          setShowSection={setShowSection}
        />
      )}

      {showSection && !isEmpty(digitalItemInfo) && Marc21Attributes()}
      {showSection &&
        isEmpty(digitalItemInfo) &&
        haveFetched &&
        t("cms.plugins.marc21.noData")}
    </div>
  );
};

export default MARC21;
