import axiosSession from "@app/config/axiosSession";

const getSchema = id => {
  return axiosSession.get(`/api/directories/${id}/schema/`);
};
const getAttributesValue = id => {
  return axiosSession.get(`/api/directories/${id}/values/`);
};
const getItemSchema = id => {
  return axiosSession.get(`/api/digital_items/${id}/schema/`);
};
const getItemAttributesValue = id => {
  return axiosSession.get(`/api/digital_items/${id}/values/`);
};
const getPermissionsRespond = id => {
  return axiosSession.get(`/api/digital_items/${id}/permission/`);
};
const getFileRespond = id => {
  return axiosSession.get(`/api/digital_items/${id}/files/`);
};
const getDigitalItemInfoRespond = id => {
  return axiosSession.get(`/api/digital_items/${id}/`);
};
const getDict = id => {
  return axiosSession.get(`/api/dicts/${id}/`);
};
const getCollectionsListDigitalItem = id => {
  return axiosSession.get(`/api/digital_items/${id}/collections/`);
};
const getCollectionsListDirectories = id => {
  return axiosSession.get(`/api/directories/${id}/collections/`);
};
const deleteCollectionFromDigitalItem = (collectionID, objectID) => {
  return axiosSession.delete(
    `/api/collections/${collectionID}/object/${objectID}/?type=digital-item`
  );
};
const deleteCollectionFromDirectories = (collectionID, objectID) => {
  return axiosSession.delete(
    `/api/collections/${collectionID}/object/${objectID}/?type=group-object`
  );
};
const getDirectoryPermissionInfo = id => {
  return axiosSession.get(`/api/directories/${id}/permission/`);
};
const getThumbnailsInfo = id => {
  return axiosSession.get(`/api/directories/${id}/thumbnails/`);
};
const getLibraryInfo = id => {
  return axiosSession.get(`/api/library/${id}/`);
};
const getDirectoryInfo = id => {
  return axiosSession.get(`/api/directories/directory/${id}/`);
};
const getCopyrightInfo = () => {
  return axiosSession.get("/api/digital_items/copyright/select-data/");
};
const getSchemaInfo = id => {
  return axiosSession.get(`/api/attributes/schema/${id}/`);
};
const getDictLanguages = () => {
  return axiosSession.get("/api/dicts/languages/select-data/");
};
const getAttributeTypes = () => {
  return axiosSession.get("/api/attributes/types/select-data/");
};
const getAttributeFeatures = () => {
  return axiosSession.get("/api/attributes/features/select-data/");
};
const getAttributes = params => {
  return axiosSession.get("/api/attributes/select-data/", { params: params });
};
const getExternalDictValues = (id, params = {}) => {
  return axiosSession.get(`/api/dicts/${id}/external/`, { params: params });
};
const getDictSourceKeys = dictionarySource => {
  return axiosSession.get(
    `/api/dicts/sources/keys/select-data/?dictionary_source=${dictionarySource}`
  );
};
const deleteCommentReason = (id, removed_reason) => {
  return axiosSession.patch(`/api/digital_items/${id}/user/comments/`, {
    removed_reason
  });
};
const deleteComment = id => {
  return axiosSession.delete(`/api/digital_items/${id}/user/comments/`);
};

const setPageNumbers = (id, fileType) => {
  return axiosSession.post(`/api/digital_items/${id}/set_pages/${fileType}/`);
};

const changeFileType = (id, fileId, formData) => {
  return axiosSession.put(
    `/api/digital_items/${id}/files/${fileId}/`,
    formData
  );
};
const getFileTypes = () => {
  return axiosSession.get(`/api/digital_items/filetypes/select-data/`);
};

const getAttributeInfo = id => {
  return axiosSession.get(`/api/attributes/${id}/`);
};

const getDicts = isInternal => {
  return axiosSession.get("/api/dicts/select-data/", {
    params: { is_internal: isInternal }
  });
};

const getDigitalItem = id => {
  return axiosSession.get(`/api/digital_items/${id}/`)
}

export {
  getSchema,
  getAttributesValue,
  getItemSchema,
  getItemAttributesValue,
  getPermissionsRespond,
  getFileRespond,
  getDigitalItemInfoRespond,
  getDict,
  getCollectionsListDigitalItem,
  deleteCollectionFromDigitalItem,
  getCollectionsListDirectories,
  deleteCollectionFromDirectories,
  getDirectoryPermissionInfo,
  getThumbnailsInfo,
  getLibraryInfo,
  getDirectoryInfo,
  getCopyrightInfo,
  getSchemaInfo,
  getDictLanguages,
  getAttributeTypes,
  getAttributeFeatures,
  getAttributes,
  getExternalDictValues,
  getDictSourceKeys,
  deleteCommentReason,
  deleteComment,
  setPageNumbers,
  changeFileType,
  getFileTypes,
  getAttributeInfo,
  getDicts,
  getDigitalItem
};
