import React, { useEffect, useState } from "react";
import TextRow from "@app/plugins/components/assets/Typography/TextRow";
import Thumbnail from "@app/plugins/components/digital_item_preview/Thumbnail";
import ToggleSection from "@app/plugins/components/assets/ToggleSection/ToggleSection";
import axiosSession from "@app/config/axiosSession";
import handler404 from "@app/plugins/components/assets/Handler404/Handler404";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";

const GroupObjectPreview = ({ config }) => {
  const { id, lang } = config;

  const { t } = useTranslation();
  const [digitalItemInfo, setDigitalItemInfo] = useState({});
  const [copyright, setCopyright] = useState(undefined);
  const [title, setTitle] = useState("");
  const [showSection, setShowSection] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const url =
    lang === "pl"
      ? `/api/directories/${id}/attributes/`
      : `/api/directories/${id}/attributes?language=${lang}/`;

  const titleUrl = `/api/directories/${id}/name/`;

  handler404();

  useEffect(() => {
    if (id) {
      axiosSession
        .get(titleUrl)
        .then(({ data }) => {
          setTitle(data.name);
        })
        .catch(err => console.error(err));
    }
  }, [id, titleUrl]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      axiosSession
        .get(url)
        .then(({ data }) => {
          setDigitalItemInfo(data[lang.toUpperCase()] || []);
          setCopyright(data.copyrights);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => setIsLoading(false));
    }
  }, [id, lang, url]);

  return (
    <>
      {id && (
        <ToggleSection
          title={t("cms.plugins.group_object_preview.objectDetails")}
          showSection={showSection}
          setShowSection={setShowSection}
        />
      )}
      {showSection && (
        <div className="grid group-object-preview-details">
          <div className="col-xs-12 col-md-8 col-lg-9">
            <TextRow
              title={t("cms.plugins.group_object_preview.objectName")}
              content={title}
            />
            {isLoading && <p>{t("cms.plugins.results.loading")}</p>}
            {!isEmpty(digitalItemInfo) &&
              digitalItemInfo.map(item =>
                Object.entries(item).map(([name, value]) => (
                  <TextRow
                    key={name.toString()}
                    title={
                      name === "keywords"
                        ? t("cms.plugins.groupObjectPreview.keywords")
                        : name
                    }
                    content={value}
                  />
                ))
              )}

            {copyright && (
              <div
                key={copyright.toString()}
                className="plugin-group-object-preview__attribute"
              >
                <p className="plugin-group-object-preview__attribute__name">
                  {t("cms.plugins.details.copyrights")}
                </p>
                <p className="plugin-group-object-preview__attribute__value">
                  {copyright}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default GroupObjectPreview;
