import React from "react";

const Header = ({ config: { title, author } }) => {
  return (
    <div className="plugin-digital-item-header">
      <p className="has-text-weight-bold plugin-digital-item-header__title">
        {title}
      </p>
      <p className="plugin-digital-item-header__subtitle">{author}</p>
    </div>
  );
};

export default Header;
