import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { createFocusTrap } from "../../../../helpers/focusTrap";
import { useTranslation } from "react-i18next";

const Modal = ({
  open,
  onClose,
  onCloseVisibility,
  title,
  content,
  buttons,
  clickAwayClose,
  className
}) => {
  const { t } = useTranslation();
  const anchorEl = document.body;
  const ref = useRef(null);
  const [focusTrap, setFocusTrap] = useState(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        clickAwayClose
      ) {
        onClose();
      }
    };

    if (open && !!ref?.current) {
      ref.current.classList.add("active");
      document.addEventListener("mousedown", handleClickOutside);
      const ft = createFocusTrap({
        containerElement: ref.current,
        onEscape: onClose
      });
      ft.trap();
      setFocusTrap(ft);
    } else if (!open && !!ref?.current) {
      ref.current.classList.remove("active");
      document.removeEventListener("mousedown", handleClickOutside);
      focusTrap?.release();
      setFocusTrap(null);
    }
  }, [open, ref]);

  return createPortal(
    <div ref={ref} className={`modal ${className}`}>
      <div className="modal__container">
        <div className="modal__header">
          <h3>{title}</h3>
          {onCloseVisibility && (
            <button
              onClick={onClose}
              className=" btn btn--text modal__header__close-button"
              aria-label={t("cms.plugins.results.downloadModal.close")}
            >
              &#x2715;
            </button>
          )}
        </div>
        <div className="modal__content">{content}</div>
        <div className="modal__buttons">{buttons}</div>
      </div>
    </div>,
    anchorEl
  );
};

export default Modal;

Modal.defaultProps = {
  clickAwayClose: false,
  onCloseVisibility: true,
  className: ""
};
