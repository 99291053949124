import {
  ArrowRightOutlined,
  BookOutlined,
  CloseOutlined
} from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";

const CollectionItem = ({ item, detailViewUrl }) => {
  const { t } = useTranslation();
  const handleExpand = e => {
    const cl = e.currentTarget.parentElement.classList;
    cl.remove("hide-extension");
    setTimeout(() => {
      cl.add("expanded");
    }, 100);
  };

  const handleClose = e => {
    const cl = e.currentTarget.closest(".home-section__collections-element")
      .classList;
    cl.remove("expanded");

    setTimeout(() => {
      cl.add("hide-extension");
    }, 500);
  };

  return (
    <li className="home-section__collections-element hide-extension">
      <picture className="home-section__collections-cover">
        <img src={item.thumbnail} alt={item.name} aria-hidden="true" />
      </picture>
      <span
        aria-label={`${t(
          "cms.plugins.collection_object_list.results_number"
        )} ${item.count}`}
        className="home-section__collections-counter"
      >
        <BookOutlined aria-hidden="true" />
        <span aria-hidden="true">{item.count}</span>
      </span>
      <a href={`${detailViewUrl}${item.slug}`}>
        <h3 className="home-section__collections-title">{item.name}</h3>
      </a>
      <button
        className="home-section__collections-open btn"
        onClick={handleExpand}
        disabled={!item.children.length > 0}
        aria-label={`${t("cms.plugins.recommended_collection.categories")} ${
          item.name
        }`}
      >
        <span>{t("cms.plugins.recommended_collection.categories")}</span>
        <ArrowRightOutlined aria-label={null} />
      </button>
      {item.children.length > 0 ? (
        <div className="home-section__collections-expansion">
          <button
            className="home-section__collections-cancel btn btn--text"
            aria-label={t("cms.plugins.register.close")}
            onClick={handleClose}
          >
            <CloseOutlined />
          </button>
          <ul key={item.children.length}>
            <li key={item.id}>
              <a
                href={`${detailViewUrl}${item.slug}`}
                className="btn btn--text"
                aria-label={t("cms.plugins.recommended_collection.categories")}
              >
                <span>{item.name}</span>
                <ArrowRightOutlined aria-label={null} />
              </a>
            </li>
            {item.children.map(subCollection => (
              <li key={subCollection.id}>
                <a
                  href={`${detailViewUrl}${subCollection.slug}`}
                  aria-label={`${t(
                    "cms.plugins.recommended_collection.categories"
                  )}, ${subCollection.name}`}
                >
                  {subCollection.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ""
      )}
    </li>
  );
};

const RecommendedCollections = ({
  config: {
    recommended_collections: recommendedCollections,
    detail_view_url: detailViewUrl
  }
}) => {
  return (
    <ul className="home-section__collections-container">
      {recommendedCollections.map(item => (
        <CollectionItem
          item={item}
          key={item.id}
          detailViewUrl={detailViewUrl}
        />
      ))}
    </ul>
  );
};

export default RecommendedCollections;
