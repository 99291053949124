import "./ScrollToTop.scss";

import React, { useCallback, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false);
  const { t } = useTranslation();

  const checkShowScroll = useCallback(() => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  }, [showScroll]);

  const handleScroll = () => {
    window.scroll({ top: 0 });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkShowScroll);
    return () => window.removeEventListener("scroll", checkShowScroll);
  }, [checkShowScroll]);

  return (
    showScroll && (
      <FontAwesomeIcon
        title={t("app.top")}
        className="scroll-to-top"
        onClick={handleScroll}
        icon={faArrowCircleUp}
      />
    )
  );
};

export default ScrollToTop;
