import React, { useEffect, useState } from "react";

import axiosSession from "@app/config/axiosSession";

const Thumbnail = ({ config }) => {
  const { id, object_type } = config;
  const [thumbnail, setThumbnail] = useState(null);

  let objectType = "digital_items";
  if (object_type === "group") {
    objectType = "directories";
  } else if (object_type === "collection") {
    objectType = "collections";
  }

  const url = `/api/${objectType}/${id}/thumbnail-view/`;

  useEffect(() => {
    if (id) {
      axiosSession
        .get(url)
        .then(({ data }) => {
          setThumbnail(data);
        })
        .catch(err => console.error(err));
    }
  }, [id]);

  return (
    <picture className="card__cover" key={thumbnail?.url ?? "default"}>
      <img
        src={thumbnail?.url ?? "/static/images/no_image.png"}
        alt={thumbnail?.name ?? "404"}
      ></img>
    </picture>
  );
};

export default Thumbnail;
