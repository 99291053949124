import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import axiosSession from "../../../config/axiosSession";
import debounce from "lodash/debounce";
import useWindowWidth from "@app/hooks/useWindowResize";

const AutosuggestInput = ({
  url,
  inputProps,
  renderSuggestion,
  getSuggestionValue,
  onChange,
  value,
  suggestions,
  setSuggestions
}) => {
  const [cached, setCached] = useState([]);
  const [cacheSuggestion, setCacheSuggestion] = useState({});
  const isMobile = useWindowWidth() < 768;

  const loadSuggestions = value => {
    if (value.trim().length >= 3) {
      axiosSession
        .get(url, { params: { query: value } })
        .then(({ data }) => {
          setSuggestions(data);
          setCacheSuggestion({ value: value, suggestions: data });
        })
        .catch(err => {
          console.error(err);
        });
    }
  };
  const [debounced, setDebounced] = useState(() =>
    debounce(loadSuggestions, 500)
  );

  useEffect(() => {
    if (cached.indexOf(cacheSuggestion) < 0) {
      setCached(cached.concat(cacheSuggestion));
    }
  }, [cacheSuggestion]);

  const onSuggestionsFetchRequested = ({ value }) => {
    const found = cached.find(x => x.value === value);
    if (found !== undefined) {
      debounced.cancel();
      setSuggestions(found.suggestions);
    } else {
      debounced.cancel();
      debounced(value);
    }
  };

  const onSuggestionsClearRequested = () => {
    if (!isMobile) {
      setSuggestions([]);
    }
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      alwaysRenderSuggestions={isMobile}
      inputProps={{
        ...inputProps,
        value: value,
        onChange: onChange
      }}
    />
  );
};

AutosuggestInput.propTypes = {
  url: PropTypes.string,
  inputProps: PropTypes.object,
  renderSuggestion: PropTypes.func,
  getSuggestionValue: PropTypes.func
};

AutosuggestInput.defaultProps = {
  url: PropTypes.string,
  inputProps: PropTypes.object,
  renderSuggestion: suggestion => <span>{`${suggestion.name}`}</span>,
  getSuggestionValue: suggestion => suggestion.name
};
export default AutosuggestInput;
