import React, { useEffect, useState } from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ConfirmForgotPassword from "./ConfirmForgotPassword";
import ReCAPTCHA from "react-google-recaptcha";
import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";
import handleSkiplinkClick from "@app/helpers/handleSkiplinkClick";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";
import ArrowButton from "../../plugins/components/assets/Button/ArrowButton";
import Input from "@app/components/Input/CMSInput";
import { Portal } from "@material-ui/core";

const ForgotPassword = props => {
  const reCaptchaSiteKey = props.captcha || "";
  const loginUrl = "/login";
  const forgotUsernameUrl = "/api/accounts/forgot_password/";
  const { handleSubmit, register, errors, setError, setValue } = useForm();
  const [inputErrorCaptcha, setInputErrorCaptcha] = useState(false);
  const [inputError, setInputError] = useState(false);
  const { notifyError } = useGenericToastify();
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();

  const resetErrors = () => {
    setInputErrorCaptcha(false);
    setInputError(false);
  };

  useEffect(() => {
    !isEmpty(errors)
      ? notifyError(t("cms.plugins.register.errors.formError"))
      : null;
  }, [errors, notifyError]);

  useEffect(() => {
    register({ name: "recaptcha" });
  });

  const onVerifyCaptcha = token => {
    setValue("recaptcha", token);
  };

  const onSubmit = data => {
    resetErrors();
    axiosSession
      .post(forgotUsernameUrl, data)
      .then(() => {
        setSuccess(true);
      })
      .catch(error => {
        notifyError(t("cms.plugins.register.errors.formError"));

        if (error.response.data.recaptcha) {
          setInputErrorCaptcha(error.response.data.recaptcha[0]);
        }

        if (error.response.data.username) {
          setError("email", "", error.response.data.username);
        }

        if (error.response.data.non_field_errors) {
          setInputError(error.response.data.non_field_errors[0]);
        }
      });
  };

  return success ? (
    <ConfirmForgotPassword />
  ) : (
    <>
      <Portal container={document.querySelector(".skiplinks")}>
        <button
          className="skiplink btn"
          onClick={() => handleSkiplinkClick(".header__nav-open")}
        >
          {t("cms.plugins.login.skiplinks.menu")}
        </button>
        <button
          className="skiplink btn"
          onClick={() => handleSkiplinkClick(".login__container")}
        >
          {t("cms.plugins.login.skiplinks.form")}
        </button>
        <button
          className="skiplink btn"
          onClick={() => handleSkiplinkClick(".login__title-row__register")}
        >
          {t("cms.plugins.register.skiplinks.login")}
        </button>
      </Portal>
      <section className="section wrapper">
        <nav className="breadcrumbs">
          <ul>
            <li>
              <a
                className="breadcrumbs__frontoffice-link"
                href="/"
                title={t("cms.plugins.login.mainPage")}
              >
                {t("cms.plugins.login.mainPage")}
              </a>
            </li>
            <li>
              <span>&rarr;</span>
            </li>
            <li>
              <a className="breadcrumbs__frontoffice-link" href={loginUrl}>
                {t("cms.plugins.register.login")}
              </a>
            </li>
            <li>
              <span>&rarr;</span>
            </li>
            <li>
              <span className="breadcrumbs__current">
                {t("cms.plugins.forgotPassword.label")}
              </span>
            </li>
          </ul>
        </nav>
        <section className="section">
          <h1 id="mbc_header">{t("cms.plugins.forgotPassword.header")}</h1>
          <div className="login__title-row">
            <h2 className="header--decorated">
              {t("cms.plugins.forgotPassword.label")}
            </h2>
            <ArrowButton href="/login" className="login__title-row__register">
              {t("cms.plugins.login.login")}
            </ArrowButton>
          </div>
          <div className="login__container">
            <span>{t("cms.plugins.forgotPassword.caption")}</span>
            <form className="form-group" onSubmit={handleSubmit(onSubmit)}>
              <Input
                placeholder={t("cms.plugins.placeholders.username")}
                ariaLabel={t("cms.plugins.placeholders.username")}
                name={"username"}
                label={t("cms.plugins.register.username")}
                required
                register={register({
                  required: t("cms.plugins.register.errors.required")
                })}
                errors={errors}
              />

              <div className="login__links">
                <ReCAPTCHA
                  className="captcha"
                  onChange={onVerifyCaptcha}
                  sitekey={reCaptchaSiteKey}
                />
                <p className="is-error-text">
                  {errors.recaptcha &&
                    t("cms.plugins.register.errors.required")}
                  {inputErrorCaptcha}
                </p>
                <button className="btn">
                  {t("cms.plugins.forgotPassword.send")}
                  &nbsp;
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
                <ArrowButton href="/login" className="login__links__register">
                  {t("cms.plugins.registerEmail.login")}
                </ArrowButton>
              </div>
            </form>
          </div>
        </section>
      </section>
    </>
  );
};

export default ForgotPassword;
