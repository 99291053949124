import React, { useState } from "react";

import GenericInput from "@components/Input/GenericInput";
import ResizableGenericSelect from "@app/plugins/components/assets/Select/ResizableGenericSelect";
import { useTranslation } from "react-i18next";

const FieldArray = ({
  attributes,
  control,
  reset,
  errors,
  notifyErrors,
  id,
  historicOptions,
  setValue,
  labelDrawer,
  displayNoneLabel,
  isSynonyms,
  resetAfterChange,
  onChange,
  autoSuggest,
  handleInputBlur,
  autoSuggestKeyboardHandle,
  handleInputFocus,
  addRowComponent,
  isFrontoffice
}) => {
  const [chosenAttribute, setChosenAttribute] = useState(null);
  const [chosenOperation, setChosenOperation] = useState(null);
  const { t } = useTranslation();

  const complex_options = [
    {
      id: 2,
      name: t("cms.plugins.advancedSearch.operation_options.contain"),
      key: "contain"
    },
    {
      id: 3,
      name: t("cms.plugins.advancedSearch.operation_options.notContain"),
      key: "not_contain"
    },
    {
      id: 4,
      name: t("cms.plugins.advancedSearch.operation_options.empty"),
      key: "empty"
    },
    {
      id: 5,
      name: t("cms.plugins.advancedSearch.operation_options.notEmpty"),
      key: "not_empty"
    }
  ];

  const numeric_options = [
    {
      id: 0,
      name: t("cms.plugins.advancedSearch.operation_options.equal"),
      key: "equal"
    },
    {
      id: 1,
      name: t("cms.plugins.advancedSearch.operation_options.notEqual"),
      key: "not_equal"
    },
    {
      id: 4,
      name: t("cms.plugins.advancedSearch.operation_options.empty"),
      key: "empty"
    },
    {
      id: 5,
      name: t("cms.plugins.advancedSearch.operation_options.notEmpty"),
      key: "not_empty"
    },
    {
      id: 6,
      name: t("cms.plugins.advancedSearch.operation_options.from"),
      key: "from"
    },
    {
      id: 7,
      name: t("cms.plugins.advancedSearch.operation_options.to"),
      key: "to"
    }
  ];

  const text_options = [
    {
      id: 0,
      name: t("cms.plugins.advancedSearch.operation_options.equal"),
      key: "equal"
    },
    {
      id: 1,
      name: t("cms.plugins.advancedSearch.operation_options.notEqual"),
      key: "not_equal"
    },
    {
      id: 9,
      name: t("cms.plugins.advancedSearch.operation_options.regex"),
      key: "regex"
    }
  ].concat(complex_options);

  const date_options = numeric_options.concat([
    {
      id: 8,
      name: t("cms.plugins.advancedSearch.operation_options.historical"),
      key: "historical"
    }
  ]);

  const conjunctions = [
    {
      id: 0,
      name: t("cms.plugins.advancedSearch.conjuctions.and"),
      key: "and"
    },
    { id: 1, name: t("cms.plugins.advancedSearch.conjuctions.or"), key: "or" }
  ];

  const converted_numeric_options = isSynonyms
    ? numeric_options.filter(
        item => item.key !== "equal" && item.key !== "not_equal"
      )
    : numeric_options;
  const converted_text_options = isSynonyms
    ? text_options.filter(
        item => item.key !== "equal" && item.key !== "not_equal"
      )
    : text_options;

  const operationOptions =
    (chosenAttribute &&
      ((chosenAttribute.attribute_type === "complex" && complex_options) ||
        (chosenAttribute.attribute_type === "numeric" &&
          converted_numeric_options) ||
        (chosenAttribute.attribute_type === "date" && date_options))) ||
    converted_text_options;

  return (
    <>
      {id !== 0 && (
        <>
          <div
            className={
              isFrontoffice
                ? "advanced-search__content__conjuction  col-xs-12 col-md-2"
                : "column is-2-tablet is-4-mobile"
            }
          >
            <ResizableGenericSelect
              name={`query[${id}].operator`}
              placeholder={t(
                "cms.plugins.advancedSearch.placeholders.operator"
              )}
              alt={`operator select, ${t("app.field_start")} ${
                conjunctions.length
              } ${t("app.field_end")}`}
              label={`operator select, ${t("app.field_start")} ${
                conjunctions.length
              } ${t("app.field_end")}`}
              displayNoneLabel={displayNoneLabel}
              errors={errors}
              notifyErrors={notifyErrors}
              options={conjunctions}
              required={{
                required: t("cms.plugins.advancedSearch.errors.required")
              }}
              control={control}
              menuPosition="fixed"
              className="cms-select"
              classNamePrefix="cms-select"
            />
          </div>
          {!isFrontoffice && (
            <div className="column is-8-mobile is-hidden-tablet" />
          )}
        </>
      )}
      <div
        className={
          isFrontoffice
            ? "col-xs-12 col-md-3"
            : "column is-3-fullhd is-6-mobile"
        }
      >
        <ResizableGenericSelect
          name={`query[${id}].attribute`}
          placeholder={t("cms.plugins.advancedSearch.placeholders.attribute")}
          alt={`${t("cms.plugins.advancedSearch.placeholders.attribute")}, ${
            chosenAttribute !== null ? chosenAttribute.name : ""
          }, ${t("app.field_start")} ${attributes.length} ${t(
            "app.field_end"
          )}`}
          label={`${t("cms.plugins.advancedSearch.placeholders.attribute")}, ${
            chosenAttribute !== null ? chosenAttribute.name : ""
          }, ${t("app.field_start")} ${attributes.length} ${t(
            "app.field_end"
          )}`}
          displayNoneLabel={displayNoneLabel}
          options={attributes}
          notifyErrors={notifyErrors}
          handleSelectChange={value => {
            setChosenAttribute(value[0]);
            setValue(`query[${id}].operation`, null);
            setChosenOperation(null);
            return value[0];
          }}
          errors={errors}
          required={{
            required: t("cms.plugins.advancedSearch.errors.required")
          }}
          control={control}
          className="cms-select"
          classNamePrefix="cms-select"
          menuPosition="fixed"
        />
      </div>
      <div
        className={
          isFrontoffice
            ? "col-xs-12 col-md-2"
            : "column is-3-fullhd is-6-mobile"
        }
      >
        <ResizableGenericSelect
          name={`query[${id}].operation`}
          placeholder={t("cms.plugins.advancedSearch.placeholders.operation")}
          alt={`${t("cms.plugins.advancedSearch.placeholders.operation")}, ${
            chosenOperation !== null ? chosenOperation.name : ""
          }, ${t("app.field_start")} ${operationOptions.length} ${t(
            "app.field_end"
          )}`}
          label={`${t("cms.plugins.advancedSearch.placeholders.operation")}, ${
            chosenOperation !== null ? chosenOperation.name : ""
          }, ${t("app.field_start")} ${operationOptions.length} ${t(
            "app.field_end"
          )}`}
          displayNoneLabel={displayNoneLabel}
          options={operationOptions}
          notifyErrors={notifyErrors}
          errors={errors}
          required={{
            required: t("cms.plugins.advancedSearch.errors.required")
          }}
          control={control}
          handleSelectChange={value => {
            if (resetAfterChange) {
              reset();
            }
            setChosenOperation(value[0]);
            return value[0];
          }}
          disabled={chosenAttribute === null}
          className="cms-select"
          classNamePrefix="cms-select"
          menuPosition="fixed"
        />
      </div>
      <div
        className={
          isFrontoffice
            ? `col-xs-12 col-md-${id === 0 ? "6" : "4"}`
            : `column ${
                id !== 0
                  ? "is-12-tablet is-4-fullhd"
                  : "is-12-tablet is-6-fullhd"
              } is-12-mobile`
        }
      >
        {chosenOperation && chosenOperation.id === 8 ? (
          <ResizableGenericSelect
            name={`query[${id}].value`}
            placeholder={t(
              "cms.plugins.advancedSearch.placeholders.historical"
            )}
            alt={`historic options select, ${t("app.field_start")} ${
              historicOptions.length
            } ${t("app.field_end")}`}
            label={`historic options select, ${t("app.field_start")} ${
              historicOptions.length
            } ${t("app.field_end")}`}
            displayNoneLabel={displayNoneLabel}
            options={historicOptions}
            notifyErrors={notifyErrors}
            errors={errors}
            required={{
              required: t("cms.plugins.advancedSearch.errors.required")
            }}
            control={control}
            menuPosition="fixed"
            className="cms-select"
            classNamePrefix="cms-select"
          />
        ) : (
          <GenericInput
            errors={errors}
            key={chosenOperation}
            control={control}
            placeholder={
              (chosenAttribute &&
                chosenAttribute.attribute_type === "date" &&
                t("cms.plugins.advancedSearch.placeholders.year")) ||
              (chosenAttribute &&
                chosenAttribute.attribute_type === "numeric" &&
                t("cms.plugins.advancedSearch.placeholders.digit")) ||
              t("cms.plugins.advancedSearch.placeholders.text")
            }
            name={`query[${id}].value`}
            disabled={
              chosenOperation &&
              (chosenOperation.id === 4 || chosenOperation.id === 5)
            }
            value=""
            label={
              (chosenAttribute &&
                chosenAttribute.attribute_type === "date" &&
                t("cms.plugins.advancedSearch.placeholders.year")) ||
              (chosenAttribute &&
                chosenAttribute.attribute_type === "numeric" &&
                t("cms.plugins.advancedSearch.placeholders.digit")) ||
              t("cms.plugins.advancedSearch.placeholders.text")
            }
            displayNoneLabel={displayNoneLabel}
            labelDrawer={labelDrawer}
            onChange={onChange}
            autoSuggest={autoSuggest}
            onBlur={handleInputBlur}
            onFocus={handleInputFocus}
            autoSuggestKeyboardHandle={autoSuggestKeyboardHandle}
          />
        )}
      </div>
      <div
        className={`col-xs-12 col-md-1 advanced-search__content__${
          id === 0 ? "add-new" : "delete"
        }`}
      >
        {addRowComponent}
      </div>
    </>
  );
};

FieldArray.defaultProps = {
  notifyErrors: false,
  labelDrawer: true,
  resetAfterChange: true,
  isFrontoffice: false,
  displayNoneLabel: false
};

export default FieldArray;
