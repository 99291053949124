import React, { useEffect, useState } from "react";

import genericAdvancedQuery from "@app/helpers/genericAdvancedQuery";
import CMSTable from "@components/Table/CMSTable";
import { getCollectionList } from "@app/services/collectionServices";
import { useTranslation } from "react-i18next";
import { ArrowRightOutlined } from "@ant-design/icons";

const SearchHistory = ({ config }) => {
  const [collectionsList, setCollectionsList] = useState([]);
  const [CMSTableView, setCMSTableView] = useState("table");
  const { t } = useTranslation();

  useEffect(() => {
    getCollectionList()
      .then(({ data }) => setCollectionsList(data))
      .catch(err => console.error(err));
  }, []);

  const getFiltersRecord = recordFilters => {
    if (recordFilters.length == 0) {
      return <p>-</p>;
    }
    const displayRecordFilters = [];
    for (let [filterName, filterContent] of Object.entries(recordFilters[0])) {
      if (filterContent.length > 0) {
        if (filterName === "collections") {
          const collections = collectionsList.filter(collection =>
            filterContent.includes(collection.id)
          );
          displayRecordFilters.push(
            <p>
              <strong>{t(`cms.plugins.filters.${filterName}`)}: </strong>
              {collections.map(collect => collect.internal_name).join(", ")}
            </p>
          );
        } else {
          displayRecordFilters.push(
            <p>
              <strong>{t(`cms.plugins.filters.${filterName}`)}: </strong>
              {filterContent.join(", ")}
            </p>
          );
        }
      }
    }
    return displayRecordFilters;
  };
  const urls = {
    get: "/api/search/frontoffice/historical-queries/",
    collection: "/api/collections/tree/"
  };

  const columns = [
    {
      title: t("cms.plugins.searchHistory.structure.query"),
      dataIndex: "query",
      key: "query",
      sorter: false,
      width: "30%",
      render: (text, record) => (
        <div
          style={{ display: "flex", flexDirection: "column" }}
          title={!record.advanced ? record.query : genericAdvancedQuery(record)}
        >
          {!record.advanced ? (
            <p>{record.query}</p>
          ) : (
            genericAdvancedQuery(record)
          )}
        </div>
      )
    },
    {
      title: t("cms.plugins.searchHistory.structure.filters"),
      dataIndex: "filters",
      key: "filters",
      sorter: false,
      width: "30%",
      render: (text, record) => (
        <div
          style={{ display: "flex", flexDirection: "column" }}
          title={!record.advanced ? record.query : genericAdvancedQuery(record)}
        >
          {getFiltersRecord(record.filters)}
        </div>
      )
    },
    {
      title: t("cms.plugins.searchHistory.structure.created"),
      dataIndex: "date",
      key: "date",
      sorter: true,
      width: "15%"
    },
    {
      title: t("cms.plugins.searchHistory.structure.type"),
      dataIndex: "advanced",
      key: "advanced",
      sorter: true,
      width: "10%",
      render: (text, record) => (
        <>
          {record.advanced
            ? t("cms.plugins.searchHistory.results.type.advanced")
            : t("cms.plugins.searchHistory.results.type.simple")}
          {record.filters.length > 0
            ? ` + ${t("cms.plugins.searchHistory.filters")}`
            : ""}
        </>
      )
    },
    {
      title: t("cms.plugins.searchHistory.structure.actions"),
      dataIndex: "",
      sorter: false,
      width: "8%",
      fixed: "right",
      render: (text, record) => (
        <a
          className="btn btn--text-black"
          href={`${config.search_view_url}?id=${record.id}&history=1`}
          title={t("cms.plugins.searchHistory.search")}
          aria-label={t("cms.plugins.searchHistory.search")}
        >
          <ArrowRightOutlined className="anticon-dark" aria-hidden="true" />{" "}
          {CMSTableView === "list" && t("cms.plugins.searchHistory.search")}
        </a>
      )
    }
  ];

  return (
    <CMSTable
      columns={columns}
      dataUrl={urls.get}
      listView
      setUpperView={setCMSTableView}
    />
  );
};
export default SearchHistory;
