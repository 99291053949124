const handleErrors = (errors, setError, name = "") => {
  if (errors) {
    for (let [key, value] of Object.entries(errors)) {
      const fieldName = name ? `${name}[${key}]` : key;
      if (!Array.isArray(value) && typeof value === "object") {
        handleErrors(value, setError, fieldName);
      } else {
        setError(fieldName, "", value);
      }
    }
  }
};
export default handleErrors;
