import React, { useEffect, useRef, useState } from "react";

import Input from "@app/components/Input/CMSInput";
import ReCAPTCHA from "react-google-recaptcha";
import axiosSession from "@app/config/axiosSession";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";
import ArrowButton from "@app/plugins/components/assets/Button/ArrowButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import GenericSelect from "@app/components/Select/GenericSelect";

const Contact = ({ selectOptions, contactId, siteKey, userIsLogged }) => {
  const {
    watch,
    handleSubmit,
    register,
    errors,
    control,
    setError,
    setValue,
    reset
  } = useForm();
  const reCaptchaSiteKey = siteKey;
  const [titleOptions, setTitleOptions] = useState([]);
  const { notifyError, notifySuccess } = useGenericToastify();
  const { t, i18n } = useTranslation();
  const watchFields = watch();
  const [captchaLang, setCaptchaLang] = useState("pl")

  const hideCaptcha = userIsLogged.toLowerCase() === "true"

  useEffect(() => {
    const options = selectOptions.map((value, index) => {
      return { id: index, name: value };
    });
    setTitleOptions(options);
  }, [selectOptions]);

  const setErrors = errors => {
    let entries = Object.entries(errors);
    for (let [key, value] of entries) {
      setError(key, "", value);
    }
  };

  useEffect(() => {
    setCaptchaLang(i18n.language)
  })

  useEffect(() => {
    register({ name: "recaptcha" });
  });


  const onVerifyCaptcha = token => {
    setValue("recaptcha", token);
  };

  const reCaptchaRef = useRef(null);

  const onSubmit = data => {
    if (!hideCaptcha) {
      data.recaptcha = reCaptchaRef.current.getValue();
    }
    data.contact_id = contactId;
    data.title = data.title.name;

    const url = hideCaptcha ? "/api/cms/plugins/contact/send-email-auth/" : "/api/cms/plugins/contact/send-email/"

    axiosSession
      .post(url, data)
      .then(response => {
        notifySuccess(response.data.message);
        reset({ title: "", content: "" });
      })
      .catch(error => {
        reCaptchaRef.current.reset();
        if (error.response.status === 422) {
          setErrors(error.response.data.errors);
        }
        notifyError(error.response.data.message);
      })
      .finally(() => {
        if(!hideCaptcha){
          reCaptchaRef.current.reset();
        }
      });
  };

  return (
    <section className="contact">
      <section className="section wrapper">
        <nav className="breadcrumbs">
          <ul>
            <li>
              <a
                className="breadcrumbs__frontoffice-link"
                href="/"
                title={t("cms.plugins.confirmEmail.mainPage")}
              >
                {t("cms.plugins.confirmEmail.mainPage")}
              </a>
            </li>

            <li>
              <span>&rarr;</span>
            </li>
            <li>
              <span className="breadcrumbs__current">
                {t("cms.plugins.contact.header")}
              </span>
            </li>
          </ul>
        </nav>

        <section className="section">
          <div className="contact__title-row">
            <h2 className="header--decorated">
              {t("cms.plugins.contact.header")}
            </h2>
            <ArrowButton href="/">{t("cms.general.mainPage")}</ArrowButton>
          </div>
          <div className="contact__container">
            <h2>{t("cms.plugins.contact.prompt")}</h2>
            <form className="form-group" onSubmit={handleSubmit(onSubmit)}>
              <GenericSelect
                options={titleOptions}
                required={{
                  required: t("cms.plugins.contact.required")
                }}
                className="cms-select"
                classNamePrefix="cms-select"
                name="title"
                errors={errors}
                label={t("cms.plugins.contact.title")}
                placeholder={t("cms.plugins.contact.placeholders.title")}
                alt={`${t(
                  "cms.plugins.contact.placeholders.title"
                )}, ${watchFields.title?.name ?? ""}, ${t("app.field_start")} ${titleOptions.length
                  } ${t("app.field_end")}`}
                control={control}
              />
              <Input
                label={t("cms.plugins.contact.reply")}
                placeholder={t("cms.plugins.contact.placeholders.reply")}
                ariaLabel={`${t("cms.plugins.contact.placeholders.reply")} ${t(
                  "app.requiredField"
                )}`}
                name="reply"
                type="email"
                required
                register={register({
                  required: t("cms.plugins.contact.required")
                })}
                errors={errors}
              />
              <Input
                label={t("cms.plugins.contact.fullname")}
                placeholder={t("cms.plugins.contact.placeholders.fullname")}
                ariaLabel={t("cms.plugins.contact.placeholders.fullname")}
                name="fullname"
                errors={errors}
              />
              <Input
                label={t("cms.plugins.contact.content")}
                placeholder={t("cms.plugins.contact.placeholders.content")}
                ariaLabel={`${t(
                  "cms.plugins.contact.placeholders.content"
                )} ${t("app.requiredField")}`}
                name="content"
                tag="textarea"
                register={register({
                  required: t("cms.plugins.contact.required")
                })}
                required
                errors={errors}
              />
              {!hideCaptcha && <ReCAPTCHA
                ref={reCaptchaRef}
                onChange={onVerifyCaptcha}
                className="contact__captcha captcha"
                sitekey={reCaptchaSiteKey}
                hl={captchaLang}
              />}

              <p className="is-error-text">
                {errors.recaptcha && t("cms.plugins.contact.required")}
              </p>
              <button className="btn" type="submit">
                {t("cms.plugins.contact.sendButton")}
                &nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </form>
            <div>
              <p>
                <span className="color-red">*</span> -{" "}
                {t("app.requiredDescription")}
              </p>
            </div>
            <ArrowButton href="/">{t("cms.general.mainPage")}</ArrowButton>
          </div>
        </section>
      </section>
    </section>
  );
};

export default Contact;
