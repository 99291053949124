import React from "react";
import TiledView from "@components/TiledView/TiledView";
import { useTranslation } from "react-i18next";

const Favorites = ({ config }) => {
  const { t } = useTranslation();
  const favoriteUrl = "/api/digital_items/user/favorite/";

  return (
    <TiledView
      config={config}
      url={favoriteUrl}
      noItemMessage={t("cms.plugins.favorites.noObjects")}
      noItemMessageMore={t("cms.plugins.favorites.noObjectsMore")}
      favorite
    />
  );
};

export default Favorites;
